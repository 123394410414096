import {
  VN_ADSENSE_REMAINING_VISITORS_EXP_ID,
  VN_ADSENSE_EXP_1_ID,
  VN_ADSENSE_EXP_3_ID,
  VN_ADSENSE_EXP_4_ID,
  VN_ADSENSE_EXP_5_ID,
  VN_ADSENSE_EXP_6_ID,
  VN_ADSENSE_EXP_7_ID,
  VN_ADSENSE_CHEAP_FACEBOOK_TRAFFIC_EXP_ID,
  VN_ADSENSE_CHEAP_GOOGLE_TRAFFIC_EXP_ID,
  VN_ADSENSE_EXP_11_ID,
} from "@integration/experiments/constants";
import { type Condition, type Rule } from "@integration/adsense/rules/types";

const dob0AndAgeConditions: Condition[] = [
  { field: "age", operator: "<=", value: 39 },
  { field: "age", operator: ">=", value: 24 },
  { field: "is_days_on_book_zero", operator: "==", value: false },
];

const preprocess: Rule["preprocess"] = ({ input, flattened }) => {
  const IGNORED_WHEN_COUNTED = [
    "vib-credit-card-2-vn",
    "sacombank-visa-vn",
    "hd-bank-vietjet-classic-vn",
    "ocb-mastercard-life-style-vn",
  ];
  return {
    ...flattened,
    offer_count: input.offerListing.offers.filter(
      (it) => !IGNORED_WHEN_COUNTED.includes(it.id),
    ).length,
  };
};

export const rules: Rule[] = [
  {
    experimentId: VN_ADSENSE_CHEAP_FACEBOOK_TRAFFIC_EXP_ID,
    conditions: [
      { field: "utm_source", operator: "==", value: "facebook" },
      { field: "utm_campaign", operator: "==", value: "120207410441060371" },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_CHEAP_GOOGLE_TRAFFIC_EXP_ID,
    conditions: [
      { field: "utm_source", operator: "==", value: "google" },
      { field: "utm_campaign", operator: "==", value: "21181827268" },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_11_ID,
    conditions: [
      { field: "is_fresh", operator: "==", value: true },
      { field: "has_no_redirects", operator: "==", value: true },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_4_ID,
    conditions: [
      ...dob0AndAgeConditions,
      { field: "offer_count", operator: "<=", value: 3 },
      { field: "has_money_event", operator: "==", value: true },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_5_ID,
    conditions: [
      ...dob0AndAgeConditions,
      { field: "offer_count", operator: ">=", value: 4 },
      { field: "has_money_event", operator: "==", value: true },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_6_ID,
    conditions: [
      ...dob0AndAgeConditions,
      { field: "offer_count", operator: "<=", value: 3 },
      { field: "has_money_event", operator: "==", value: false },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_7_ID,
    conditions: [
      ...dob0AndAgeConditions,
      { field: "offer_count", operator: ">=", value: 4 },
      { field: "has_money_event", operator: "==", value: false },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [{ field: "age", operator: "==", value: 21 }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [{ field: "offer_count", operator: "==", value: 0 }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [{ field: "offer_count", operator: "<=", value: 3 }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [
      { field: "offer_count", operator: "<=", value: 4 },
      { field: "address_city", operator: "==", value: "Hồ Chí Minh" },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [
      { field: "offer_count", operator: "<=", value: 4 },
      { field: "address_city", operator: "==", value: "Hà Nội" },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [
      { field: "offer_count", operator: "<=", value: 7 },
      { field: "age", operator: "==", value: null },
      { field: "gender", operator: "==", value: null },
      { field: "address_city", operator: "==", value: null },
    ],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_1_ID,
    conditions: [{ field: "quality_score", operator: "<", value: 0.05 }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_3_ID,
    conditions: [{ field: "gender", operator: "==", value: "male" }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_EXP_3_ID,
    conditions: [{ field: "device_brand", operator: "==", value: "apple" }],
    preprocess,
  },
  {
    experimentId: VN_ADSENSE_REMAINING_VISITORS_EXP_ID,
    conditions: [],
  },
];
