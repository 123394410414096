import { JEFF_VN_BRAND_ID } from "@domain/branding/constants";
import { CONFIG_MAP } from "../configMap";
import type { BrandId } from "@domain/branding/common";

export const fetchProductConfig = (
  brandId: BrandId,
  onError: (error: Error) => void,
) => {
  let config = CONFIG_MAP[brandId];
  if (!config) {
    onError(new Error(`Not implemented for ${brandId}`));
    config = CONFIG_MAP[JEFF_VN_BRAND_ID];
  }
  return config;
};
