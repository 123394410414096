/* eslint-disable no-console */
import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";

export const snowplowEndpoint = ({
  domainName,
  onError = console.error,
}: {
  domainName: string;
  onError: (error: string | Error) => void;
}) => {
  if (domainName.indexOf("vercel.app") > -1) {
    return;
  }

  if (
    domainName.includes("localhost") ||
    domainName.includes("ngrok-free.app")
  ) {
    return "localhost:9090";
  }

  const rootDomain = getDomainFromOrigin({ origin: domainName });

  if (!rootDomain) {
    onError(
      `Could not get the root domain for ${domainName} for snowplowEndpoint.`,
    );
    return;
  }

  return domainName.includes("-dev.")
    ? `sp-dev.${rootDomain}`
    : `sp.${rootDomain}`;
};
