import { type ExperimentId } from "@integration/experiments/constants";
import { type GetAdSenseStatusArgs } from "../types";
import { type Rule } from "./types";
import { flatten } from "./flatten";

export const evaluate = (rule: Rule, input: GetAdSenseStatusArgs): boolean => {
  let data = flatten({ input });
  if (rule.preprocess) {
    data = rule.preprocess({ flattened: data, input });
  }
  return rule.conditions.every((condition) => {
    const value = data[condition.field];
    if (condition.value === null && condition.operator === "==") {
      return value === null;
    }
    if (condition.operator === "==") {
      return value == condition.value;
    }
    if (condition.operator === "!=") {
      return value != condition.value;
    }
    if (
      typeof condition.value === "string" &&
      typeof value === "string" &&
      condition.operator === "startsWith"
    ) {
      return value.startsWith(condition.value);
    }

    if (typeof value === "number" && typeof condition.value === "number") {
      if (condition.operator === ">") {
        return value > condition.value;
      }
      if (condition.operator === "<") {
        return value < condition.value;
      }
      if (condition.operator === ">=") {
        return value >= condition.value;
      }
      if (condition.operator === "<=") {
        return value <= condition.value;
      }
    }
    if (
      (value === null || condition.value === null) &&
      [">", "<", ">=", "<=", "startsWith"].includes(condition.operator)
    ) {
      return false;
    }
    throw new Error(
      `Unsupported comparison: field=${condition.field}, operator='${condition.operator}', value=${condition.value}`,
    );
  });
};

export const findApplicableExperiment = ({
  rules,
  input,
}: {
  rules: Rule[];
  input: GetAdSenseStatusArgs;
}): ExperimentId | undefined => {
  const rule = rules.find((it) => {
    const result = evaluate(it, input);
    return result;
  });
  return rule ? rule.experimentId : undefined;
};
