import { enUS as hi } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const inItems: LocalisationConfig = {
  country: "IN",
  allowedLanguages: ["en", "hi"],
  defaultLanguage: "hi",
  hrefLang: "hi",
  currency: {
    abbreviation: "INR",
    symbol: "₹",
    format: (amount: number) => `₹${amount.toLocaleString()}`,
  },
  phonePrefix: allPhonePrefixes.IN,
  locale: hi,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "Indonesia",
};
