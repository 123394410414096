import { useEffect, useState } from "react";
import { useRouter } from "@integration/next-router";
import { PARAM_NAME_LANGUAGE } from "@utils/constants/urlParamNames";
import { getInitialLang } from "@domain/localisation/common/getInitialLang";
import { fetchLocalisationConfig } from "@domain/localisation/common/fetchLocalisationConfig";
import { onError } from "@integration/bugsnag/csr";
import { setCookieNext } from "@integration/cookies/setCookieNext";
import { COOKIE_NAME_PREFERRED_LANG } from "@integration/cookies/cookie-names";
import { getStoredLang } from "@domain/localisation/common/getStoredLang";
import type { BrandId } from "@domain/branding/common/types";
import type {
  Languages,
  LocalisationConfig,
} from "@domain/localisation/localisation-config/types";

interface Args {
  browserLanguages?: string[];
  preferredLang?: Languages;
  storybookLang?: Languages;
  brandId: BrandId;
  localisationConfig?: LocalisationConfig;
}

export const useLangState = ({
  brandId,
  browserLanguages,
  preferredLang,
  localisationConfig,
  storybookLang,
}: Args) => {
  const { query } = useRouter();

  const currentConfig =
    localisationConfig || fetchLocalisationConfig({ brandId, onError });

  const receivedLang = query[PARAM_NAME_LANGUAGE] as Languages;
  const storedLang = preferredLang || getStoredLang();
  const defaultBrandLanguage = currentConfig.defaultLanguage;
  const allowedLanguages = currentConfig.allowedLanguages;

  const initialLang = getInitialLang({
    receivedLang,
    storedLang,
    browserLanguages,
    defaultBrandLanguage,
    allowedLanguages,
  });

  const [currentLang, setCurrentLangState] = useState(initialLang);

  useEffect(() => {
    if (currentConfig.allowedLanguages.includes(receivedLang)) {
      setCurrentLangState(receivedLang);
    }
  }, [currentConfig.allowedLanguages, receivedLang]);

  useEffect(() => {
    if (storybookLang) {
      setCurrentLangState(storybookLang);
    }
  }, [storybookLang]);

  const setCurrentLang = (lang: Languages) => {
    setCookieNext(COOKIE_NAME_PREFERRED_LANG, lang);
    setCurrentLangState(lang);
  };

  return [currentLang, setCurrentLang] as [
    currentLang: Languages,
    setCurrentLang: (lang: Languages) => void,
  ];
};
