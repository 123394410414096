import {
  PARAM_NAME_FORCE_ADSENSE,
  PARAM_NAME_FORCE_GPT,
} from "@jeff/web-analytics/src/marketing/marketingParams";
import {
  PARAM_NAME_PARTNER_URL_KEY,
  PARAM_NAME_REDIRECT_REASON,
} from "@utils/constants";
import type { UrlParamNames } from "@integration/query-params/common";

export const AD_SENSE_ALLOWED_PAGES = [
  "/offers",
  "/guest-offers",
  "/profile/my-activity",
  "/profile/dashboard",
  "/confirm-email",
  "/registration",
];

/**
 * Unfortunately AdSense considers every (well, not always every) new query param combination as "new page" and limits showing ads on the page.
 * Read more details here: https://webmasters.stackexchange.com/questions/126918/adsense-not-showing-ads-because-links-from-facebook-add-a-query-parameter
 * Therefore think twice if you really need to have a query params on the page where adsense is located
 */
export const WHITELISTED_QUERY_PARAM_KEYS: UrlParamNames[] = [
  PARAM_NAME_FORCE_ADSENSE,
  PARAM_NAME_FORCE_GPT,
  PARAM_NAME_PARTNER_URL_KEY,
  PARAM_NAME_REDIRECT_REASON,
];

export const isAllowedToShowAdsense = ({ path }: { path: string }): boolean => {
  return AD_SENSE_ALLOWED_PAGES.indexOf(path) > -1;
};
