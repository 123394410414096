import { type GetAdSenseStatusArgs } from "../types";
import { ALL_FIELDS, type FlattenedAdSenseData } from "./types";

export const flatten = ({ input }: { input: GetAdSenseStatusArgs }) => {
  const result: FlattenedAdSenseData = ALL_FIELDS.reduce(
    (acc, field) => ({ ...acc, [field]: null }),
    {} as FlattenedAdSenseData,
  );
  result["age"] = input.age || null;
  result["offer_count"] = input.offerListing.offers.length;
  result["device_brand"] = input.device.brand?.toLowerCase() || null;
  result["gender"] = input.gender?.toLowerCase() || null;
  result["utm_source"] = input.utm.source?.toLowerCase() || null;
  result["utm_campaign"] = input.utm.campaign?.toLowerCase() || null;
  result["utm_medium"] = input.utm.medium?.toLowerCase() || null;
  result["address_city"] = input.address.city || null;
  result["is_days_on_book_zero"] = input.isDaysOnBookZero ?? null;
  result["has_money_event"] = input.hasMoneyEvent ?? null;
  result["has_no_redirects"] = input.hasNoRedirects ?? null;
  result["is_fresh"] = input.isFresh ?? null;
  result["quality_score"] = input.qualityScore ?? null;
  return result;
};
