import { mobileVendor, deviceType } from "react-device-detect";
import { z } from "zod";
import { onError } from "@integration/bugsnag/csr";
import { CustomError } from "@integration/bugsnag/error-types";

const DeviceDataSchema = z.object({
  type: z.string(),
  vendor: z.string(),
});

type DeviceData = z.infer<typeof DeviceDataSchema>;

export const getDeviceData = (): DeviceData => {
  const result = DeviceDataSchema.safeParse({
    type: deviceType,
    vendor: mobileVendor,
  });
  if (!result.success) {
    onError(
      new CustomError({
        name: "DeviceDataError",
        message: `Failed to get device data: ${result.error.message}`,
      }),
    );
    return {
      type: "unknown",
      vendor: "unknown",
    };
  }
  return result.data;
};
