import {
  newTracker,
  trackPageView as _trackPageView,
  setUserId,
  type PageViewEvent,
  type CommonEventProperties,
} from "@snowplow/browser-tracker";
import { GaCookiesPlugin } from "@snowplow/browser-plugin-ga-cookies";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  FormTrackingPlugin,
  enableFormTracking,
} from "@snowplow/browser-plugin-form-tracking";
import { type SnowplowExperiment } from "../common/types";
import { ExperimentContextPlugin } from "./experiment-context-plugin";
import { ClarityContextPlugin } from "./clarity-context-plugin";
import { MarketingContextPlugin } from "./marketing-context-plugin";

let INITIALIZED = false;

export const initSnowplow = ({
  trackerName = "sp1",
  endpoint,
  appId,
  leadId,
  crossDomainLinker,
  getExperiments,
  getCookieByName,
}: {
  trackerName?: string;
  endpoint?: string;
  appId: string;
  leadId?: string;
  crossDomainLinker?: (elt: HTMLAnchorElement | HTMLAreaElement) => boolean;
  getExperiments: () => SnowplowExperiment[];
  getCookieByName: (name: string) => string | null;
}) => {
  if (endpoint) {
    newTracker(trackerName, endpoint, {
      appId,
      discoverRootDomain: true,
      plugins: [
        GaCookiesPlugin(),
        LinkClickTrackingPlugin(),
        FormTrackingPlugin(),
        ClarityContextPlugin(),
        ExperimentContextPlugin(getExperiments),
        MarketingContextPlugin({ getCookieByName }),
      ],
      crossDomainLinker,
    });
    enableLinkClickTracking();
    enableFormTracking();
    INITIALIZED = true;
  }
  if (leadId) {
    setUserId(leadId);
  }
};

export const identify = ({ leadId }: { leadId: string }) => {
  if (!INITIALIZED) {
    // eslint-disable-next-line no-console
    console.log(`snowplow: identify({leadId: ${leadId}})`);
    return;
  }
  setUserId(leadId);
};

export const trackPageView = (
  event?: PageViewEvent & CommonEventProperties,
) => {
  if (!INITIALIZED) {
    // eslint-disable-next-line no-console
    console.log(
      `snowplow: trackPageView(${window.location.pathname}${window.location.search})`,
    );
    return;
  }
  _trackPageView(event);
};

export const isSpInitialized = () => INITIALIZED;
