import {
  JEFF_VN_BRAND_NAME,
  JEFF_VN_COOKIE_DOMAIN,
  JEFF_DEV_DOMAIN,
  LOCALHOST_HOMEPAGE,
  JEFF_VN_BRAND_ID,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const jeffVNDevBranding: Branding = {
  brandId: JEFF_VN_BRAND_ID,
  brandName: JEFF_VN_BRAND_NAME,
  cookieDomain: JEFF_VN_COOKIE_DOMAIN,
  brandDomain: JEFF_DEV_DOMAIN,
  homepage: LOCALHOST_HOMEPAGE,
  gtmId: gtmTags.vn.jeff,
  tncLink: "https://www.jeff.vn/dieu-khoan-va-dieu-kien",
  privacyPolicyLink: "https://www.jeff.vn/chinh-sach-bao-mat",
};
