import { es } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const mxItems: LocalisationConfig = {
  country: "MX",
  allowedLanguages: ["es"],
  defaultLanguage: "es",
  hrefLang: "es-MX",
  currency: {
    abbreviation: "MXN",
    symbol: "$",
    format: (amount: number) => `$${amount.toLocaleString()}`,
  },
  phonePrefix: allPhonePrefixes.MX,
  locale: es,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "México",
};
