import { useCallback, useEffect, useMemo, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { getBrandedTheme } from "@theme/getBrandedTheme";
import { branding } from "@domain/branding/common/branding";
import { CenteredCircularProgress } from "@components/atoms/CenteredCircularProgress/CenteredCircularProgress";
import { asReportable, onError } from "@integration/bugsnag/csr";
import { ThemeContext } from "./context";
import type { EntityId } from "@domain/branding/common";
import type { BaseTheme } from "@theme/base";
import type { ThemeProviderProps } from "./ThemeProvider.types";

export const ThemeProvider = ({
  children,
  storybookBrandId,
  initialThemeId,
  initialTheme,
}: ThemeProviderProps) => {
  const [currentTheme, setCurrentTheme] = useState<BaseTheme | undefined>(
    initialTheme,
  );

  const overrideThemeId =
    storybookBrandId || initialThemeId || branding.brandId;

  const handleThemeChange = useCallback(
    async (themeId: EntityId) => {
      try {
        const newTheme = await getBrandedTheme(themeId);
        if (newTheme && newTheme.themeId !== currentTheme?.themeId) {
          setCurrentTheme(newTheme);
        }
      } catch (e) {
        const err = asReportable(e);
        onError(err);
      }
    },
    [currentTheme?.themeId],
  );

  useEffect(() => {
    if (!currentTheme?.themeId) {
      void handleThemeChange(overrideThemeId);
    }
  }, [currentTheme?.themeId, handleThemeChange, overrideThemeId]);

  const themeContextValue = useMemo(() => {
    return currentTheme
      ? {
          themeId: currentTheme.themeId,
          setTheme: handleThemeChange,
          theme: currentTheme,
        }
      : undefined;
  }, [currentTheme, handleThemeChange]);

  return (
    <>
      {!currentTheme?.themeId && <CenteredCircularProgress />}

      {currentTheme?.themeId && themeContextValue && (
        <ThemeContext.Provider value={themeContextValue}>
          <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
      )}
    </>
  );
};
