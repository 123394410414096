import { enUS as fil } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const phItems: LocalisationConfig = {
  country: "PH",
  allowedLanguages: ["fil", "en"],
  defaultLanguage: "fil",
  hrefLang: "fil",
  currency: {
    abbreviation: "PHP",
    symbol: "₱",
    format: (amount: number) => `₱${amount.toLocaleString()}`,
  },
  phonePrefix: allPhonePrefixes.PH,
  locale: fil,
  dateInputFormat: "MM/dd/yyyy",
  countryName: "Pilipinas",
};
