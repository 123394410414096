import { useEffect, useRef } from "react";
import { isErrorTypeOf, onError } from "@integration/bugsnag/csr";
import { useBattery } from "./useBattery";
import type { BatteryState } from "./useBattery";

export const useTrackBattery = (isSPReady: boolean) => {
  const battery = useBattery() as Partial<
    BatteryState & {
      isSupported: true;
      fetched: true;
    }
  >;

  const {
    charging,
    chargingTime,
    dischargingTime,
    fetched,
    isSupported,
    level,
  } = battery;

  const hasReported = useRef(false);

  useEffect(() => {
    if (!isSPReady) {
      return;
    }

    if (hasReported.current) {
      return;
    }

    const reportTrackBatteryAsync = async () => {
      try {
        const trackBattery = (await import("@jeff/web-analytics/snowplow"))
          .trackBattery;
        if (!isSupported) {
          trackBattery({ isSupported: false });
          hasReported.current = true;
          return;
        }

        if (fetched) {
          trackBattery({
            isSupported,
            charging,
            chargingTime,
            dischargingTime:
              dischargingTime && dischargingTime < 0 ? 0 : dischargingTime,
            level,
          });
          hasReported.current = true;
        }
      } catch (e) {
        if (isErrorTypeOf({ e, errorType: "illegal-invocation" })) {
          return;
        }
        onError(e as Error);
      }
    };

    reportTrackBatteryAsync().catch(onError);
  }, [
    charging,
    chargingTime,
    dischargingTime,
    fetched,
    isSPReady,
    isSupported,
    level,
  ]);
};
