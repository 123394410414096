import { useRouter } from "@integration/next-router";
import { getStoredLeadId } from "@domain/leadId/getStoredLeadId";
import { filterQueryParams } from "@integration/query-params/common/filterQueryParams";
import { PARAM_NAME_LEADID, PARAM_NAME_LEAD_ID } from "@utils/constants";

export const useLeadIdNext = () => {
  const { query } = useRouter();
  const params = filterQueryParams(query);

  // leadId received from params needs to be prioritized over stored one
  const leadId =
    params?.[PARAM_NAME_LEADID] ||
    params?.[PARAM_NAME_LEAD_ID] ||
    getStoredLeadId();

  return leadId;
};
