/* eslint-disable no-console */
import { JEFF_VN_BRAND_ID } from "@domain/branding/constants";
import { LOCALISATION_CONFIG_MAP } from "../localisation-config/localisationConfigMap";
import type { BrandId } from "@domain/branding/common";

interface Args {
  brandId: BrandId;
  onError: (error: Error | string) => void;
}

export const fetchLocalisationConfig = ({
  brandId,
  onError = console.error,
}: Args) => {
  const config = LOCALISATION_CONFIG_MAP[brandId];

  if (!config) {
    onError(`Not implemented for ${brandId}`);
    return LOCALISATION_CONFIG_MAP[JEFF_VN_BRAND_ID];
  }

  return config;
};
