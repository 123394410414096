import { type Feature } from "@domain/features/types";
import { type ExperimentId } from "@integration/experiments/constants";
import { LEVEL_UP_FEATURE_ENABLED_FEATURE } from "@domain/features/features";
import { USER_PROFILE_PAGE_URI } from "@utils/constants";
import { type ProductConfig } from "@domain/product-config/types";
import {
  type MenuID,
  type ProfileConfig,
  type ProfileSpacePath,
} from "./types";

const mapSpaceToMenuId = (space: ProfileSpacePath): MenuID | undefined => {
  switch (space) {
    case "/dashboard":
      return "dashboard";
    case "/my-activity":
      return "my-activity";
    case "/me":
      return "my-profile";
    case "/level-up":
      return "level-up";
    default:
      return undefined;
  }
};

const getEnabledSpaces = ({
  config,
}: {
  config: ProfileConfig;
}): ProfileSpacePath[] => {
  const spaces = config.spaces;
  spaces.push("/delete");
  return [...new Set(spaces)];
};

const isLevelUpEnabled = ({
  isFeatureEnabled,
}: {
  isFeatureEnabled: (name: Feature | ExperimentId) => boolean;
}): boolean => {
  return isFeatureEnabled(LEVEL_UP_FEATURE_ENABLED_FEATURE);
};

export const getMenuEntries = ({
  config,
  isFeatureEnabled,
}: {
  config: ProductConfig;
  isFeatureEnabled: (name: Feature | ExperimentId) => boolean;
}): MenuID[] => {
  const enabledSpaces = getEnabledSpaces({ config: config.profileConfig });
  const entries: MenuID[] = enabledSpaces
    .map(mapSpaceToMenuId)
    .filter((it): it is MenuID => it !== undefined);
  if (!!config.feedback?.enabled) {
    entries.push("leave-feedback");
  }
  if (config.profileConfig.share.enabled) {
    entries.push("share");
  }
  if (entries.includes("level-up") && !isLevelUpEnabled({ isFeatureEnabled })) {
    entries.splice(entries.indexOf("level-up"), 1);
  }
  entries.push("logout");
  return [...new Set(entries)];
};

export const getProfileRootPath = ({
  config,
}: {
  config: ProfileConfig;
}): string => {
  const spaces = getEnabledSpaces({ config });
  if (spaces.length === 0) {
    throw new Error("Invalid profile configuration, spaces must be defined");
  }
  return USER_PROFILE_PAGE_URI + spaces[0];
};
