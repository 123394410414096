import { useEffect } from "react";
import { z } from "zod";
import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { safeLocalStorage as storage } from "@utils/safeLocalStorage";
import { asReportable, onError } from "@integration/bugsnag/csr";

const KEY = "backend-api-request-metrics";

const BackendApiRequestMetricsSchema = z.object({
  min: z.number(),
  max: z.number(),
  average: z.number(),
});

export type BackendApiRequestMetrics = z.infer<
  typeof BackendApiRequestMetricsSchema
>;

const doMeasure = async (url: string) => {
  const times: number[] = [];
  const numRequests = 3;

  for (let i = 0; i < numRequests; i++) {
    const start = performance.now();
    try {
      await fetch(url);
      const end = performance.now();
      times.push(end - start);
    } catch (error) {
      throw new Error(`Request failed: ${error}`);
    }
  }

  if (times.length === 0) {
    throw new Error("All requests failed");
  }

  const min = Math.min(...times);
  const max = Math.max(...times);
  const average = times.reduce((a, b) => a + b, 0) / times.length;

  const metrics: BackendApiRequestMetrics = {
    min: parseFloat(min.toFixed(2)),
    max: parseFloat(max.toFixed(2)),
    average: parseFloat(average.toFixed(2)),
  };

  storage.setItem(KEY, JSON.stringify(metrics));
};

export const useMeasurePerformance = () => {
  useEffect(() => {
    const { host } = window.location;
    const domain = getDomainFromOrigin({ origin: host });
    const url = `https://api.${domain}/public-api/measure?delay-ms=20`;
    doMeasure(url).catch((e) => {
      onError(asReportable(e));
    });
  }, []);
};

export const getApiRequestMetrics = (): BackendApiRequestMetrics | null => {
  const item = storage.getItem(KEY);
  if (!item) {
    return null;
  }
  const parsed = BackendApiRequestMetricsSchema.safeParse(JSON.parse(item));
  if (!parsed.success) {
    return null;
  }
  return parsed.data;
};
