import {
  FINMART_VN_BRAND_ID,
  FINMART_VN_BRAND_NAME,
  FINMART_VN_COOKIE_DOMAIN,
  FINMART_VN_DOMAIN,
  FINMART_VN_HOMEPAGE,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const finmartVnBranding: Branding = {
  brandId: FINMART_VN_BRAND_ID,
  brandName: FINMART_VN_BRAND_NAME,
  cookieDomain: FINMART_VN_COOKIE_DOMAIN,
  brandDomain: FINMART_VN_DOMAIN,
  homepage: FINMART_VN_HOMEPAGE,
  gtmId: gtmTags.vn.finmart,
  tncLink: "https://www.finmart.vn/dieu-khoan-va-dieu-kien",
  privacyPolicyLink: "https://www.finmart.vn/chinh-sach-bao-mat",
};
