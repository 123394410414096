import { type AdSenseExperimentConfig } from "../../types";

export const config: AdSenseExperimentConfig = {
  siteId: "finmart-vn",
  clientId: "ca-pub-2865748719827515",
  placementConfigs: [
    {
      id: "vn-exp8",
      toggleId: "adsense-vn-exp8",
      siteId: "finmart-vn",
      adUnits: [
        { id: "8080534878", name: "vn-exp8-top", placement: "top" },
        { id: "7094864067", name: "vn-exp8-mid", placement: "mid" },
        { id: "8342644353", name: "vn-exp8-bottom", placement: "bottom" },
      ],
    },
  ],
};
