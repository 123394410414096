import { type Country } from "@domain/common-types";
import {
  ENGLISH_LANG,
  FILIPINO_LANG,
  HINDI_LANG,
  INDONESIAN_LANG,
  SPANISH_LANG,
  VIETNAMESE_LANG,
} from "./humanReadableLangs";
import type { Locale } from "date-fns";

export const ALL_LANGUAGES = [
  "en",
  "vi",
  "fil",
  "hi",
  "id",
  "es",
  "si",
] as const;

export type Languages = (typeof ALL_LANGUAGES)[number];

export type CurrencySymbol = "E" | "₫" | "₱" | "$" | "රු" | "₹" | "Rp" | "$";
export type CurrencyAbbreviations =
  | "EUR"
  | "VND"
  | "PHP"
  | "USD"
  | "LKR"
  | "INR"
  | "IDR"
  | "MXN";

export const humanReadableLanguages = [
  VIETNAMESE_LANG,
  ENGLISH_LANG,
  FILIPINO_LANG,
  HINDI_LANG,
  INDONESIAN_LANG,
  SPANISH_LANG,
] as const;
export type HumanReadableLanguage = (typeof humanReadableLanguages)[number];

export const hrefLangList = [
  "vi-VN",
  "fil-PH",
  "en-PH",
  "en-VN",
  "en-US",
  "en",
  "fil",
  "hi",
  "id",
  "es-MX",
] as const;
export type HrefLang = (typeof hrefLangList)[number];

export type HumanReadableLanguages = {
  [key in Languages]: HumanReadableLanguage;
};

export interface Currency {
  symbol: CurrencySymbol;
  abbreviation: CurrencyAbbreviations;
  format: (amount: number) => string;
}

export type Currencies = {
  [key in Country]: Currency;
};

export interface PhonePrefix {
  prefix: string;
  inputPrefix: string;
}

export type PhonePrefixes = {
  [key in Country]: PhonePrefix;
};

export const allPhonePrefixes: PhonePrefixes = {
  VN: {
    prefix: "+84",
    inputPrefix: "+84(0)",
  },
  US: {
    prefix: "+1",
    inputPrefix: "+1(0)",
  },
  PH: {
    prefix: "+63",
    inputPrefix: "09",
  },
  IN: {
    prefix: "+91",
    inputPrefix: "+91(0)",
  },
  ID: {
    prefix: "+62",
    inputPrefix: "0",
  },
  MX: {
    prefix: "+52",
    inputPrefix: "+52",
  },
};

export interface Translation {
  [key: string]: Translation | string;
}

const DD_MM_YYYY = "dd/MM/yyyy";
const MM_DD_YYYY = "MM/dd/yyyy";

export type DateInputFormat = typeof DD_MM_YYYY | typeof MM_DD_YYYY;

export type LocalisationConfig = {
  allowedLanguages: Languages[];
  defaultLanguage: Languages;
  hrefLang: HrefLang;
  currency: Currency;
  phonePrefix: PhonePrefix;
  locale: Locale;
  country: Country;
  countryName: string;
  dateInputFormat: DateInputFormat;
};
