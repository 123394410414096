import {
  type AdPlacement,
  type RelaxedToggleIdAware,
  type AdPlacementConfig,
  type PlacementConfig,
  type SiteId,
} from "./types";
import { config as jeffVN } from "./sites/jeff-vn";
import { config as loanonlinePH } from "./sites/loanonline-ph";
import { config as finmercadoMX } from "./sites/finmercado-mx";
import { config as finmartVN } from "./sites/finmart-vn";

const config = [jeffVN, loanonlinePH, finmercadoMX, finmartVN];

export const placementConfigs: PlacementConfig[] = config.flatMap(
  (it) => it.placementConfigs,
);

export const findExperimentByToggleId = ({
  siteId,
  toggleId,
  experimentVariant,
}: { siteId: SiteId } & RelaxedToggleIdAware & { experimentVariant?: string }):
  | PlacementConfig
  | undefined => {
  const toggleNameWithVariant = `${toggleId}-${experimentVariant}`;
  return placementConfigs.find(
    (it) =>
      it.siteId === siteId &&
      (it.toggleId === toggleId ||
        (experimentVariant && it.toggleId === toggleNameWithVariant)),
  );
};

export const adPlacement = ({
  siteId,
  toggleId,
  experimentVariant,
}: { siteId: SiteId } & RelaxedToggleIdAware & {
    experimentVariant?: string;
  }): AdPlacementConfig => {
  const experiment = findExperimentByToggleId({
    siteId,
    toggleId,
    experimentVariant,
  });
  if (!experiment) {
    return new Map();
  }

  const result = new Map<AdPlacement, string>();
  experiment.adUnits.forEach((it) => result.set(it.placement, it.id));
  return result;
};

export const emptyAdPlacementConfig = (): AdPlacementConfig => {
  return new Map();
};

export const maxGPTPlacementConfig = ({
  siteId,
}: {
  siteId: SiteId;
}): AdPlacementConfig => {
  return adPlacement({ siteId, toggleId: "adsense-vn-exp6-test-group3" });
};

export const maxAdPlacementConfig = ({
  siteId,
}: {
  siteId: SiteId;
}): AdPlacementConfig => {
  if (siteId === "jeff-vn") {
    return adPlacement({ siteId, toggleId: "adsense-vn-exp1-test-group1" });
  }
  if (siteId === "loanonline-ph") {
    return adPlacement({ siteId, toggleId: "adsense-ph-exp1" });
  }
  return adPlacement({ siteId, toggleId: "adsense-mx-exp0" });
};

export const getAdSenseClientId = ({
  siteId,
}: {
  siteId: string;
}): string | undefined => {
  const entry = config.find((it) => it.siteId === siteId);
  return entry?.clientId;
};

export const getAdSlotName = ({
  slotId,
}: {
  slotId: string;
}): string | undefined => {
  const adSlot = placementConfigs
    .flatMap((it) => it.adUnits)
    .find((it) => it.id === slotId);
  return adSlot?.name;
};
