import {
  ATM_ONLINE_VN_PARTNER_OFFER_ID,
  ATM_ONLINE_CPS_VN_PARTNER_OFFER_ID,
  CASHXPRESS_PH_PARTNER_OFFER_ID,
  DIGIDO_PH_PARTNER_OFFER_ID,
  FINBRO_PH_PARTNER_OFFER_ID,
  KVIKU_DIRECT_PH_PARTNER_OFFER_ID,
  MONEYCAT_PH_PARTNER_OFFER_ID,
  MONEYCAT_CPS_PH_PARTNER_OFFER_ID,
  OLP_DIRECT_PH_PARTNER_OFFER_ID,
  ROBOCASH_CPS_VN_PARTNER_OFFER_ID,
  ROBOCASH_VN_PARTNER_OFFER_ID,
  TAKOMO_PARTNER_OFFER_ID,
  VAMO_PARTNER_OFFER_ID,
  VAMO_PH_PARTNER_OFFER_ID,
  MONEYCAT_VN_PARTNER_OFFER_ID,
  MONEYCAT_CPS_VN_PARTNER_OFFER_ID,
  TAMO_VN_PARTNER_OFFER_ID,
  TAMO_CPS_VN_PARTNER_OFFER_ID,
  ON_CREDIT_VN_PARTNER_OFFER_ID,
  ONCREDIT_VN_PARTNER_OFFER_ID,
  DOCTORDONG_VN_PARTNER_OFFER_ID,
  DOCTOR_DONG_VN_PARTNER_OFFER_ID,
  FINDO_VN_PARTNER_OFFER_ID,
  SENMO_VN_PARTNER_OFFER_ID,
  VAYVND_VN_PARTNER_OFFER_ID,
  TIEN_OI_VN_PARTNER_OFFER_ID,
  TIEN_VN_PARTNER_OFFER_ID,
  ON_CREDIT_LK_PARTNER_OFFER_ID,
  LOANME_LK_PARTNER_OFFER_ID,
  LOTUS_LOAN_LK_PARTNER_OFFER_ID,
  ROBOCASH_LK_PARTNER_OFFER_ID,
  FASTRUPEE_LK_PARTNER_OFFER_ID,
  CASHX_LK_PARTNER_OFFER_ID,
  DIGIDO_DIRECT_PH_PARTNER_OFFER_ID,
  MAYBANK_ID_PARTNER_OFFER_ID,
  COZMO_CPS_VN_PARTNER_OFFER_ID,
  COZMO_VN_PARTNER_OFFER_ID,
  TAKOMO_CPS_PARTNER_OFFER_ID,
  MONEYVEO_VN_PARTNER_OFFER_ID,
  CASHEXPRESS_PH_PARTNER_OFFER_ID,
  PESO_REDEE_PH_PARTNER_OFFER_ID,
  DONG_PLUS_VN_PARTNER_OFFER_ID,
  HDBANK_VN_PARTNER_OFFER_ID,
  CREDITO_365_MX_PARTNER_OFFER_ID,
  DINERIA_MX_PARTNER_OFFER_ID,
  LENDSWAP_MX_PARTNER_OFFER_ID,
  KIMBI_MX_PARTNER_OFFER_ID,
  MONEYMAN_MX_PARTNER_OFFER_ID,
  VIVUS_MX_PARTNER_OFFER_ID,
  COZMO_CPL_MX_PARTNER_OFFER_ID,
  LENDON_MX_PARTNER_OFFER_ID,
  MONEYCAT_MX_PARTNER_OFFER_ID,
  PRESTOMIN_MX_PARRTNER_OFFER_ID,
} from "./constants";
import type { PartnerOfferConfigEntry } from "./types";

export const CONFIG_ENTRIES: PartnerOfferConfigEntry[] = [
  {
    getTheme: async () => (await import("@theme/atmonline")).atmonlineTheme,
    offerIds: [
      ATM_ONLINE_VN_PARTNER_OFFER_ID,
      ATM_ONLINE_CPS_VN_PARTNER_OFFER_ID,
    ],
  },
  {
    getTheme: async () => (await import("@theme/cashxpress")).cashxpressTheme,
    offerIds: [
      ROBOCASH_VN_PARTNER_OFFER_ID,
      ROBOCASH_CPS_VN_PARTNER_OFFER_ID,
      ROBOCASH_LK_PARTNER_OFFER_ID,
    ],
  },
  {
    getTheme: async () => (await import("@theme/moneycat")).moneycatTheme,
    offerIds: [
      MONEYCAT_VN_PARTNER_OFFER_ID,
      MONEYCAT_CPS_VN_PARTNER_OFFER_ID,
      MONEYCAT_PH_PARTNER_OFFER_ID,
      MONEYCAT_CPS_PH_PARTNER_OFFER_ID,
      MONEYCAT_MX_PARTNER_OFFER_ID,
    ],
  },
  {
    getTheme: async () => (await import("@theme/tamo")).tamoTheme,
    offerIds: [TAMO_VN_PARTNER_OFFER_ID, TAMO_CPS_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/on-credit-lk")).onCreditLkTheme,
    offerIds: [ON_CREDIT_VN_PARTNER_OFFER_ID, ONCREDIT_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/doctorDong")).doctorDongTheme,
    offerIds: [DOCTORDONG_VN_PARTNER_OFFER_ID, DOCTOR_DONG_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/findo")).findoTheme,
    offerIds: [FINDO_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/senmo")).senmoTheme,
    offerIds: [SENMO_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/vayvnd")).vayvndTheme,
    offerIds: [VAYVND_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/hdbank")).hdbankTheme,
    offerIds: [HDBANK_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/tienOi")).tienTheme,
    offerIds: [TIEN_OI_VN_PARTNER_OFFER_ID, TIEN_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/digido")).digidoTheme,
    offerIds: [DIGIDO_PH_PARTNER_OFFER_ID, DIGIDO_DIRECT_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/olp")).olpTheme,
    offerIds: [OLP_DIRECT_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/finbro")).finbroTheme,
    offerIds: [FINBRO_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/cashxpress")).cashxpressTheme,
    offerIds: [CASHXPRESS_PH_PARTNER_OFFER_ID, CASHEXPRESS_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/kviku")).kvikuTheme,
    offerIds: [KVIKU_DIRECT_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/vamo")).vamoTheme,
    offerIds: [VAMO_PARTNER_OFFER_ID, VAMO_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/takomo")).takomoTheme,
    offerIds: [TAKOMO_PARTNER_OFFER_ID, TAKOMO_CPS_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/on-credit-lk")).onCreditLkTheme,
    offerIds: [ON_CREDIT_LK_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/loanme-lk")).loanMeLkTheme,
    offerIds: [LOANME_LK_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () =>
      (await import("@theme/lotus-loan-lk")).lotusLoanLkTheme,
    offerIds: [LOTUS_LOAN_LK_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () =>
      (await import("@theme/fastrupee-lk")).fastrupeeLkTheme,
    offerIds: [FASTRUPEE_LK_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/cashx-lk")).cashxLkTheme,
    offerIds: [CASHX_LK_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/mybank-id")).mayBankTheme,
    offerIds: [MAYBANK_ID_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/cozmo")).cozmoTheme,
    offerIds: [
      COZMO_CPS_VN_PARTNER_OFFER_ID,
      COZMO_VN_PARTNER_OFFER_ID,
      COZMO_CPL_MX_PARTNER_OFFER_ID,
    ],
  },
  {
    getTheme: async () => (await import("@theme/moneyveo")).moneyveoTheme,
    offerIds: [MONEYVEO_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/peso-redee-ph")).pesoRedeePh,
    offerIds: [PESO_REDEE_PH_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/dong-plus-vn")).dongPlusVnTheme,
    offerIds: [DONG_PLUS_VN_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/credito365")).credito355Mx,
    offerIds: [CREDITO_365_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/dineria")).dineriaMx,
    offerIds: [DINERIA_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/lendswap")).lendswapaMx,
    offerIds: [LENDSWAP_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/kimbi")).kimbiMx,
    offerIds: [KIMBI_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/moneyman")).moneymanMx,
    offerIds: [MONEYMAN_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/vivus")).vivusMx,
    offerIds: [VIVUS_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/lendon")).lendonMx,
    offerIds: [LENDON_MX_PARTNER_OFFER_ID],
  },
  {
    getTheme: async () => (await import("@theme/prestomin")).prestominMx,
    offerIds: [PRESTOMIN_MX_PARRTNER_OFFER_ID],
  },
];
