import { BrowserPlugin } from "@snowplow/browser-tracker-core";
import { SnowplowExperiment } from "../common/types";

export const ExperimentContextPlugin = (
  getExperiments: () => SnowplowExperiment[],
): BrowserPlugin => {
  return {
    contexts: () => {
      const experiments = getExperiments();
      if (!experiments || experiments.length === 0) {
        return [];
      }
      return [
        {
          schema: "iglu:app.j3ff.web/ga_experiment_context/jsonschema/1-0-2",
          data: {
            experiments,
          },
        },
        {
          schema:
            "iglu:app.j3ff.experiments/experiment_context/jsonschema/4-0-0",
          data: {
            active_experiments: experiments
              .map((it) => it.experimentId)
              .join(","),
          },
        },
      ];
    },
  };
};
