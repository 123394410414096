import { type ExperimentId } from "@integration/experiments/constants";
import { type GetAdSenseStatusArgs } from "../types";

export const ALL_FIELDS = [
  "age",
  "gender",
  "offer_count",
  "device_brand",
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "address_city",
  "is_days_on_book_zero",
  "has_money_event",
  "has_no_redirects",
  "is_fresh",
  "quality_score",
] as const;

type Field = (typeof ALL_FIELDS)[number];

export type Rule = {
  experimentId: ExperimentId;
  conditions: Condition[];
  preprocess?: (args: {
    input: GetAdSenseStatusArgs;
    flattened: FlattenedAdSenseData;
  }) => FlattenedAdSenseData;
};

export type Operator = ">" | "<" | "==" | "!=" | ">=" | "<=" | "startsWith";

export type AgeCondition = {
  field: Extract<Field, "age">;
  operator: Operator;
  value: number | null;
};

export type OfferCountCondition = {
  field: Extract<Field, "offer_count">;
  operator: Operator;
  value: number;
};

export type DeviceBrandCondition = {
  field: Extract<Field, "device_brand">;
  operator: Operator;
  value: string | null;
};

export type UtmSourceCondition = {
  field: Extract<Field, "utm_source">;
  operator: Extract<Operator, "==" | "!=" | "startsWith">;
  value: string | null;
};

export type UtmMediumCondition = {
  field: Extract<Field, "utm_medium">;
  operator: Extract<Operator, "==" | "!=" | "startsWith">;
  value: string | null;
};

export type UtmCampaignCondition = {
  field: Extract<Field, "utm_campaign">;
  operator: Extract<Operator, "==" | "!=" | "startsWith">;
  value: string | null;
};

export type GenderCondition = {
  field: Extract<Field, "gender">;
  operator: Operator;
  value: string | null;
};

export type AddressCityCondition = {
  field: Extract<Field, "address_city">;
  operator: Operator;
  value: string | null;
};

export type IsDaysOnBookZeroCondition = {
  field: Extract<Field, "is_days_on_book_zero">;
  operator: Extract<Operator, "==" | "!=">;
  value: boolean | null;
};

export type HasMoneyEventCondition = {
  field: Extract<Field, "has_money_event">;
  operator: Extract<Operator, "==" | "!=">;
  value: boolean | null;
};

export type HasNoRedirectsEventCondition = {
  field: Extract<Field, "has_no_redirects">;
  operator: Extract<Operator, "==" | "!=">;
  value: boolean | null;
};

export type IsFreshEventCondition = {
  field: Extract<Field, "is_fresh">;
  operator: Extract<Operator, "==" | "!=">;
  value: boolean | null;
};

export type QualityScoreCondition = {
  field: Extract<Field, "quality_score">;
  operator: Operator;
  value: number | null;
};

export type Condition =
  | AgeCondition
  | OfferCountCondition
  | DeviceBrandCondition
  | UtmSourceCondition
  | UtmMediumCondition
  | UtmCampaignCondition
  | GenderCondition
  | AddressCityCondition
  | IsDaysOnBookZeroCondition
  | HasMoneyEventCondition
  | HasNoRedirectsEventCondition
  | IsFreshEventCondition
  | QualityScoreCondition;

export type FlattenedAdSenseData = {
  [key in Field]: string | number | boolean | null;
};
