import { type ProductType } from "@jeff/lead-api";
import { z } from "zod";
import { type ExperimentProps } from "@integration/experiments/types";
import { type ExperimentId } from "@integration/experiments/constants";

type Country = "vn" | "ph" | "mx" | "id";

export const SITE_IDS = [
  "jeff-vn",
  "loanonline-ph",
  "finmercado-mx",
  "finmart-vn",
] as const;

export const SiteIdSchema = z.enum(SITE_IDS);

export type SiteId = z.infer<typeof SiteIdSchema>;

export type AdsProduct = "adsense" | "gpt";

export type PlacementLocations = "top" | "mid" | "bottom";

export type AdPlacement =
  | "top"
  | "mid"
  | "bottom"
  | `${AdsProduct}-${PlacementLocations}`;

export type ConfigId =
  | `${Country}-exp${number}`
  | `${Country}-exp${number}-test-group${number}`;

export type ToggleId<E extends ConfigId> = `adsense-${E}`; // same as experiment id in unleash

export type AdUnitName<E extends ConfigId> = `${E}-${AdPlacement}`;

export type AdUnit<E extends ConfigId> = {
  id: string;
  name: AdUnitName<E>;
  placement: AdPlacement;
  adsProduct?: AdsProduct;
};

export type PlacementConfig = {
  id: ConfigId;
  siteId: SiteId;
  toggleId: ToggleId<ConfigId>;
  adUnits: AdUnit<ConfigId>[];
};

export type AdPlacementConfig = Map<AdPlacement, string>;

export type ToggleIdAware = {
  toggleId: ToggleId<ConfigId>;
};

export type RelaxedToggleIdAware = { toggleId?: string | null };

export type AdSenseExperimentConfig = {
  siteId: SiteId;
  clientId: string;
  placementConfigs: PlacementConfig[];
};

export interface AdSenseStatus {
  show: boolean;
  adsProduct?: AdsProduct;
  approach?: AdSenseApproach;
  /**
   * @deprecated
   */
  segmentName: string;
  placements?: AdPlacementConfig;
  experimentId: ExperimentId | null;
  experimentVariant: string | null;
}

export interface AdSenseProductConfig {
  enabled: boolean;
}

export enum AdSenseApproach {
  Forced = "forced",
  Disabled = "disabled",
  ExperimentBased = "experiment_based",
}

export type GetAdSenseStatusArgs = {
  config: AdSenseProductConfig;
  experiments: ExperimentProps;
  age?: number | null;
  gender?: string;
  forced: boolean;
  forcedGpt?: boolean;
  offerListing: {
    id: string;
    offers: { id: string }[];
    products: { productType: ProductType; count: number }[];
  };
  device: {
    brand?: string;
  };
  utm: {
    source?: string;
    campaign?: string;
    medium?: string;
  };
  address: {
    city?: string;
  };
  isDaysOnBookZero?: boolean;
  hasMoneyEvent?: boolean;
  hasNoRedirects?: boolean;
  isFresh?: boolean;
  qualityScore: number | null;
};
