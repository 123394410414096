import { type AdsProduct, type AdPlacementConfig } from "./types";

export const getAdsProduct = (
  placementConfig: AdPlacementConfig,
): AdsProduct => {
  const hasGPT =
    Array.from(placementConfig.keys()).find((adsPlacement) =>
      adsPlacement.startsWith("gpt"),
    ) !== undefined;

  return hasGPT ? "gpt" : "adsense";
};
