export const TEST_PLACEHOLDER_EXP_1_ID = "test-placeholder-1";
export const TEST_PLACEHOLDER_EXP_2_ID = "test-placeholder-2";
export const VN_OFFER_SORTING_EXP_ID = "KnkZB3QxS_mBqVvoxvsZ7Q";
// VN AdSense
export const VN_ADSENSE_REMAINING_VISITORS_EXP_ID = "adsense-vn-exp0";
export const VN_ADSENSE_EXP_0_ID = "adsense-vn-exp0";
export const VN_ADSENSE_EXP_1_ID = "adsense-vn-exp1";
export const VN_ADSENSE_EXP_3_ID = "adsense-vn-exp3";
export const VN_ADSENSE_EXP_4_ID = "adsense-vn-exp4";
export const VN_ADSENSE_EXP_5_ID = "adsense-vn-exp5";
export const VN_ADSENSE_EXP_6_ID = "adsense-vn-exp6";
export const VN_ADSENSE_EXP_7_ID = "adsense-vn-exp7";
export const VN_ADSENSE_EXP_10_ID = "adsense-vn-exp10";
export const VN_ADSENSE_CHEAP_FACEBOOK_TRAFFIC_EXP_ID = "adsense-vn-exp9";
export const VN_ADSENSE_CHEAP_GOOGLE_TRAFFIC_EXP_ID = "adsense-vn-exp10";
export const VN_FINMART_ADSENSE_ALL_VISITORS_EXP_ID = "adsense-vn-exp8";
export const VN_ADSENSE_EXP_11_ID = "adsense-vn-exp11";
// -- end VN AdSense
// PH AdSense
export const PH_ADSENSE_REMAINING_VISITORS_EXP_ID = "adsense-ph-exp0";
export const PH_ADSENSE_EXP_1_ID = "adsense-ph-exp1";
export const PH_ADSENSE_EXP_2_ID = "adsense-ph-exp2";
export const PH_ADSENSE_EXP_3_ID = "adsense-ph-exp3";
export const PH_ADSENSE_EXP_4_ID = "adsense-ph-exp4";
export const PH_ADSENSE_EXP_5_ID = "adsense-ph-exp5";
export const PH_ADSENSE_EXP_6_ID = "adsense-ph-exp6";
export const PH_ADSENSE_EXP_7_ID = "adsense-ph-exp7";
export const PH_ADSENSE_EXP_8_ID = "adsense-ph-exp8";
export const PH_ADSENSE_EXP_9_ID = "adsense-ph-exp9";
export const PH_ADSENSE_EXP_10_ID = "adsense-ph-exp10";
// -- end PH AdSense
// MX AdSense
export const MX_ADSENSE_REMAINING_VISITORS_EXP_ID = "adsense-mx-exp0";
export const MX_ADSENSE_EXP_1_ID = "adsense-mx-exp1";
export const MX_ADSENSE_EXP_2_ID = "adsense-mx-exp2";
export const MX_ADSENSE_EXP_3_ID = "adsense-mx-exp3";
export const MX_ADSENSE_EXP_4_ID = "adsense-mx-exp4";
export const MX_ADSENSE_EXP_5_ID = "adsense-mx-exp5";
export const MX_ADSENSE_EXP_6_ID = "adsense-mx-exp6";
export const MX_ADSENSE_EXP_7_ID = "adsense-mx-exp7";
export const MX_ADSENSE_EXP_8_ID = "adsense-mx-exp8";
export const MX_ADSENSE_EXP_9_ID = "adsense-mx-exp9";
export const ID_ADSENSE_ALL_VISITORS_EXP_ID = "adsense-id-exp1";
export const MX_ADSENSE_EXP_10_ID = "adsense-mx-exp10";
// -- end MX AdSense
export const COMPANY_REVIEW_AFTER_FEEDBACK_EXP_ID =
  "company-review-after-feedback";
export const LEVEL_UP_FEATURE_ENABLED_EXP_ID = "level-up-feature-enabled";
export const QUESTION_EARNING_VN_EXP_ID =
  "question-about-earning-opportunities-vn";
export const QUESTION_EARNING_PH_EXP_ID =
  "question-about-earning-opportunities-ph";
export const QUESTION_EARNING_MX_EXP_ID =
  "question-about-earning-opportunities-mx";
export const LEVEL_UP_PROFILE_DASHBOARD_BANNER_EXP_ID =
  "level-up-profile-dashboard-banner";
export const MONEYMAN_AUTO_REDIRECT_EXP_ID = "moneyman-auto-redirect-exp";
export const FINBRO_AUTO_REDIRECT_EXP_ID = "finbro-auto-redirect-exp";
export const LEVEL_UP_OFFER_LISTING_BANNER_EXP_ID =
  "level-up-offer-listing-banner";
export const EXTENDED_MX_AUTO_REDIRECT_EXP_ID = "extended-mx-auto-redirect-exp";
export const BUY_INSURANCE_VN_EXP_ID = "buy-insurance-vn";
export const LEAVE_FEEDBACK_EXP_ID = "leave-feedback";
export const REGISTRATION_CAPTCHA_EXP_ID = "registration-captcha-enabled";
export const ACTIVITY_LISTING_IN_OFFERS = "activity-listing-in-offers";
export const OFFER_CARD_LOAN_PROBABILITY_EXP_ID = "offer-card-loan-probability";
export const SKIP_ID_BUTTON_EXP_ID = "skip-id-button-experiment";
export const TOP_OFFER_AUTO_REDIRECT_USER_CHOICE_EXP_ID =
  "top-offer-auto-redirect-user-choice";
export const LOCATION_INPUT_VARIANTS_EXP_ID = "location-input-variants";
export const OL_LEAVE_FEEDBACK_EXP_ID = "offer-listing-leave-feedback";
export const SMALL_OFFER_CARDS_EXP_ID = "small-offer-cards";
export const OL_AD_IN_LOADER_EXP_ID = "offer-listing-loading-ad";
export const LEVEL_UP_ACCOUNT_EXP_ID = "level-up-account";
export const DOB_INPUT_VARIANTS_EXP_ID = "dob-input-variants";
export const INCOME_INPUT_VARIANTS_EXP_ID = "income-input-variants";
export const APPLICATION_FLOW_PROGRESS_BAR_VARIANTS_EXP_ID =
  "application-flow-progress-bar-variants";
export const REGISTRATION_TIMER_EXP_ID = "registration-timer";
export const BEST_OFFERS_CARD_COLOR_CODING_EXP_ID =
  "best-offer-card-color-coding-variants";
export const USER_IDLING_POP_UP_IN_FLOW_NAME_AND_DOB_EXP_ID =
  "user-idling-pop-up-in-flow-name-and-dob";
export const OL_FEEDBACK_POP_UP_EXP_ID = "offer-listing-feedback-pop-up";

export const ALL_KNOWN_EXPERIMENTS = [
  TEST_PLACEHOLDER_EXP_1_ID,
  TEST_PLACEHOLDER_EXP_2_ID,
  VN_ADSENSE_REMAINING_VISITORS_EXP_ID,
  VN_ADSENSE_EXP_0_ID,
  VN_ADSENSE_EXP_1_ID,
  VN_ADSENSE_EXP_3_ID,
  VN_ADSENSE_EXP_4_ID,
  VN_ADSENSE_EXP_5_ID,
  VN_ADSENSE_EXP_6_ID,
  VN_ADSENSE_EXP_7_ID,
  VN_ADSENSE_CHEAP_FACEBOOK_TRAFFIC_EXP_ID,
  VN_ADSENSE_CHEAP_GOOGLE_TRAFFIC_EXP_ID,
  VN_OFFER_SORTING_EXP_ID,
  PH_ADSENSE_REMAINING_VISITORS_EXP_ID,
  PH_ADSENSE_EXP_1_ID,
  PH_ADSENSE_EXP_2_ID,
  PH_ADSENSE_EXP_3_ID,
  PH_ADSENSE_EXP_4_ID,
  PH_ADSENSE_EXP_5_ID,
  PH_ADSENSE_EXP_6_ID,
  PH_ADSENSE_EXP_7_ID,
  PH_ADSENSE_EXP_8_ID,
  PH_ADSENSE_EXP_9_ID,
  MX_ADSENSE_REMAINING_VISITORS_EXP_ID,
  MX_ADSENSE_EXP_1_ID,
  MX_ADSENSE_EXP_2_ID,
  MX_ADSENSE_EXP_3_ID,
  MX_ADSENSE_EXP_4_ID,
  MX_ADSENSE_EXP_5_ID,
  MX_ADSENSE_EXP_6_ID,
  MX_ADSENSE_EXP_7_ID,
  MX_ADSENSE_EXP_8_ID,
  MX_ADSENSE_EXP_9_ID,
  PH_ADSENSE_EXP_4_ID,
  COMPANY_REVIEW_AFTER_FEEDBACK_EXP_ID,
  LEVEL_UP_FEATURE_ENABLED_EXP_ID,
  LEVEL_UP_PROFILE_DASHBOARD_BANNER_EXP_ID,
  MONEYMAN_AUTO_REDIRECT_EXP_ID,
  FINBRO_AUTO_REDIRECT_EXP_ID,
  VN_FINMART_ADSENSE_ALL_VISITORS_EXP_ID,
  ID_ADSENSE_ALL_VISITORS_EXP_ID,
  QUESTION_EARNING_VN_EXP_ID,
  QUESTION_EARNING_PH_EXP_ID,
  QUESTION_EARNING_MX_EXP_ID,
  LEVEL_UP_OFFER_LISTING_BANNER_EXP_ID,
  EXTENDED_MX_AUTO_REDIRECT_EXP_ID,
  BUY_INSURANCE_VN_EXP_ID,
  LEAVE_FEEDBACK_EXP_ID,
  REGISTRATION_CAPTCHA_EXP_ID,
  ACTIVITY_LISTING_IN_OFFERS,
  PH_ADSENSE_EXP_10_ID,
  OFFER_CARD_LOAN_PROBABILITY_EXP_ID,
  SKIP_ID_BUTTON_EXP_ID,
  TOP_OFFER_AUTO_REDIRECT_USER_CHOICE_EXP_ID,
  LOCATION_INPUT_VARIANTS_EXP_ID,
  OL_LEAVE_FEEDBACK_EXP_ID,
  SMALL_OFFER_CARDS_EXP_ID,
  LEVEL_UP_ACCOUNT_EXP_ID,
  OL_AD_IN_LOADER_EXP_ID,
  DOB_INPUT_VARIANTS_EXP_ID,
  INCOME_INPUT_VARIANTS_EXP_ID,
  APPLICATION_FLOW_PROGRESS_BAR_VARIANTS_EXP_ID,
  REGISTRATION_TIMER_EXP_ID,
  VN_ADSENSE_EXP_11_ID,
  MX_ADSENSE_EXP_10_ID,
  BEST_OFFERS_CARD_COLOR_CODING_EXP_ID,
  OL_FEEDBACK_POP_UP_EXP_ID,
  USER_IDLING_POP_UP_IN_FLOW_NAME_AND_DOB_EXP_ID,
] as const;

export type ExperimentId = (typeof ALL_KNOWN_EXPERIMENTS)[number];
