import { isBrowser } from "@utils/env/isBrowser";

type ReleaseStage = "dev" | "stage-env" | "prod-env" | "build";

export const getReleaseStage = (): ReleaseStage => {
  if (!isBrowser()) {
    return "build";
  }
  const { host } = window.location;
  if (host.indexOf("localhost") > -1) {
    return "dev";
  }
  if (host.indexOf("dev") > -1) {
    return "stage-env";
  }
  return "prod-env";
};
