import { clientSideEnvVariable } from "@client-env";

const PRODUCTION_ENV =
  process.env.NODE_ENV === "production" &&
  clientSideEnvVariable("DEV_ENV") !== "true";
const UNIT_TEST = process.env.NODE_ENV === "test";
const UUID_LENGTH = 36;
const DEV_PREFIX = "dev-";
const LOCAL_PREFIX = "local-";

export const isLeadIdValid = ({
  leadId,
}: {
  leadId: string | undefined | null;
}) => {
  if (!leadId) {
    return false;
  }
  if (PRODUCTION_ENV || UNIT_TEST) {
    return leadId.length === UUID_LENGTH;
  }
  if (
    leadId.startsWith(DEV_PREFIX) &&
    leadId.length === UUID_LENGTH + DEV_PREFIX.length
  ) {
    return true;
  }
  if (
    leadId.startsWith(LOCAL_PREFIX) &&
    leadId.length === UUID_LENGTH + LOCAL_PREFIX.length
  ) {
    return true;
  }
  return false;
};
