import {
  FINMART_ID_DEV_DOMAIN,
  FINMART_ID_DOMAIN,
  FINMART_VN_DEV_DOMAIN,
  FINMART_VN_DOMAIN,
  FINMERCADO_MX_DEV_DOMAIN,
  FINMERCADO_MX_DOMAIN,
  type FINMERKADO_PH_BRAND_ID,
  JEFF_DEV_DOMAIN,
  JEFF_DOMAIN,
  JEFF_WEBFLOW_DOMAIN,
  LOANONLINE_DEV_DOMAIN,
  LOANONLINE_DOMAIN,
  LOCALHOST_DOMAIN,
  type FINMART_ID_BRAND_ID,
  type FINMART_ID_BRAND_NAME,
  type FINMART_ID_HOMEPAGE,
  type FINMART_VN_BRAND_ID,
  type FINMART_VN_BRAND_NAME,
  type FINMART_VN_HOMEPAGE,
  type FINMERCADO_MX_BRAND_ID,
  type FINMERCADO_MX_HOMEPAGE,
  type FINMERCARDO_MX_BRAND_NAME,
  type JEFF_VN_BRAND_ID,
  type JEFF_VN_BRAND_NAME,
  type JEFF_VN_HOMEPAGE,
  type LOANONLINE_BRAND_ID,
  type LOANONLINE_BRAND_NAME,
  type LOANONLINE_HOMEPAGE,
  type LOCALHOST_HOMEPAGE,
  type FINMERKADO_PH_HOMEPAGE,
  FINMERKADO_PH_DEV_DOMAIN,
  type FINMERKARDO_PH_BRAND_NAME,
  FINMERKADO_PH_DOMAIN,
} from "../constants/constants";
import type { LeadPageId } from "@domain/lead-pages";
import type { PartnerOfferId } from "@domain/partner-offers/types";

export type BrandId =
  | typeof JEFF_VN_BRAND_ID
  | typeof LOANONLINE_BRAND_ID
  | typeof FINMART_ID_BRAND_ID
  | typeof FINMART_VN_BRAND_ID
  | typeof FINMERCADO_MX_BRAND_ID
  | typeof FINMERKADO_PH_BRAND_ID;

export type EntityId = BrandId | PartnerOfferId | LeadPageId;

export type HomePage =
  | typeof JEFF_VN_HOMEPAGE
  | typeof LOCALHOST_HOMEPAGE
  | typeof LOANONLINE_HOMEPAGE
  | typeof FINMART_ID_HOMEPAGE
  | typeof FINMART_VN_HOMEPAGE
  | typeof FINMART_VN_HOMEPAGE
  | typeof FINMART_VN_DOMAIN
  | typeof FINMART_VN_DEV_DOMAIN
  | typeof FINMERKADO_PH_HOMEPAGE
  | typeof FINMERCADO_MX_HOMEPAGE;

export const brandDomains = [
  JEFF_DOMAIN,
  JEFF_DEV_DOMAIN,
  JEFF_WEBFLOW_DOMAIN,
  LOCALHOST_DOMAIN,
  LOANONLINE_DOMAIN,
  LOANONLINE_DEV_DOMAIN,
  FINMART_ID_DOMAIN,
  FINMART_ID_DEV_DOMAIN,
  FINMART_VN_DOMAIN,
  FINMART_VN_DEV_DOMAIN,
  FINMERCADO_MX_DOMAIN,
  FINMERCADO_MX_DEV_DOMAIN,
  FINMERCADO_MX_DOMAIN,
  FINMERKADO_PH_DEV_DOMAIN,
  FINMERKADO_PH_DOMAIN,
] as const;

export type BrandDomains = (typeof brandDomains)[number];

export type BrandName =
  | typeof JEFF_VN_BRAND_NAME
  | typeof LOANONLINE_BRAND_NAME
  | typeof FINMART_ID_BRAND_NAME
  | typeof FINMART_VN_BRAND_NAME
  | typeof FINMERKARDO_PH_BRAND_NAME
  | typeof FINMERCARDO_MX_BRAND_NAME;

export interface Branding {
  brandId: BrandId;
  brandName: BrandName;
  brandDomain: BrandDomains;
  cookieDomain: string;
  homepage: HomePage;
  gtmId: string;
  tncLink: string;
  privacyPolicyLink: string;
}

export type ErrorFunc = (e: Error | string) => void | Promise<void>;
