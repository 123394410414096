/* eslint-disable no-console */
import React, { Fragment, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  type BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";
import {
  isErrorTypeOf,
  isErrorOfAnyType,
} from "@jeff/common-resources/src/integration/errors";
import { config } from "@utils/config";
import { getReleaseStage } from "@utils/env";
import { asSampledError } from "../error-types";
import { isReportable } from "./uncaught-event-handling";
import { shouldReport } from "./caught-exception-reporting-predicate";

const IS_BROWSER = typeof window !== "undefined";
let ErrorBoundary: BugsnagErrorBoundary | undefined;

if (config.bugsnag.enabled && IS_BROWSER && !Bugsnag.isStarted()) {
  const releaseStage = () => {
    const stage = getReleaseStage();
    if (stage === "prod-env") {
      return "production";
    }
    if (stage === "stage-env") {
      return "stage";
    }
    return "development";
  };
  Bugsnag.start({
    apiKey: config.bugsnag.client.apiKey,
    plugins: [new BugsnagPluginReact()],
    appVersion: config.app.version,
    releaseStage: releaseStage(),
    autoDetectErrors: config.bugsnag.enabled,
    enabledReleaseStages: ["production"],
    onError: (event) => isReportable({ event }),
    enabledErrorTypes: {
      // anything that is thrown outside React boundaries, try-catch blocks etc. please refer to the ./uncaught-event-handling
      unhandledExceptions: true,
      // any unhandled promise rejection, please think twice when coding async functions
      unhandledRejections: true,
    },
  });
  ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
}

const onError = <T extends Error | string>(
  e: T,
  metadata?: { leadId?: string | null } & Record<string, unknown>,
) => {
  if (!IS_BROWSER || !config.bugsnag.enabled) {
    console.error(e);
    return;
  }
  const reportable = asSampledError(e);
  if (reportable.shouldSkipReporting) {
    console.log(
      `Error reporting skipped due to the sampling: ${reportable.name}:${reportable.message}`,
    );
    return;
  }
  if (!shouldReport({ error: reportable })) {
    console.log(
      `Error reporting skipped due to the hardcoded reporting rules: ${reportable.name}:${reportable.message}`,
    );
    return;
  }
  Bugsnag.notify(reportable, (event) => {
    const leadId = metadata?.leadId;
    if (leadId) {
      event.setUser(leadId);
    }
    event.addMetadata("metaData", {
      ...(metadata ?? {}),
      ...{
        caughtBy: "caughtExceptionHandler@integration/bugsnag",
      },
    });
  });
};

const Boundary = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    window.onerror = (message, source, lineno, colno, error) => {
      let reportable = error;
      if (
        !reportable &&
        typeof message === "string" &&
        message.indexOf("Script error.") > -1
      ) {
        return true;
      }
      if (!reportable) {
        if (
          typeof message === "string" &&
          message.indexOf("ReferenceError:") > -1
        ) {
          reportable = new ReferenceError(message.split("ReferenceError:")[1]);
        } else {
          reportable = new Error(
            typeof message === "string"
              ? message
              : `${source}:${lineno}:${colno}`,
          );
          reportable.name = "UnknownError";
        }
        reportable.stack = `at ${source}:${lineno}:${colno}`;
      }
      if (
        reportable instanceof ReferenceError &&
        reportable.message.toLowerCase().includes("zalojs")
      ) {
        return true;
      }
      onError(reportable, {
        caughtBy: "uncaughtExceptionHandler@integration/bugsnag",
      });
      return true;
    };
    window.addEventListener("unhandledrejection", function (event) {
      const reportable = asReportable(event.reason);
      const isReasonError = event.reason instanceof Error;

      onError(reportable, {
        caughtBy: "unhandledRejectionHandler@integration/bugsnag",
        stackTrace: isReasonError
          ? event.reason.stack
          : "No stack trace available",
      });
    });
    return () => {
      window.onerror = null;
    };
  }, []);
  if (ErrorBoundary) {
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
  return <Fragment>{children}</Fragment>;
};

const leaveBreadcrumb = ({
  message,
  metadata,
}: {
  message: string;
  metadata?: { [key: string]: unknown };
}) => {
  if (!IS_BROWSER || !config.bugsnag.enabled) {
    console.log({ message, metadata });
    return;
  }
  Bugsnag.leaveBreadcrumb(message, metadata);
};

const asReportable = (e: Error | string | unknown): Error => {
  if (e instanceof Error) {
    return e;
  } else if (typeof e === "string") {
    return new Error(e);
  }
  return new Error(String(e));
};

export {
  Boundary as ErrorBoundary,
  onError,
  leaveBreadcrumb,
  isErrorTypeOf,
  isErrorOfAnyType,
  asReportable,
};
