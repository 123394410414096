type ServerSideEnvKey =
  | "BACKEND_URL"
  | "SERVICES_API_USERNAME"
  | "SERVICES_API_PASSWORD"
  | "DEV_ENV"
  | "TESTING_API_PASSWORD"
  | "VERCEL_ENV"
  | "CRON_SECRET"
  | "BACKEND_WEBFLOW_CMS_WEBHOOK_API_KEY"
  | "BACKEND_APP_ACTUATOR_PASSWORD"
  | "GOOGLE_GEOCODING_API_KEY";

interface VariableEntry {
  key: ServerSideEnvKey;
  value?: string;
}

const entries: VariableEntry[] = [
  {
    key: "BACKEND_URL",
    value: process.env.BACKEND_URL || "https://api-dev.jeff.vn",
  },
  {
    key: "SERVICES_API_USERNAME",
    value: process.env.SERVICES_API_USERNAME,
  },
  {
    key: "SERVICES_API_PASSWORD",
    value: process.env.SERVICES_API_PASSWORD,
  },
  {
    key: "DEV_ENV",
    value: process.env.DEV_ENV,
  },
  {
    key: "TESTING_API_PASSWORD",
    value: process.env.TESTING_API_PASSWORD,
  },
  {
    key: "VERCEL_ENV",
    value: process.env.VERCEL_ENV,
  },
  {
    key: "CRON_SECRET",
    value: process.env.CRON_SECRET,
  },
  {
    key: "BACKEND_WEBFLOW_CMS_WEBHOOK_API_KEY",
    value: process.env.BACKEND_WEBFLOW_CMS_WEBHOOK_API_KEY,
  },
  {
    key: "BACKEND_APP_ACTUATOR_PASSWORD",
    value: process.env.BACKEND_APP_ACTUATOR_PASSWORD,
  },
  {
    key: "GOOGLE_GEOCODING_API_KEY",
    value: process.env.NEXT_PUBLIC_GOOGLE_GEOCODING_API_KEY,
  },
];

export const serverSideEnvVariable = (
  key: ServerSideEnvKey,
): string | undefined => {
  const entry = entries.find((it) => it.key === key);
  if (!entry) {
    return;
  }
  const { value } = entry;
  if (!value) {
    return;
  }
  return value;
};

export const requireServerSideEnvVariable = (key: ServerSideEnvKey): string => {
  const value = serverSideEnvVariable(key);
  if (!value) {
    throw new Error(`Could not get env variable for key '${key}'`);
  }
  return value;
};
