import { useEffect, useState } from "react";
import { onError } from "@integration/bugsnag/csr";
import { snowplowEndpoint } from "./snowplowEndpoint";
import type { ExperimentProps } from "@integration/experiments/types";

interface Args {
  brandId: string;
  leadId?: string;
  domainName: string;
  experiments?: ExperimentProps;
}

export const useInitSnowplow = ({
  leadId,
  domainName,
  brandId,
  experiments,
}: Args) => {
  const [isSnowPlowReady, setIsSnowPlowReady] = useState(false);

  useEffect(() => {
    const initSpAsync = async () => {
      const initSnowplow = (await import("@integration/analytics/snowplow"))
        .initSnowplow;

      initSnowplow({
        appId: brandId,
        leadId,
        endpoint: snowplowEndpoint({ domainName, onError }),
        experiments,
      });

      setIsSnowPlowReady(true);
    };

    if (experiments && !isSnowPlowReady) {
      // wait till experiments are loaded, otherwise init will be called several times
      initSpAsync().catch(onError);
    }
  }, [brandId, domainName, experiments, isSnowPlowReady, leadId]);

  return isSnowPlowReady;
};
