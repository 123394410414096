import type { Languages } from "../localisation-config/types";

const pickFirstMatchingLanguage = (
  browserLanguages: string[],
  allowedLanguages: Languages[],
) => {
  return browserLanguages.find((browserLanguage) =>
    (allowedLanguages as string[]).includes(browserLanguage),
  ) as Languages;
};

export const getInitialLang = ({
  receivedLang,
  allowedLanguages,
  browserLanguages,
  defaultBrandLanguage,
  storedLang,
}: {
  receivedLang?: Languages;
  storedLang?: Languages;
  browserLanguages?: string[];
  defaultBrandLanguage: Languages;
  allowedLanguages: Languages[];
}) => {
  const isReceivedLangAllowed =
    !!receivedLang && allowedLanguages.includes(receivedLang);
  const isStoredLangAllowed =
    !!storedLang && allowedLanguages.includes(storedLang);

  if (isReceivedLangAllowed) {
    return receivedLang;
  }

  if (isStoredLangAllowed) {
    return storedLang;
  }

  if (browserLanguages) {
    const allowedBrowserLanguage = pickFirstMatchingLanguage(
      browserLanguages,
      allowedLanguages,
    );

    if (allowedBrowserLanguage) {
      return allowedBrowserLanguage;
    }
  }

  return defaultBrandLanguage;
};
