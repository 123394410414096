import {
  PARAM_NAME_ADID,
  PARAM_NAME_CAMPAIGN,
  PARAM_NAME_GOOGLE_CLICK_ID,
  PARAM_NAME_MEDIUM,
  PARAM_NAME_PUBLISHER_CLICK_ID,
  PARAM_NAME_PUBLISHER_NAME,
  PARAM_NAME_PUBLISHER_SUB_PUB_ID,
  PARAM_NAME_QUERY_PARAMS_JSON,
  PARAM_NAME_REFERRER,
  PARAM_NAME_UTM_CAMPAIGN,
  PARAM_NAME_UTM_CONTENT,
  PARAM_NAME_UTM_ID,
  PARAM_NAME_UTM_MEDIUM,
  PARAM_NAME_UTM_SOURCE,
  PARAM_NAME_UT_ID,
} from "./marketingParams";

const ONE_HOUR_IN_SECONDS = 60 * 60;
const ONE_MONTH_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24 * 30;

interface QueryParam {
  key: string;
  value: string;
}

export interface StoreMarketingData {
  cookieDomain: string;
  params: Record<string, string | undefined>;
  referrer?: string | null;
  setCookie: (key: string, value: string, maxAge: number) => void;
  deleteCookie: (key: string) => void;
  getCookie: (key: string) => string | undefined | null;
  storeQueryParams: boolean | undefined;
}

export const storeMarketingData = ({
  cookieDomain,
  deleteCookie,
  setCookie,
  getCookie,
  params,
  referrer,
  storeQueryParams,
}: StoreMarketingData) => {
  const setSessionCookie = (key: string, value: string) => {
    setCookie(key, value, ONE_HOUR_IN_SECONDS);
  };
  const setLongLivingCookie = (key: string, value: string) => {
    setCookie(key, value, ONE_MONTH_IN_SECONDS);
  };
  const setLongLivingCookieIfModified = (key: string, value: string) => {
    const currentValue = getCookie(key);
    if (currentValue !== value) {
      setLongLivingCookie(key, value);
    }
  };
  const refreshSessionCookie = (key: string) => {
    const value = getCookie(key);
    value && setSessionCookie(key, value);
  };

  const handleSessionDataParams = (
    utmSource: string | undefined,
    utmMedium: string | undefined,
    utmCampaign: string | undefined,
    utmContent: string | undefined,
    utmId: string | undefined,
    queryParams: string | undefined,
  ) => {
    if (utmSource || utmMedium || utmCampaign || utmContent || utmId) {
      deleteCookie(PARAM_NAME_UTM_SOURCE);
      deleteCookie(PARAM_NAME_UTM_MEDIUM);
      deleteCookie(PARAM_NAME_UTM_CAMPAIGN);
      deleteCookie(PARAM_NAME_UTM_CONTENT);
      deleteCookie(PARAM_NAME_UTM_ID);
      if (utmSource !== "publisher") {
        clearPublisherCookies();
      }

      utmSource && setSessionCookie(PARAM_NAME_UTM_SOURCE, utmSource);
      utmMedium && setSessionCookie(PARAM_NAME_UTM_MEDIUM, utmMedium);
      utmCampaign && setSessionCookie(PARAM_NAME_UTM_CAMPAIGN, utmCampaign);
      utmContent && setSessionCookie(PARAM_NAME_UTM_CONTENT, utmContent);
      utmId && setSessionCookie(PARAM_NAME_UTM_ID, utmId);
    } else {
      refreshSessionCookie(PARAM_NAME_UTM_SOURCE);
      refreshSessionCookie(PARAM_NAME_UTM_MEDIUM);
      refreshSessionCookie(PARAM_NAME_UTM_CAMPAIGN);
      refreshSessionCookie(PARAM_NAME_UTM_CONTENT);
      refreshSessionCookie(PARAM_NAME_UTM_ID);
    }
    if (queryParams) {
      setSessionCookie(PARAM_NAME_QUERY_PARAMS_JSON, queryParams);
    } else {
      refreshSessionCookie(PARAM_NAME_QUERY_PARAMS_JSON);
    }
  };

  const shouldOverridePublisherCookies = (
    utid: string,
    publisherName: string,
    subPubId: string | undefined,
  ): boolean => {
    const res =
      getCookie(PARAM_NAME_PUBLISHER_NAME) !== publisherName ||
      getCookie(PARAM_NAME_PUBLISHER_CLICK_ID) !== utid ||
      getCookie(PARAM_NAME_PUBLISHER_SUB_PUB_ID) !== subPubId;

    return res;
  };

  const clearPublisherCookies = () => {
    deleteCookie(PARAM_NAME_PUBLISHER_NAME);
    deleteCookie(PARAM_NAME_PUBLISHER_CLICK_ID);
    deleteCookie(PARAM_NAME_PUBLISHER_SUB_PUB_ID);
  };

  const handlePublisherParams = (
    publisherClickId: string | undefined,
    publisherName: string | undefined,
    publisherSubPubId: string | undefined,
  ) => {
    if (
      publisherClickId &&
      publisherName &&
      shouldOverridePublisherCookies(
        publisherClickId,
        publisherName,
        publisherSubPubId,
      )
    ) {
      clearPublisherCookies();
      setLongLivingCookie(PARAM_NAME_PUBLISHER_NAME, publisherName);
      setLongLivingCookie(PARAM_NAME_PUBLISHER_CLICK_ID, publisherClickId);
      if (publisherSubPubId) {
        setLongLivingCookie(PARAM_NAME_PUBLISHER_SUB_PUB_ID, publisherSubPubId);
      }
    }
  };

  const utmSource = params[PARAM_NAME_UTM_SOURCE];
  const utmMedium = params[PARAM_NAME_UTM_MEDIUM];
  const utmCampaign = params[PARAM_NAME_UTM_CAMPAIGN];
  const utmContent = params[PARAM_NAME_UTM_CONTENT];
  const utmId = params[PARAM_NAME_UTM_ID];
  const queryParams = params[PARAM_NAME_QUERY_PARAMS_JSON];

  handleSessionDataParams(
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmId,
    queryParams,
  );

  const googleClickId = params[PARAM_NAME_GOOGLE_CLICK_ID];

  const publisherClickId = params[PARAM_NAME_PUBLISHER_CLICK_ID];
  const publisherName = params[PARAM_NAME_PUBLISHER_NAME];
  const publisherSubPubId = params[PARAM_NAME_PUBLISHER_SUB_PUB_ID];

  handlePublisherParams(publisherClickId, publisherName, publisherSubPubId);

  if (utmSource === "publisher") {
    if (!publisherClickId) {
      handlePublisherParams(params[PARAM_NAME_UT_ID], utmCampaign, utmContent);
    }
  } else if (googleClickId) {
    setLongLivingCookieIfModified(PARAM_NAME_GOOGLE_CLICK_ID, googleClickId);

    if (!utmSource && !utmMedium && !utmCampaign && !utmId) {
      const medium = params[PARAM_NAME_MEDIUM];
      const mediumValue = medium ? medium : "cpc";
      const googleCampaignId = params[PARAM_NAME_CAMPAIGN];
      const adid = params[PARAM_NAME_ADID];

      handleSessionDataParams(
        "google",
        mediumValue,
        googleCampaignId,
        utmContent,
        adid,
        queryParams,
      );
    }
  } else if (utmSource?.startsWith("coccoc_")) {
    const cocCocCampaignId = params[PARAM_NAME_CAMPAIGN];
    if (cocCocCampaignId) {
      setSessionCookie(PARAM_NAME_UTM_CAMPAIGN, cocCocCampaignId);
    }
  }

  if (referrer) {
    const currentReferrer = getCookie(PARAM_NAME_REFERRER);
    if (!currentReferrer && !referrer.includes(cookieDomain)) {
      setSessionCookie(PARAM_NAME_REFERRER, referrer);
    } else {
      refreshSessionCookie(PARAM_NAME_REFERRER);
    }
  }

  if (storeQueryParams) {
    const queryParams: QueryParam[] = Object.entries(params)
      .map(([key, value]) => ({
        key: key,
        value: value || "",
      }))
      .filter((it) => it.key !== PARAM_NAME_QUERY_PARAMS_JSON);

    if (queryParams.length > 0) {
      const json = JSON.stringify(queryParams);
      const base64String = Buffer.from(json).toString("base64");
      setSessionCookie(
        PARAM_NAME_QUERY_PARAMS_JSON,
        encodeURIComponent(base64String),
      );
    }
  }
};
