import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import "core-js/features/string/replace-all";

if (
  typeof window !== "undefined" &&
  typeof window.queueMicrotask !== "function"
) {
  window.queueMicrotask = function (callback) {
    Promise.resolve()
      .then(callback)
      .catch((e) =>
        setTimeout(() => {
          throw e;
        }, 0),
      );
  };
}
