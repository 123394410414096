import {
  PARTNER_ID_SLUG,
  PARTNER_REDIRECT_SLUG,
  PARTNER_REVIEWS_SLUG,
  PARTNERS_SLUG,
} from "./routeParams";

export const EMAIL_REGISTRATION_PAGE_URI = "/registration";
/**
 * @deprecated
 */
export const SUB_PRIME_PAGE_URI = "/sub-prime";
export const OFFERS_PAGE_URI = "/offers";
export const CC_OFFERS_PAGE_URI = "/offers/credit-cards";
export const GUEST_OFFERS_PAGE_URI = "/guest-offers";

export const LEAD_PAGE_LANDING_URI = "/lead-pages/lead-form-landing";
export const LEAD_FORM_PAGE_URI = "/lead-form";
export const PARTNER_OFFER_REDIRECT_PAGE_URI =
  `${PARTNERS_SLUG}${PARTNER_ID_SLUG}${PARTNER_REDIRECT_SLUG}` as const;
export const PARTNER_OFFER_REVIEWS_PAGE_URI =
  `${PARTNERS_SLUG}${PARTNER_ID_SLUG}${PARTNER_REVIEWS_SLUG}` as const;
export const USER_PROFILE_PAGE_URI = "/profile";

export const _404_PAGE_URI = "/no-existant-route";

export const LEAD_PAGE_URI = "/lead-page";

export const EMAILS_CONFIRM_SUBSCRIPTION = "/emails/confirm-subscription";
export const ZALO_REDIRECT_TEST = "/zalo-redirect-test";

export const DASHBOARD_URI = USER_PROFILE_PAGE_URI + "/dashboard";
export const MY_ACTIVITY_URI = USER_PROFILE_PAGE_URI + "/my-activity";
export const MY_PROFILE_URI = USER_PROFILE_PAGE_URI + "/me";
export const PAGE_URI_LEVEL_UP = `${USER_PROFILE_PAGE_URI}/level-up`;
export const PAGE_URI_LEVEL_UP_ENROLL = `${PAGE_URI_LEVEL_UP}/enroll`;
export const PAGE_URI_LEVEL_UP_BUY = `${PAGE_URI_LEVEL_UP}/buy`;
export const PAGE_URI_MY_CREDIT_HISTORY =
  USER_PROFILE_PAGE_URI + "/my-credit-history";
export const PAGE_URI_MY_CREDIT_CARD_OWNERSHIP =
  USER_PROFILE_PAGE_URI + "/my-credit-card-ownership";
export const PAGE_URI_LEVEL_UP_REWARDS = PAGE_URI_LEVEL_UP + "/rewards";
export const PAGE_URI_PAYMENT_FAILED = `${USER_PROFILE_PAGE_URI}/payment-failed`;

export const SIGN_UP_PAGE_URI = "/sign-up";
export const EMAIL_SIGN_UP_PAGE_URI = SIGN_UP_PAGE_URI + "/email";
export const SIGN_IN_PAGE_URI = "/sign-in";
export const SIGN_OUT_PAGE_URI = "/sign-out";
export const CONFIRM_EMAIL_PAGE_URI = "/confirm-email";
export const CONFIRM_EMAIL_CALLBACK_PAGE_URI =
  `${CONFIRM_EMAIL_PAGE_URI}/callback` as const;
export const CREATE_ACCOUNT_PAGE_URI = "/create-account";
export const SIGN_UP_INTENT_CALLBACK = "/sign-up/intent-callback";
export const PASSWORD_RESET_PAGE_URI = "/password-reset";
export const PASSWORD_RESET_CALLBACK_PAGE_URI = "/password-reset/callback";
export const PAGE_URI_EMAIL_PREFERENCES = "/email-preferences";
export const PAGE_URI_EMAIL_PREFERENCES_SUCCESS =
  PAGE_URI_EMAIL_PREFERENCES + "/success";

export const ZALO_LANDING = "/zalo-landing";
export const ZALO_DETAILS = "/zalo-details";
export const ZALO_DETAILS_PHONE = `${ZALO_DETAILS}/phone`;
export const ZALO_DETAILS_EMAIL = `${ZALO_DETAILS}/email`;
export const ZALO_DETAILS_FOLLOW_REQUEST = `${ZALO_DETAILS}/follow-request`;
export const ZALO_DETAILS_CONFIRM_EMAIL = `${ZALO_DETAILS}/confirm-email`;

export const OTP_URI = "/otp";
export const OTP_REQUIRE_PHONE_NUMBER_PAGE_URI =
  OTP_URI + "/require-phone-number";
export const OTP_SELECT_PROVIDER_PAGE_URI = OTP_URI + "/select-provider";
export const OTP_VALIDATE_WITH_ZALO_PAGE_URI = OTP_URI + "/validate-with-zalo";
export const OTP_VALIDATE_WITH_VOICE_PAGE_URI =
  OTP_URI + "/validate-with-voice";
export const OTP_REDIRECT = OTP_URI + "/redirect";

export const INITIATE_FLOW_PAGE_URI = "/initiate-flow";

export const LANDING_WALLETS_PAGE = "/vi-dien-tu";

export const SHARE_PAGE_URI = "/share";

export const LOTTERY_PAGE_URI = "/lottery";

// non-coupled-forms
export const ACTIVITY_EDIT_LOAN_AMOUNT_URI = "/forms/activity-edit/loan-amount";
export const ACTIVITY_EDIT_INTEREST_RATE = "/forms/activity-edit/interest-rate";
export const ACTIVITY_EDIT_LOAN_REPAYMENT_INFO =
  "/forms/activity-edit/loan-repayment";
export const PAGE_URI_FEEDBACK_FORM = "/forms/feedback";
export const PAGE_URI_UPLOAD_BANK_STATEMENT_FORM =
  "/forms/upload/bank-statement";
export const PAGE_URI_UPLOAD_PAYMENT_PROOF_CREATE =
  "/forms/upload/payment-proof/create";
export const PAGE_URI_UPLOAD_PAYMENT_PROOF_EDIT =
  "/forms/upload/payment-proof/edit";

// flows
export const FLOW_URI = "/flows";
export const IDENTIFY_LEAD_FLOW_STEP_URI = `${FLOW_URI}/identify-lead` as const;
export const FETCH_PARTNER_OFFER_FLOW_STEP_URI =
  `${FLOW_URI}/fetch-partner-offer` as const;

// new universal flow routes
export const UNIFIED_FLOW_START_URI = `${FLOW_URI}/unified-flow-start` as const;
export const VIETTEL_FLOW_START_URI = `${FLOW_URI}/viettel-flow-start` as const;
export const CREDIT_CARD_FLOW_START_URI =
  `${FLOW_URI}/credit-card-flow-start` as const;
export const PERSONAL_LOAN_FLOW_START_URI =
  `${FLOW_URI}/personal-loan-flow-start` as const;
export const NAME_AND_DOB_STEP_URI = `${FLOW_URI}/name-and-dob` as const;
export const ADDRESS_STEP_URI = `${FLOW_URI}/address` as const;
export const ADDRESS_CONTINUED_STEP_URI =
  `${FLOW_URI}/address-continued` as const;
export const EMPLOYMENT_STEP_URI = `${FLOW_URI}/employment` as const;
export const SOCIAL_INSURANCE_STEP_URI =
  `${FLOW_URI}/social-insurance` as const;
export const DEBT_STEP_URI = `${FLOW_URI}/debt` as const;
export const CREDIT_CARD_STEP_URI = `${FLOW_URI}/credit-card` as const;
export const COLLATERAL_STEP_URI = `${FLOW_URI}/collateral` as const;
export const PERSONAL_ID_STEP_URI = `${FLOW_URI}/id` as const;
export const ADDITIONAL_DOCUMENTS_STEP_URI =
  `${FLOW_URI}/additional-documents` as const;
export const PRELIMINARY_CHECK_STEP_URI =
  `${FLOW_URI}/preliminary-check` as const;
export const CREDIT_CARD_OFFERS_PAGE_URI = `${OFFERS_PAGE_URI}/credit-cards`;
export const PERSONAL_LOANS_OFFERS_PAGE_URI = `${OFFERS_PAGE_URI}/personal-loans`;
export const APPLICATION_COMPLETE_STEP_URI =
  `${FLOW_URI}/application-complete` as const;
export const API_REDIRECT_CHECK_STEP_URI =
  `${FLOW_URI}/api-redirect-check` as const;
export const CREATE_LOAN_APPLICATION_STEP_URI =
  `${FLOW_URI}/create-loan-application` as const;
export const SEND_EMAIL_CONFIRMATION_LINK_STEP_URI =
  `${FLOW_URI}/send-email-confirmation-link` as const;
export const BANK_ACCOUNT_STEP_URI = `${FLOW_URI}/bank-account` as const;
export const LOAN_INFO_STEP_URI = `${FLOW_URI}/loan-info` as const;

//insurance
export const PAGE_URI_BUY_INSURANCE = "/buy-insurance";
export const PAGE_URI_BUY_INSURANCE_MOTORBIKE =
  `${PAGE_URI_BUY_INSURANCE}/motorbike` as const;
export const PAGE_URI_INSURANCE_MOTORBIKE_OFFERS =
  PAGE_URI_BUY_INSURANCE_MOTORBIKE + "/offers";
export const PAGE_URI_INSURANCE_MOTORBIKE_ADD_DETAILS =
  PAGE_URI_BUY_INSURANCE_MOTORBIKE + "/add-details";
export const PAGE_URI_INSURANCE_MOTORBIKE_CONFIRM =
  PAGE_URI_BUY_INSURANCE_MOTORBIKE + "/confirm";
export const PAGE_URI_INSURANCE_SELECT_PAYMENT_METHOD =
  PAGE_URI_BUY_INSURANCE_MOTORBIKE + "/select-payment-method";
export const PAGE_URI_INSURANCE_PAYMENT =
  `${PAGE_URI_BUY_INSURANCE_MOTORBIKE}/payment` as const;
export const PAGE_URI_INSURANCE_PAYMENT_CALLBACK =
  PAGE_URI_INSURANCE_PAYMENT + "/callback";
export const POLICY_STATUS_PAGE_URI =
  `${PAGE_URI_BUY_INSURANCE_MOTORBIKE}/status` as const;
export const PAGE_URI_BUY_INSURANCE_AUTO_REDIRECT =
  PAGE_URI_BUY_INSURANCE + "/redirect";
export const PAGE_URI_DIGIDO = "/digido";

//e-commerce
export const PAGE_URI_E_COMMERCE = "/e-commerce";
