/* eslint-disable no-console */
import {
  ENGLISH_LANG,
  FILIPINO_LANG,
  HINDI_LANG,
  INDONESIAN_LANG,
  SPANISH_LANG,
  VIETNAMESE_LANG,
} from "@domain/localisation/localisation-config/humanReadableLangs";
import type {
  HumanReadableLanguage,
  Languages,
} from "../localisation-config/types";

export const langToHumanReadableMap: Record<Languages, HumanReadableLanguage> =
  {
    en: ENGLISH_LANG,
    fil: FILIPINO_LANG,
    vi: VIETNAMESE_LANG,
    hi: HINDI_LANG,
    id: INDONESIAN_LANG,
    es: SPANISH_LANG,
    si: ENGLISH_LANG,
  };

type Args = {
  lang: Languages;
  onError?: (error: string | Error) => void;
};

export const getHumanReadableLangFromLang = ({
  lang,
  onError = console.error,
}: Args): HumanReadableLanguage => {
  const language = langToHumanReadableMap[lang];

  if (!lang) {
    onError(`No human readable language for ${language}`);
  }

  return language || ENGLISH_LANG;
};
