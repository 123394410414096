import {
  FINMART_ID_BRAND_ID,
  LOANONLINE_BRAND_ID,
  JEFF_VN_BRAND_ID,
  FINMART_VN_BRAND_ID,
  FINMERCADO_MX_BRAND_ID,
  FINMERKADO_PH_BRAND_ID,
} from "@domain/branding/constants";
import { finmerkadoPhConfig, loanonlineConfig } from "./countries/ph";
import { finmartIDConfig } from "./countries/id";
import { finmartVNConfig, jeffVNConfig } from "./countries/vn";
import { finmercadoMxConfig } from "./countries/mx";
import type { ProductConfig } from "./types";
import type { BrandId } from "@domain/branding/common";

export const CONFIG_MAP: Record<BrandId, ProductConfig> = {
  [FINMART_ID_BRAND_ID]: finmartIDConfig,
  [LOANONLINE_BRAND_ID]: loanonlineConfig,
  [JEFF_VN_BRAND_ID]: jeffVNConfig,
  [FINMART_VN_BRAND_ID]: finmartVNConfig,
  [FINMERCADO_MX_BRAND_ID]: finmercadoMxConfig,
  [FINMERKADO_PH_BRAND_ID]: finmerkadoPhConfig,
};
