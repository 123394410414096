import { asset } from "@assets/index";
import { getBrandingFromDomain } from "../common/getBrandingFromDomain";
import { getFaviconFromBrandId } from "./getFaviconFromBrandId";
import type { BrandDomains, BrandId } from "../common/types";

export const getFaviconFromDomain = ({
  domain,
  onError,
}: {
  domain: BrandDomains;
  onError: (err: Error | string) => void | Promise<void>;
}) => {
  if (!domain) {
    void onError("getFaviconFromDomain: no domain provided");
  }
  const brandId = getBrandingFromDomain(domain, onError).brandId;
  const brandIdOverride = process.env.NEXT_PUBLIC_LOCAL_DEV_BRAND_ID as BrandId;
  return (
    getFaviconFromBrandId(brandIdOverride || brandId, onError) ||
    asset("icons/favicons/jeffLogo.png")
  );
};
