import { useEffect, useRef, useState } from "react";
import { useDetectAdBlock } from "adblock-detect-react";
import { asReportable, isErrorTypeOf, onError } from "@integration/bugsnag/csr";
import { getEnhancedBrowserGlobals } from "./utils/getEnhancedBrowserGlobals";
import type { BrowserData } from "./utils/fetchBrowserData";

// NB: loads in 2..3 seconds, be careful not to block the UI
export const useBrowserData = () => {
  const [browserData, setBrowserData] = useState<BrowserData>();

  const adBlockDetected = useRef(false);
  adBlockDetected.current = useDetectAdBlock();

  useEffect(() => {
    const timerId = setTimeout(async () => {
      try {
        const { fetchBrowserData } = await import("./utils/fetchBrowserData");
        const collect = async () => {
          const { enhancedConnection, enhancedNavigator, enhancedWindow } =
            getEnhancedBrowserGlobals(window);
          return fetchBrowserData({
            connection: enhancedConnection,
            hasAdsBlocked: adBlockDetected.current,
            navigator: enhancedNavigator,
            window: enhancedWindow,
          });
        };
        setBrowserData(await collect());
      } catch (e) {
        const err = asReportable(e);
        if (isErrorTypeOf({ e: err, errorType: "chunk-load-error" })) {
          return;
        }
        onError(err);
      }
    }, 2 * 1000);
    return () => clearTimeout(timerId);
  }, []);

  return browserData;
};
