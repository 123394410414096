import {
  FINMART_ID_BRAND_ID,
  FINMART_ID_BRAND_NAME,
  FINMART_ID_COOKIE_DOMAIN,
  FINMART_ID_DOMAIN,
  FINMART_ID_HOMEPAGE,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const finmartIdBranding: Branding = {
  brandId: FINMART_ID_BRAND_ID,
  brandName: FINMART_ID_BRAND_NAME,
  cookieDomain: FINMART_ID_COOKIE_DOMAIN,
  brandDomain: FINMART_ID_DOMAIN,
  homepage: FINMART_ID_HOMEPAGE,
  gtmId: gtmTags.id.finmart,
  tncLink: "https://www.finmart.id/syarat-dan-ketentuan",
  privacyPolicyLink: "https://www.finmart.id/privacy",
};
