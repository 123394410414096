export const PARAM_NAME_UTM_SOURCE = "utm_source";
export const PARAM_NAME_UTM_MEDIUM = "utm_medium";
export const PARAM_NAME_UTM_CAMPAIGN = "utm_campaign";
export const PARAM_NAME_UTM_CONTENT = "utm_content";
export const PARAM_NAME_UTM_ID = "utm_id";
export const PARAM_NAME_REFERRER = "referrer";
export const PARAM_NAME_PUBLISHER_NAME = "publisher_name";
export const PARAM_NAME_PUBLISHER_CLICK_ID = "publisher_click_id";
export const PARAM_NAME_PUBLISHER_SUB_PUB_ID = "publisher_sub_pub_id";
export const PARAM_NAME_GOOGLE_CLICK_ID = "gclid";
export const PARAM_NAME_UT_ID = "utid";
export const PARAM_NAME_MEDIUM = "medium";
export const PARAM_NAME_CAMPAIGN = "campaign";
export const PARAM_NAME_ADID = "adid";
export const PARAM_NAME_QUERY_PARAMS_JSON = "query_params_json";
export const PARAM_NAME_FORCE_ADSENSE = "force_adsense";
export const PARAM_NAME_FORCE_GPT = "force_gpt";
