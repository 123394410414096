import { deleteCookie } from "cookies-next";
// eslint-disable-next-line import/no-restricted-paths
import { branding } from "@domain/branding/common/branding";
import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { createCookieOptions } from "./createCookieOptions";
import type { OptionsType } from "cookies-next/lib/types";

export const deleteCookieNext = (key: string, options?: OptionsType) => {
  const cookieOptions = createCookieOptions(options);

  deleteCookie(key, cookieOptions);

  // try to delete cookie with sub-domain
  if (typeof window !== "undefined") {
    const optionsWithSubDomain = { ...cookieOptions };
    optionsWithSubDomain.domain = branding.brandDomain;
    deleteCookie(key, optionsWithSubDomain);
  }

  // delete cookie with default values and first level domain in options
  const optionsWithTopLevelDomain = { ...cookieOptions };
  optionsWithTopLevelDomain.domain = getDomainFromOrigin({
    origin: optionsWithTopLevelDomain?.domain,
    prefixDot: true,
  });
  deleteCookie(key, optionsWithTopLevelDomain);
};
