import { useEffect, useRef, type ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import Snackbar, { type SnackbarOrigin } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import type React from "react";

type Severity = "error" | "warning" | "info" | "success";

interface NotificationState {
  open: boolean;
  message: string | ReactNode;
  title?: string | ReactNode;
  severity?: Severity;
}

export interface NotificationArgs {
  message: string | ReactNode;
  severity?: Severity;
  title?: string | ReactNode;
}

interface NotificationConfig {
  autoHideDuration?: number;
  anchorOrigin?: SnackbarOrigin;
}

type NotificationContextType = {
  notify: (args: NotificationArgs) => void;
  setNotificationConfig: (options: NotificationConfig) => void;
};

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined,
);

export const useNotify = (
  config?: NotificationConfig,
): NotificationContextType => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error("useNotify must be used within a NotificationProvider");
  }

  const notificationConfigSetter = useRef(context.setNotificationConfig);
  const immutableConfig = useRef(config);

  useEffect(() => {
    if (immutableConfig.current) {
      notificationConfigSetter.current(immutableConfig.current);
    }

    return () => {
      notificationConfigSetter.current = () => null;
      immutableConfig.current = undefined;
    };
  }, []);

  return context;
};

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: "",
    severity: "info",
  });

  const [defaultConfig, setDefaultConfig] = useState<NotificationConfig>({
    autoHideDuration: 5000,
    anchorOrigin: { vertical: "top", horizontal: "center" },
  });

  const notify = (notificationArgs: NotificationArgs) => {
    setNotification({ ...notificationArgs, open: true });
  };

  const hideAlert = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider
      value={{ notify, setNotificationConfig: setDefaultConfig }}
    >
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={defaultConfig.autoHideDuration}
        onClose={hideAlert}
        anchorOrigin={defaultConfig.anchorOrigin}
      >
        <Alert
          onClose={hideAlert}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.title && <AlertTitle>{notification.title}</AlertTitle>}

          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
