import { getProfileConfig } from "@domain/profile";
import type { ProductConfig } from "@domain/product-config/types";

export const finmercadoMxConfig: ProductConfig = {
  countryCode: "MX",
  socialProviderConfig: {
    enabledSocialProviders: ["google"],
    socialProvidersEnvKeys: {
      google: process.env.NEXT_PUBLIC_FINMERCADO_MX_GG_APP_ID,
    },
  },
  levelUp: {
    enabled: true,
  },
  profileConfig: {
    ...getProfileConfig({ brandId: "finmercado-mx" }),
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
    isAlternativeProgressBarVariantsEnabled: true,
  },
  adSense: {
    enabled: true,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: false,
  },
  otp: {
    enabled: true,
  },
  captcha: {
    enabled: false,
  },
  feedback: {
    enabled: true,
  },
  smallOfferCards: { enabled: true },
  registrationTimer: { enabled: true },
  olFeedbackPopUp: {
    enabled: true,
  },
};
