import {
  FINMERKADO_PH_BRAND_ID,
  FINMERKARDO_PH_BRAND_NAME,
  FINMERKADO_PH_COOKIE_DOMAIN,
  FINMERKADO_PH_DOMAIN,
  FINMERKADO_PH_HOMEPAGE,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const finmerkadoPhBranding: Branding = {
  brandId: FINMERKADO_PH_BRAND_ID,
  brandName: FINMERKARDO_PH_BRAND_NAME,
  cookieDomain: FINMERKADO_PH_COOKIE_DOMAIN,
  brandDomain: FINMERKADO_PH_DOMAIN,
  homepage: FINMERKADO_PH_HOMEPAGE,
  gtmId: gtmTags.ph.finmerkado,
  tncLink: "https://www.finmerkado.ph/terms-and-condition",
  privacyPolicyLink: "https://www.finmerkado.ph/privacy-policy",
};
