export const getDomainFromOrigin = ({
  origin,
  level = 2,
  prefixDot = false,
}: {
  origin: string | undefined | null;
  level?: number;
  prefixDot?: boolean;
}) => {
  if (
    !origin ||
    origin.includes("localhost") ||
    origin.includes("ngrok-free.app")
  ) {
    return undefined;
  }
  const prefix = prefixDot ? "." : "";
  const url = origin.replace(/(https?:\/\/)?(www.)?/i, "");
  const parts = url.split(".");
  return prefix + parts.slice(parts.length - level).join(".");
};
