import { useEffect, useState, useRef } from "react";
import { getTranslations } from "@domain/localisation/common/getTranslationsAsync";
import { onError } from "@integration/bugsnag/csr";
import type {
  Languages,
  Translation,
} from "@domain/localisation/localisation-config/types";

interface Args {
  lang: Languages;
  fallbackLang?: Languages;
  initialFallbackTranslations?: Translation;
  initialTranslations?: Translation;
}

export const useTranslations = ({
  fallbackLang = "en",
  lang,
  initialFallbackTranslations,
  initialTranslations,
}: Args) => {
  const [translationDict, setTranslationDict] = useState<
    Translation | undefined
  >(initialTranslations);
  const [fallbackTranslationDict, setFallbackTranslationDict] = useState<
    Translation | undefined
  >(initialFallbackTranslations);

  const currentLang = useRef(lang);
  useEffect(() => {
    if (!translationDict || currentLang.current !== lang) {
      const fetchTranslations = async () => {
        const translations = await getTranslations(lang);
        const fallbackTranslations = await getTranslations(fallbackLang);
        setTranslationDict(translations);
        setFallbackTranslationDict(fallbackTranslations);
        currentLang.current = lang;
      };
      fetchTranslations().catch(onError);
    }
  }, [fallbackLang, lang, translationDict]);

  return [translationDict, fallbackTranslationDict] as [
    Translation | undefined,
    Translation | undefined,
  ];
};
