import {
  type AdPlacement,
  type AdUnit,
  type AdUnitName,
  type PlacementConfig,
  type ConfigId,
  type SiteId,
  type ToggleId,
} from "./types";

const createToggleIdFromConfigId = (
  configId: ConfigId,
): ToggleId<typeof configId> => {
  return `adsense-${configId}`;
};

export const createAdUnitNameFromConfigId = ({
  adPlacement,
  configId,
}: {
  configId: ConfigId;
  adPlacement: AdPlacement;
}): AdUnitName<typeof configId> => {
  return `${configId}-${adPlacement}`;
};

const createAdUnitConfig = ({
  adPlacement,
  configId,
  adSlotId,
}: {
  configId: ConfigId;
  adSlotId: string;
  adPlacement: AdPlacement;
}): AdUnit<typeof configId> => {
  return {
    id: adSlotId,
    name: createAdUnitNameFromConfigId({ configId, adPlacement }),
    placement: adPlacement,
  };
};

export const createExperimentAdPlacementConfig = ({
  configId,
  adUnits,
  siteId,
}: {
  siteId: SiteId;
  configId: ConfigId;
  adUnits: { id: string; placement: AdPlacement }[];
}): PlacementConfig => {
  return {
    adUnits: adUnits.map((unit) =>
      createAdUnitConfig({
        adPlacement: unit.placement,
        configId,
        adSlotId: unit.id,
      }),
    ),
    id: configId,
    siteId,
    toggleId: createToggleIdFromConfigId(configId),
  };
};
