import { z } from "zod";
import { safeLocalStorage } from "@utils/safeLocalStorage";
import { asReportable, onError } from "@integration/bugsnag/csr";
import { CustomError } from "@integration/bugsnag/error-types";

const AppStateSchema = z.object({
  feedback: z.object({
    leftAt: z.number().nullable(),
    olFeedbackPopUp: z.object({
      timesShown: z.number(),
    }),
  }),
  offerListing: z.object({
    introShown: z.boolean(),
    lastOfferListingId: z.number().nullable(),
    timesRequested: z.number(),
  }),
  registration: z
    .object({
      timeLeadCreatedTimestamp: z.number().nullable(),
    })
    .optional(),
});

export type AppState = z.infer<typeof AppStateSchema>;

const DEFAULT_APP_STATE: AppState = {
  feedback: {
    leftAt: null,
    olFeedbackPopUp: {
      timesShown: 0,
    },
  },
  offerListing: {
    introShown: false,
    lastOfferListingId: null,
    timesRequested: 0,
  },
  registration: {
    timeLeadCreatedTimestamp: null,
  },
};

const syncState = (state: AppState): AppState => {
  safeLocalStorage.setItem("app-state", JSON.stringify(state));
  return state;
};

export const getAppState = (): AppState => {
  try {
    const json = safeLocalStorage.getItem("app-state");
    if (!json) {
      return { ...DEFAULT_APP_STATE };
    }
    const state = AppStateSchema.safeParse(JSON.parse(json));
    if (!state.success) {
      return { ...DEFAULT_APP_STATE };
    }
    return state.data;
  } catch (e) {
    const err = asReportable(e);
    onError(
      new CustomError({
        name: "AppStateError",
        message: `Failed to deserialize app state: ${err.message}`,
      }),
    );
    return { ...DEFAULT_APP_STATE };
  }
};

export const handleOfferListingRequested = (offerListingId: number) => {
  const state = getAppState();
  if (state.offerListing.lastOfferListingId === offerListingId) {
    return state;
  }
  return syncState({
    ...state,
    offerListing: {
      ...state.offerListing,
      lastOfferListingId: offerListingId,
      timesRequested: state.offerListing.timesRequested + 1,
    },
  });
};

export const setIntroShown = () => {
  const state = getAppState();
  return syncState({
    ...state,
    offerListing: {
      ...state.offerListing,
      introShown: true,
    },
  });
};

export const isIntroShown = () => {
  const state = getAppState();
  return state.offerListing.introShown;
};

export const handleFeedbackLeft = () => {
  const state = getAppState();
  return syncState({
    ...state,
    feedback: {
      ...state.feedback,
      leftAt: Date.now(),
    },
  });
};

type SetStateUpdate =
  | Partial<AppState>
  | ((currentState: AppState) => AppState);

export const appState = (): {
  getState: () => AppState;
  setState: (args: SetStateUpdate) => AppState;
} => {
  let state = getAppState();

  return {
    getState: () => state,
    setState: (args) => {
      if (typeof args === "function") {
        const newState: AppState = args(state);
        syncState(newState);
        state = newState;
        return newState;
      } else {
        const newState: AppState = { ...state, ...args };
        syncState(newState);
        state = newState;
        return newState;
      }
    },
  };
};
