import { BrowserPlugin } from "@snowplow/browser-tracker-core";

export const MarketingContextPlugin = ({
  getCookieByName,
}: {
  getCookieByName: (name: string) => string | null;
}): BrowserPlugin => {
  return {
    contexts: () => {
      return [
        {
          schema: "iglu:app.j3ff.web/marketing_context/jsonschema/1-0-0",
          data: {
            utm_source: getCookieByName("utm_source"),
            utm_medium: getCookieByName("utm_medium"),
            utm_campaign: getCookieByName("utm_campaign"),
            utm_content: getCookieByName("utm_content"),
          },
        },
      ];
    },
  };
};
