import { setCookie } from "cookies-next";
import { isRunningOnVercel as isRunningOnVercelSSR } from "@integration/vercel/ssr";
import { isRunningOnVercel as isRunningOnVercelCSR } from "@integration/vercel/csr";
import { createCookieOptions } from "./createCookieOptions";
import type { OptionsType } from "cookies-next/lib/types";

const rootHost = (input: string) => {
  const parts = input.split(".");
  return parts.slice(parts.length - 2).join(".");
};

export const setCookieNext = (
  key: string,
  value: string,
  options?: OptionsType,
) => {
  const cookieOptions = createCookieOptions(options);
  const isSSR = typeof window === "undefined";
  const isOnVercel = isSSR ? isRunningOnVercelSSR() : isRunningOnVercelCSR();

  const adjustedDomain = isOnVercel
    ? isSSR
      ? "." + cookieOptions.domain
      : "." + rootHost(window.location.host)
    : cookieOptions.domain;

  const updatedOptions = { ...cookieOptions, domain: adjustedDomain };
  setCookie(key, value, updatedOptions);
};
