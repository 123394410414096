/* eslint-disable no-restricted-imports */
import router, { useRouter as originalUseRouter, Router } from "next/router";
import { getRandomUUID } from "@utils/uuid";
import {
  INTERNAL_CLICK_ID_PARAM,
  INTERNAL_CLICK_ID_TRACKING_ENABLED,
} from "@integration/funnel-tracking";
import { isAllowedToShowAdsense } from "@integration/adsense/guards";
import type { NextRouter } from "next/router";
import type { UrlObject } from "url";
import type { ParsedUrlQuery } from "querystring";

type PatchedRouter = NextRouter & { __patched__?: boolean };

const report = async ({
  targetUrl,
  transitionType,
}: {
  targetUrl: UrlObject | string;
  transitionType: "push" | "replace";
}) => {
  const trackSelfDescribingEvent = (await import("@snowplow/browser-tracker"))
    .trackSelfDescribingEvent;

  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/route_transitions/jsonschema/1-0-0",
      data: { transition_type: transitionType, target_url: targetUrl },
    },
  });
};

const useRouter = (): NextRouter => {
  const r: PatchedRouter = originalUseRouter();
  if (r.__patched__ || !INTERNAL_CLICK_ID_TRACKING_ENABLED) {
    return r;
  }

  r.__patched__ = true;

  const appendQueryParameter = (
    url: UrlObject | string,
  ): UrlObject | string => {
    const clickId = getRandomUUID();
    if (typeof url === "string") {
      const urlParts = url.split("?");
      const baseURL = urlParts[0];

      const queryParams = new URLSearchParams(urlParts[1] || "");
      if (!isAllowedToShowAdsense({ path: baseURL })) {
        queryParams.delete(INTERNAL_CLICK_ID_PARAM);
        queryParams.append(INTERNAL_CLICK_ID_PARAM, clickId);
      }

      return queryParams.toString().length > 0
        ? `${baseURL}?${queryParams.toString()}`
        : baseURL;
    }

    if (url.pathname && isAllowedToShowAdsense({ path: url.pathname })) {
      return url;
    }

    let newQuery: ParsedUrlQuery = { [INTERNAL_CLICK_ID_PARAM]: clickId };
    if (typeof url.query === "object" && url.query !== null) {
      newQuery = {
        ...url.query,
        [INTERNAL_CLICK_ID_PARAM]: clickId,
      };
    }
    return {
      ...url,
      query: newQuery,
    };
  };

  const originalPush = r.push;
  r.push = async (url, as, options) => {
    const adjustedUrl = appendQueryParameter(url);
    await report({ targetUrl: adjustedUrl, transitionType: "push" });
    return originalPush(
      adjustedUrl,
      as ? appendQueryParameter(as) : undefined,
      options,
    );
  };

  const originalReplace = r.replace;
  r.replace = async (url, as, options) => {
    const adjustedUrl = appendQueryParameter(url);
    await report({ targetUrl: adjustedUrl, transitionType: "replace" });
    return originalReplace(
      adjustedUrl,
      as ? appendQueryParameter(as) : undefined,
      options,
    );
  };

  return r;
};

export { useRouter, type NextRouter, Router };
export default router;
