import Script from "next/script";

interface Props {
  onLoaded?: () => void;
}

export const GooglePublisherTagsScript = ({ onLoaded }: Props) => {
  return (
    <Script
      id="google-publisher-tags"
      onReady={onLoaded}
      async
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
    />
  );
};
