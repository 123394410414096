import { getRandomUUID } from "@utils/uuid";
import { isAllowedToShowAdsense } from "@integration/adsense/guards";
import { INTERNAL_CLICK_ID_PARAM } from "./constants";

export const appendClickId = ({
  to,
  existingClickId,
}: {
  to: string;
  existingClickId: string | undefined | null;
}): string => {
  const { searchParams, pathname } = new URL(to, "http://dummy.com");
  if (isAllowedToShowAdsense({ path: pathname })) {
    return to;
  }
  const internalClickId = existingClickId || getRandomUUID();
  searchParams.set(INTERNAL_CLICK_ID_PARAM, internalClickId);
  return `${pathname}?${searchParams.toString()}`;
};
