export const PARAM_NAME_LEAD_ID = "lead-id";
export const PARAM_NAME_LEADID = "leadId";
export const PARAM_NAME_AMOUNT = "amount";
export const PARAM_NAME_PURPOSE = "purpose";
export const PARAM_NAME_TERM = "term";
export const PARAM_NAME_PHONE = "phone";
export const PARAM_PHONE_NUMBER = "phone_number";
export const PARAM_PHONENUMBER = "phoneNumber";
export const PARAM_NAME_EMAIL = "email";
export const PARAM_NAME_PARTNER_TYPE = "partner-type";
export const PARAM_NAME_PRODUCT_TYPE = "product-type";
export const PARAM_NAME_LANGUAGE = "language";
export const PARAM_NAME_MARKETING = "marketing";
export const PARAM_NAME_LINE_OF_BUSINESS = "line-of-business";
export const PARAM_NAME_EMBEDDED = "embedded";
export const PARAM_NAME_CLICK_ID = "click_id";
export const PARAM_NAME_UTM_TERM = "utm_term";
export const PARAM_NAME_BRAND = "brand";
export const PARAM_NAME_FACEBOOK_BROWSER_ID = "_fbp";
export const PARAM_NAME_FACEBOOK_CLICK_ID = "_fbc";
export const PARAM_NAME_TIK_TOK_CLICK_ID = "ttclid";
export const PARAM_NAME_REDIRECT_REASON = "redirect-reason";
export const PARAM_NAME_OTP_ZALO_VERIFICATION_ENABLED =
  "zalo-verification-enabled";
export const PARAM_NAME_EMAIL_CONFIRMATION_TOKEN = "email-confirmation-token";
export const PARAM_NAME_RETURN_TO_URI = "return_to";
export const PARAM_NAME_OFFER_LISTING_ID = "offer-listing-id";
export const PARAM_NAME_ALGORITHM = "algorithm";
export const PARAM_NAME_TIMEOUT = "timeout";
export const PARAM_NAME_REDIRECT_ID = "redirect-id";
export const PARAM_NAME_RATING = "rating";
export const PARAM_NAME_FORM_CONTEXT_VALUE = "form-context";
export const PARAM_NAME_LOAN_AMOUNT = "loan-amount";
export const PARAM_NAME_PLATFORM = "platform";
export const PARAM_NAME_REDIRECT_URL = "redirect-url";
export const PARAM_NAME_PHONE_UNSUBSCRIBE_TOKEN = "phone_unsubscribe_token";
export const PARAM_NAME_CONFIRM_EMAIL_TOKEN = "confirm-email-token";
export const PARAM_NAME_PARTNER_URL_KEY = "partnerUrlKey";
export const PARAM_NAME_REDIRECT_ON_SIGN_IN = "redirectOnSignIn";
export const PARAM_NAME_REDIRECT_TO = "redirectTo";
export const PARAM_NAME_REDIRECT_AFTER_REG = "redirectAfterReg";
export const PARAM_NAME_LAYOUT = "layout";

/**
 * Use this to redirect to predefined page after successful lead/account signup/signin
 */
export const PARAM_NAME_RETURN_TO = "returnTo";

export const PARAM_NAME_REFERRAL_TOKEN = "referral-token";

// these params can take multiple feature names (delimited by > char)
export const PARAM_NAME_ENABLE_FEATURES = "enable-features";
export const PARAM_NAME_DISABLE_FEATURES = "disable-features";

//zalo landing params(should not be changes since url is generated from BE)
export const PARAM_NAME_ZALO_LANDING_CODE = "code";
export const PARAM_NAME_ZALO_LANDING_STATE = "state";
export const PARAM_NAME_ZALO_APPEND_TO_STATE = "appendToState";
export const PARAM_NAME_ZALO_ERROR_CODE = "error";
export const PARAM_NAME_ZALO_ERROR_REASON = "error_reason";
export const PARAM_NAME_ZALO_ERROR_DESCRIPTION = "error_description";

export const PARAM_NAME_REDIRECT_TO_LEAD_FORM_LANDING =
  "redirectToLeadFormLanding";
export const PARAM_NAME_COMPANIES = "companies";

// flow related params
export const PARAM_NAME_FLOW_ID = "flowId";
export const PARAM_NAME_SUB_ID = "sub_id";

export const PARAM_NAME_LEAD_PAGE = "lead-page";
// insurance params
export const PARAM_NAME_INTERACTION_ID = "interactionId";

export const PARAM_NAME_PREFERRED_PRODUCT = "preferred-product";
export const PARAM_NAME_CREDIT_CARD_OFFER_ID = "credit-card-offer-id";
export const PARAM_NAME_PARTNER_ID = "partner-id";
export const PARAM_NAME_OFFER_ID = "offerId";
export const PARAM_NAME_OTP_KEY = "otp-key";

//e-commerce params
export const PARAM_NAME_OFFER_URL = "offer-url";

export const PARAM_NAME_IGNORE_POLICIES = "ignorePolicies";

export const PARAM_NAME_LOAN_APPLICATION_INPUT = "loanApplicationInput";
