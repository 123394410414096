import { useEffect, useState } from "react";
import isDeepEqual from "fast-deep-equal/react";
import { asReportable, isErrorTypeOf, onError } from "@integration/bugsnag/csr";

function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(
      ...(args as Parameters<HTMLElement["addEventListener"]>),
    );
  }
}

function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args:
    | Parameters<T["removeEventListener"]>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
    | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(
      ...(args as Parameters<HTMLElement["removeEventListener"]>),
    );
  }
}

const isNavigator = typeof navigator !== "undefined";

export interface BatteryState {
  charging: boolean;
  chargingTime: number;
  dischargingTime: number;
  level: number;
}

interface BatteryManager extends Readonly<BatteryState>, EventTarget {
  onchargingchange: () => void;
  onchargingtimechange: () => void;
  ondischargingtimechange: () => void;
  onlevelchange: () => void;
}

interface NavigatorWithPossibleBattery extends Navigator {
  getBattery?: () => Promise<BatteryManager>;
}

type UseBatteryState =
  | { isSupported: false } // Battery API is not supported
  | { isSupported: true; fetched: false } // battery API supported but not fetched yet
  | (BatteryState & { isSupported: true; fetched: true }); // battery API supported and fetched

const nav: NavigatorWithPossibleBattery | undefined = isNavigator
  ? navigator
  : undefined;

const isSupported = () => {
  if (!nav) {
    return false;
  }
  if (typeof nav.getBattery !== "function") {
    return false;
  }
  return true;
};

/**
 * Taken from here https://github.com/streamich/react-use/blob/master/src/useBattery.ts
 * Adjusted so that better error handling is implemented
 */
export const useBattery = (): UseBatteryState => {
  const [state, setState] = useState<UseBatteryState>({
    isSupported: true,
    fetched: false,
  });

  useEffect(() => {
    let isMounted = true;
    let battery: BatteryManager | null = null;

    const handleChange = () => {
      if (!isMounted || !battery || !isSupported()) {
        return;
      }
      const newState: UseBatteryState = {
        isSupported: true,
        fetched: true,
        level: battery.level,
        charging: battery.charging,
        dischargingTime: battery.dischargingTime,
        chargingTime: battery.chargingTime,
      };
      if (!isDeepEqual(state, newState)) {
        setState(newState);
      }
    };

    (async () => {
      if (!isSupported() || !nav || !nav.getBattery) {
        return;
      }
      try {
        const bat = await nav.getBattery();
        if (!isMounted) {
          return;
        }
        battery = bat;
        on(battery, "chargingchange", handleChange);
        on(battery, "chargingtimechange", handleChange);
        on(battery, "dischargingtimechange", handleChange);
        on(battery, "levelchange", handleChange);
        handleChange();
      } catch (e) {
        const err = asReportable(e);
        if (isErrorTypeOf({ e: err, errorType: "illegal-invocation" })) {
          return;
        }
        onError(err);
      }
    })().catch(onError);

    return () => {
      isMounted = false;
      if (battery) {
        off(battery, "chargingchange", handleChange);
        off(battery, "chargingtimechange", handleChange);
        off(battery, "dischargingtimechange", handleChange);
        off(battery, "levelchange", handleChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};
