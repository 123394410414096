import { type BrandId } from "@domain/branding/common";
import { type ProfileConfig } from "../types";
import { config as jeffVN } from "./vn-jeff";
import { config as finmartVN } from "./vn-finmart";
import { config as loanonlinePH } from "./ph-loanonline";
import { config as finmerkadoPH } from "./ph-finmerkado";
import { config as finmercadoMX } from "./mx-finmercado";

export const getProfileConfig = ({
  brandId,
}: {
  brandId: BrandId;
}): ProfileConfig => {
  if (brandId === "jeff-vn") {
    return jeffVN;
  }
  if (brandId === "finmart-vn") {
    return finmartVN;
  }
  if (brandId === "loanonline-ph") {
    return loanonlinePH;
  }
  if (brandId === "finmerkado-ph") {
    return finmerkadoPH;
  }
  if (brandId === "finmercado-mx") {
    return finmercadoMX;
  }
  return {
    enabled: false,
    spaces: [],
    dashboard: { otherProducts: { enabled: false } },
    followUs: { enabled: [] },
    share: {
      enabled: false,
    },
  };
};
