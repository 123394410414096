import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { getDefaultCookieBasicOptions } from "./getDefaultCookieBasicOptions";
import type { OptionsType } from "cookies-next/lib/types";

export const getDefaultCookieOptions = (options?: OptionsType) => {
  const domain =
    typeof window !== "undefined"
      ? getDomainFromOrigin({ origin: window.location.host }) ||
        getDomainFromOrigin({ origin: window.location.origin })
      : getDomainFromOrigin({ origin: options?.req?.headers.host }) ||
        getDomainFromOrigin({ origin: options?.req?.headers.origin });

  const defaultCookieOptions: OptionsType = {
    domain,
    ...getDefaultCookieBasicOptions(),
  };

  return defaultCookieOptions;
};
