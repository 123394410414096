import { JEFF_VN_BRAND_NAME } from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const jeffBranding: Branding = {
  brandId: "jeff-vn",
  brandName: JEFF_VN_BRAND_NAME,
  cookieDomain: "jeff.vn",
  brandDomain: "app.jeff.vn",
  homepage: "https://www.jeff.vn",
  gtmId: gtmTags.vn.jeff,
  tncLink: "https://www.jeff.vn/dieu-khoan-va-dieu-kien",
  privacyPolicyLink: "https://www.jeff.vn/chinh-sach-bao-mat",
};
