import { cookieAge } from "./cookieAge";
import type { OptionsType } from "cookies-next/lib/types";

export const getDefaultCookieBasicOptions = () => {
  const defaultCookieBasicOptions: OptionsType = {
    maxAge: cookieAge({ days: 90 }),
    sameSite: "lax",
    path: "/",
  };

  return defaultCookieBasicOptions;
};
