export type ErrorType =
  | "localstorage-unavailable"
  | "illegal-invocation"
  | "clipboard-permission-denied"
  | "not-allowed-error"
  | "route-did-not-complete-loading"
  | "failed-to-load-static-props"
  | "failed-to-load-script"
  | "failed-to-fetch"
  | "chunk-load-error"
  | "resize-observer-loop-error"
  | "random-script-variable-not-found"
  | "insecure-operation";

export const isErrorTypeOf = ({
  e,
  errorType,
}: {
  e: unknown;
  errorType: ErrorType;
}): boolean => {
  if (!(e instanceof Error)) {
    return false;
  }
  const err = e as Error;
  const msg = err.message || "";
  if (errorType === "localstorage-unavailable") {
    const messages = [
      "Failed to read the 'localStorage'",
      "Cannot read properties of null (reading 'setItem')",
      "Cannot read property 'setItem' of null",
    ].map((it) => it.toLowerCase());
    return messages.findIndex((it) => msg.toLowerCase().includes(it)) > -1;
  }
  if (errorType === "illegal-invocation") {
    return e instanceof TypeError && msg.includes("Illegal invocation");
  }
  if (errorType === "clipboard-permission-denied") {
    const messages = [
      "read property 'readText' of undefined",
      "Read permission denied",
      "Document is not focused",
      "Write permission denied",
      "user denied permission",
      "clipboard.readText",
      "read operation is not allowed",
    ].map((it) => it.toLowerCase());
    return messages.findIndex((it) => msg.toLowerCase().includes(it)) > -1;
  }
  if (errorType === "route-did-not-complete-loading") {
    return msg.includes("Route did not complete loading");
  }
  if (errorType === "failed-to-load-static-props") {
    return msg.includes("Failed to load static props");
  }
  if (errorType === "failed-to-load-script") {
    return msg.includes("Failed to load script");
  }
  if (errorType === "not-allowed-error") {
    return msg.includes("possibly because the user denied permission");
  }
  if (errorType === "failed-to-fetch") {
    const messages = [
      "Failed to fetch",
      "NetworkError when attempting to fetch resource",
      "thể tìm thấy máy chủ",
      "đã hủy",
      "Load failed",
      "Unexpected token '<'",
      "Unexpected token )",
      "Unexpected token u in JSON at position",
      "Unexpected EOF",
      "Unexpected end of script",
    ];
    return messages.findIndex((it) => msg.includes(it)) > -1;
  }
  if (errorType === "chunk-load-error") {
    return err.name === "ChunkLoadError";
  }
  if (errorType === "resize-observer-loop-error") {
    return msg.includes(
      "ResizeObserver loop completed with undelivered notifications",
    );
  }
  if (errorType === "random-script-variable-not-found") {
    const messages = [
      "__AutoFillPopupClose__",
      ".bannerNight",
      ".changeNetWork",
      "isReCreate is not defined",
      "variable: zaloJSV2",
      "variable: _AutofillCallbackHandler",
    ];
    return messages.findIndex((it) => msg.includes(it)) > -1;
  }
  if (errorType === "insecure-operation") {
    return msg.includes("The operation is insecure");
  }
  return false;
};

export const isErrorOfAnyType = ({
  e,
  errorTypes,
}: {
  e: unknown;
  errorTypes: ErrorType[];
}): boolean => {
  return errorTypes.some((errorType) => isErrorTypeOf({ e, errorType }));
};
