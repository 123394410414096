import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { onError } from "@integration/bugsnag/csr";
import { brandDomains } from "../common/types";
import {
  JEFF_DEV_DOMAIN,
  LOCALHOST_DOMAIN,
  NGROK_FUNNEL_DOMAIN,
  VERCEL_DOMAIN,
} from "../constants";
import type { BrandDomains } from "../common/types";

export const getCurrentDomain = () => {
  const currentDomain: BrandDomains = LOCALHOST_DOMAIN;
  if (typeof window !== "undefined") {
    const windowDomain = window.location.hostname as BrandDomains;

    const rootDomain = getDomainFromOrigin({ origin: windowDomain });

    if (rootDomain === VERCEL_DOMAIN) {
      return JEFF_DEV_DOMAIN;
    }

    if (
      ![...brandDomains, LOCALHOST_DOMAIN].includes(windowDomain) &&
      !LOCALHOST_DOMAIN.includes(windowDomain) &&
      !windowDomain.includes(NGROK_FUNNEL_DOMAIN)
    ) {
      onError("getCurrentDomain: Unrecognized brand domain: " + windowDomain);
    }

    if (
      [LOCALHOST_DOMAIN].includes(windowDomain) ||
      windowDomain.includes(NGROK_FUNNEL_DOMAIN)
    ) {
      return LOCALHOST_DOMAIN;
    } else {
      return windowDomain;
    }
  }

  return currentDomain;
};
