import {
  LOANONLINE_BRAND_NAME,
  LOANONLINE_COOKIE_DOMAIN,
  LOANONLINE_HOMEPAGE,
  LOANONLINE_BRAND_ID,
  LOANONLINE_DEV_DOMAIN,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const loanonlineDevBranding: Branding = {
  brandId: LOANONLINE_BRAND_ID,
  brandName: LOANONLINE_BRAND_NAME,
  cookieDomain: LOANONLINE_COOKIE_DOMAIN,
  brandDomain: LOANONLINE_DEV_DOMAIN,
  homepage: LOANONLINE_HOMEPAGE,
  gtmId: gtmTags.ph.loanonline,
  tncLink: "https://www.loanonline.ph/terms-conditions",
  privacyPolicyLink: "https://www.loanonline.ph/privacy",
};
