const PARAM_DELIMITER = "/:";
const ROUTE_DELIMITER = "/";

export const PARTNERS_PARAM = "partners";
export const PARTNERS_SLUG = `${ROUTE_DELIMITER}${PARTNERS_PARAM}` as const;
export const PARTNER_ID_PARAM = "partnerId";
export const PARTNER_ID_SLUG = `${PARAM_DELIMITER}${PARTNER_ID_PARAM}` as const;
export const PARTNER_REDIRECT_PARAM = "redirect";
export const PARTNER_REDIRECT_SLUG =
  `${ROUTE_DELIMITER}${PARTNER_REDIRECT_PARAM}` as const;
export const PARTNER_REVIEWS_PARAM = "reviews";
export const PARTNER_REVIEWS_SLUG =
  `${ROUTE_DELIMITER}${PARTNER_REVIEWS_PARAM}` as const;
export const LOYALTY_CODE_PARAM = "code";
