import { clientSideEnvVariable } from "@client-env";

type Config = {
  env: "dev" | "prod";
  app: {
    version: string;
  };
  tagManager: {
    enabled: boolean;
  };
  bugsnag: {
    enabled: boolean;
    client: {
      apiKey: string;
    };
    server: {
      apiKey: string;
    };
  };
};

const version = clientSideEnvVariable("COMMIT_REF").substring(0, 7);

const baseConfig: Config = {
  env: "prod",
  app: {
    version,
  },
  tagManager: {
    enabled: true,
  },
  bugsnag: {
    enabled: true,
    client: {
      apiKey: "f1e5e9602a96450eaab3e725485b522c",
    },
    server: {
      apiKey: "c8194ab2579130f2be7fbbc430fc079f",
    },
  },
};

const devConfig: Partial<Config> = {
  env: "dev",
  bugsnag: {
    ...baseConfig.bugsnag,
    enabled: false,
  },
  tagManager: {
    enabled: false,
  },
};

let finalConfig: Config = baseConfig;

if (clientSideEnvVariable("ENVIRONMENT") === "development") {
  finalConfig = { ...baseConfig, ...devConfig };
}

export const config = finalConfig;
