import {
  getCookie,
  setCookieNext,
  deleteCookieNext,
} from "@integration/cookies";

export const safeLocalStorage = {
  getItem: (key: string): string | null => {
    const errors = [];

    let value: string | null = null;

    try {
      value = localStorage.getItem(key);

      if (value !== null) {
        return value;
      }
    } catch (err) {
      errors.push(err);
    }

    try {
      const cookieValue = getCookie(key);

      if (typeof cookieValue === "string") {
        return cookieValue;
      } else if (cookieValue === null || cookieValue === undefined) {
        return null;
      } else {
        return cookieValue.toString();
      }
    } catch (err) {
      errors.push(err);

      if (errors.length > 1) {
        throw new Error(
          "Both localStorage and getCookieNext services failed with Errors: " +
            errors,
        );
      }
    }

    return value;
  },

  setItem: (key: string, value: string): boolean => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch {
      try {
        setCookieNext(key, value);
        return true;
      } catch {
        return false;
      }
    }
  },

  removeItem: (key: string): boolean => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch {
      try {
        deleteCookieNext(key);
        return true;
      } catch {
        return false;
      }
    }
  },
};
