import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { domainToBrandingMap } from "../utils/domainToBrandingMap";
import { jeffBranding } from "../branding-configs/jeff-vn";
import {
  LOCALHOST_COOKIE_DOMAIN,
  LOCALHOST_DOMAIN,
  VERCEL_DOMAIN,
} from "../constants";
import type { BrandDomains, ErrorFunc } from "./types";

const isLocalhostOrVercel = (domain: string) => {
  return (
    [LOCALHOST_COOKIE_DOMAIN, LOCALHOST_DOMAIN].includes(domain) ||
    domain.includes(VERCEL_DOMAIN)
  );
};

export const isNgrokFunnel = (domain: string) => {
  return domain.includes(".ngrok-free.app") || domain === "ngrok-free.app";
};

export const getBrandingFromDomain = (
  domain: string | undefined | null,
  // eslint-disable-next-line no-console
  onError: ErrorFunc = console.error,
) => {
  if (!domain) {
    void onError("getBrandingFromDomain: no domain provided");
    return jeffBranding;
  }

  if (isLocalhostOrVercel(domain) || isNgrokFunnel(domain)) {
    return domainToBrandingMap[LOCALHOST_DOMAIN];
  }

  const branding = domainToBrandingMap[domain as BrandDomains];
  const rootDomain = getDomainFromOrigin({ origin: domain });
  const fallback = domainToBrandingMap[("app." + rootDomain) as BrandDomains];
  const fallbackDev =
    domainToBrandingMap[("app-dev." + rootDomain) as BrandDomains];
  const brandingFinal = branding || fallback || fallbackDev;
  if (!brandingFinal) {
    void onError(
      "getBrandingFromDomain: could not match branding from domain: " + domain,
    );
  }

  return brandingFinal || jeffBranding;
};
