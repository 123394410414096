import { useEffect, useState } from "react";
import { type Language } from "src/common-types";
import { mobilePostMessage } from "./mobilePostMessage";

export const useSetMobileAppLanguage = (
  setCurrentLang: (
    lang: "en" | "vi" | "fil" | "hi" | "id" | "es" | "si",
  ) => void,
) => {
  const [mobileAppLanguage, setMobileAppLanguage] = useState<
    Language | undefined
  >();

  useEffect(() => {
    const handleLoad = () => {
      if (window.ReactNativeWebView && window.ReactNativeWebView.appLanguage) {
        try {
          const appLanguage = window.ReactNativeWebView.appLanguage as Language;
          setMobileAppLanguage(appLanguage);
        } catch (error) {
          throw error;
        }
      } else {
        mobilePostMessage({
          message:
            "window.ReactNativeWebView.injectedObjectJson not found; exiting",
        });
      }
    };

    handleLoad();
  }, []);

  useEffect(() => {
    if (mobileAppLanguage) {
      setCurrentLang(mobileAppLanguage);
      setMobileAppLanguage(undefined);
    }
  }, [mobileAppLanguage, setCurrentLang]);
};
