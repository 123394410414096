import { createExperimentAdPlacementConfig } from "@integration/adsense/createExperimentAdPlacementConfig";
import { type AdSenseExperimentConfig } from "../../types";

export const config: AdSenseExperimentConfig = {
  siteId: "loanonline-ph",
  clientId: "ca-pub-2865748719827515",
  placementConfigs: [
    createExperimentAdPlacementConfig({
      configId: "ph-exp0-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "6344651494", placement: "top" },
        { id: "5031569820", placement: "mid" },
        { id: "4966106746", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp0-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "2405406488", placement: "mid" },
        { id: "7400698391", placement: "bottom" },
      ],
    }),
    {
      id: "ph-exp1",
      toggleId: "adsense-ph-exp1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "3632843770", name: "ph-exp1-top", placement: "top" },
        { id: "2319762108", name: "ph-exp1-mid", placement: "mid" },
        { id: "7460408142", name: "ph-exp1-bottom", placement: "bottom" },
      ],
    },
    {
      id: "ph-exp2",
      toggleId: "adsense-ph-exp2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "5543184646", name: "ph-exp2-top", placement: "top" },
        { id: "9418000415", name: "ph-exp2-mid", placement: "mid" },
        { id: "3044163756", name: "ph-exp2-bottom", placement: "bottom" },
      ],
    },
    createExperimentAdPlacementConfig({
      configId: "ph-exp3-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "2128190418", placement: "top" },
        { id: "8502027072", placement: "mid" },
        { id: "6825983325", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp3-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "7188945408", placement: "mid" },
        { id: "4562782060", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp4-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "6997373714", placement: "top" },
        { id: "4371210371", placement: "mid" },
        { id: "7947493302", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp4-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "9431965361", placement: "mid" },
        { id: "5492720353", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp5-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "5321329963", placement: "top" },
        { id: "9240393674", placement: "mid" },
        { id: "7927312009", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp5-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "3802248638", placement: "mid" },
        { id: "5301148662", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp6-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "2674985324", placement: "top" },
        { id: "9048821987", placement: "mid" },
        { id: "1521685750", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp6-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "6422658648", placement: "mid" },
        { id: "7549921959", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp7-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "7721775366", placement: "top" },
        { id: "9034857038", placement: "mid" },
        { id: "1347938707", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp7-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "6600265388", placement: "mid" },
        { id: "2852592060", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp8-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "3796495302", placement: "top" },
        { id: "1170331960", placement: "mid" },
        { id: "6236840285", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp8-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "8671431937", placement: "mid" },
        { id: "7358350264", placement: "bottom" },
      ],
    }),
    {
      id: "ph-exp9",
      toggleId: "adsense-ph-exp9",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "6442839940", name: "ph-exp9-top", placement: "top" },
        { id: "7957734612", name: "ph-exp9-mid", placement: "mid" },
        { id: "5331571270", name: "ph-exp9-bottom", placement: "bottom" },
      ],
    },
    createExperimentAdPlacementConfig({
      configId: "ph-exp10-test-group1",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "2897574465", placement: "top" },
        { id: "3664409967", placement: "mid" },
        { id: "5697846246", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "ph-exp10-test-group2",
      siteId: "loanonline-ph",
      adUnits: [
        { id: "3368347759", placement: "mid" },
        { id: "7334361548", placement: "bottom" },
      ],
    }),
  ],
};
