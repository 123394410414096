import { useEffect } from "react";
import { type FunctionContext } from "@integration/next/csr/types";
import { onError } from "@integration/bugsnag/csr";

interface Args {
  leadId?: string;
  pathname: string;
  isRouterReady: boolean;
  isSPReady: boolean;
  fnContext?: FunctionContext;
}

const reportPageViewAsync = async (
  leadId: string | undefined,
  pathname: string,
  fnContext?: FunctionContext,
) => {
  const reportPageView = (await import("./reportPageView")).reportPageView;
  reportPageView(leadId, pathname, fnContext);
};

export const useReportPageView = ({
  leadId,
  pathname,
  isRouterReady,
  isSPReady,
  fnContext,
}: Args) => {
  useEffect(() => {
    if (isRouterReady && isSPReady) {
      reportPageViewAsync(leadId, pathname, fnContext).catch(onError);
    }
  }, [isRouterReady, isSPReady, leadId, pathname, fnContext]);
};
