import {
  PARAM_NAME_UTM_CAMPAIGN,
  PARAM_NAME_UTM_MEDIUM,
  PARAM_NAME_UTM_SOURCE,
} from "@jeff/web-analytics/src/marketing";
import { getCookie } from "@integration/cookies";
import { type UrlParams } from "@integration/query-params/common";

export const getUtmSource = (params: UrlParams) => {
  const utmSource =
    params?.[PARAM_NAME_UTM_SOURCE] ||
    getCookie(PARAM_NAME_UTM_SOURCE)?.toString();
  const utmCampaign =
    params?.[PARAM_NAME_UTM_CAMPAIGN] ||
    getCookie(PARAM_NAME_UTM_CAMPAIGN)?.toString();
  const utmMedium =
    params?.[PARAM_NAME_UTM_MEDIUM] ||
    getCookie(PARAM_NAME_UTM_MEDIUM)?.toString();
  return { utmSource, utmCampaign, utmMedium };
};
