import { getCookie } from "@integration/cookies/getCookie";
import { COOKIE_NAME_PREFERRED_LANG } from "@integration/cookies/cookie-names";
import type { IncomingMessage, ServerResponse } from "http";
import type { Languages } from "../localisation-config/types";

export const getStoredLang = (ctx?: {
  req?: IncomingMessage;
  res?: ServerResponse;
}) => {
  const storedLang = getCookie(COOKIE_NAME_PREFERRED_LANG, ctx);
  if (storedLang) {
    return storedLang as Languages;
  }
  return undefined;
};
