import { useEffect, useRef } from "react";
import { onError } from "@integration/bugsnag/csr";
import { flattenBrowserData } from "./utils/flattenBrowserData";
import { useBrowserData } from "./useBrowserData";

export const useReportBrowserData = (isSPReady: boolean) => {
  const hasReported = useRef(false);

  const browserData = useBrowserData();

  useEffect(() => {
    const reportBrowserDataAsync = async () => {
      const trackBrowserData = (await import("@jeff/web-analytics/snowplow"))
        .trackBrowserData;

      trackBrowserData(flattenBrowserData(browserData));
      hasReported.current = true;
    };

    if (isSPReady && browserData && !hasReported.current) {
      reportBrowserDataAsync().catch(onError);
    }
  }, [browserData, isSPReady]);
};
