export const COOKIE_NAME_LEAD_ID = "leadId";
export const COOKIE_NAME_FLOW_ID = "flowId";
export const COOKIE_NAME_PARTNER_ID = "partnerId";
export const COOKIE_NAME_AUTHENTICATED = "authenticated";
export const COOKIE_NAME_PREFERRED_LANG = "__preferred_language";
export const COOKIE_NAME_ENABLED_FEATURES = "__features_";
export const COOKIE_NAME_BRAND = "brand";
export const COOKIE_NAME_REFERRAL_TOKEN = "referral_token";
export const COOKIE_NAME_EXPERIMENT_MARKETING_API = "experiment_marketing_api";
export const COOKIE_NAME_PREFERRED_PRODUCT = "preferred_product";
export const COOKIE_NAME_OFFER_URL = "offer_url";
export const COOKIE_NAME_EMAIL = "email";
export const COOKIE_NAME_OFFER_LISTING_ID = "__offer_listing_id";
export const COOKIE_NAME_REDIRECT_AFTER_REG = "__redirect_after_reg";
export const COOKIE_NAME_REDIRECT_AFTER_SIGN_IN = "__redirect_after_sign_in";
export const COOKIE_NAME_FLOW_CONFIG = "__flow_config";
export const COOKIE_NAME_AUTO_REDIRECTED = "autoRedirected";
export const COOKIE_NAME_ANONYMOUS_REVIEW_ID = "anonymous-review-id";
