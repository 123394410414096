import type { EnhancedNavigator, EnhancedWindow } from "../types";

export const getEnhancedBrowserGlobals = (window: Window) => {
  const enhancedNavigator = window.navigator as EnhancedNavigator;
  const enhancedWindow = window as EnhancedWindow;

  const enhancedConnection =
    enhancedNavigator.connection ||
    enhancedNavigator.mozConnection ||
    enhancedNavigator.webkitConnection;

  return { enhancedNavigator, enhancedConnection, enhancedWindow };
};
