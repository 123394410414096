import { type MyActivityOffer } from "@jeff/account-api";
import {
  type SelfDescribingJson,
  trackSelfDescribingEvent as trackSelfDescribingEventSP,
} from "@snowplow/browser-tracker";
import { isSpInitialized } from "./snowplow";

const LARGE_STRING = 8192;

export const trackSelfDescribingEvent = async (
  args: Parameters<typeof trackSelfDescribingEventSP>[0],
) => {
  if (typeof window === "undefined") {
    return;
  }

  const maxRetries = 10;
  let retries = 0;

  while (!isSpInitialized() && retries <= maxRetries) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    retries += 1;
  }

  if (!isSpInitialized()) {
    // eslint-disable-next-line no-console
    console.error("Snowplow not initialized, event will not be sent!");
  }

  trackSelfDescribingEventSP(args);
};

const truncate = ({
  input,
  length,
}: {
  input: string;
  length: number;
}): string => {
  return input.substring(0, length);
};

export const trackClick = ({ key }: { key: string }) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/click/jsonschema/1-0-0",
      data: { key },
    },
  });
};

export const showEvent = ({
  key,
  context,
}: {
  key: string;
  context?: SelfDescribingJson | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/show/jsonschema/1-0-0",
      data: { key },
    },
    context: context ? [context] : context,
  });
};

export const feedbackElementsShownEvent = ({
  mostPopular,
  partnerId,
  position,
  top3,
  reviewTags,
  offerListingId,
}: {
  mostPopular?: boolean;
  top3?: boolean;
  position: string;
  partnerId: string;
  reviewTags?: string[];
  offerListingId?: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/feedback_elements_shown/jsonschema/2-0-0",
      data: {
        mostPopular,
        partnerId,
        position,
        top3,
        reviewTags,
        offerListingId,
      },
    },
  });
};

export const showLeadActivityEvent = ({
  totalOffers,
  activity,
}: {
  totalOffers: number;
  activity: MyActivityOffer[];
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.feedback/show_lead_activity/jsonschema/1-0-0",
      data: { totalOffers, activity },
    },
  });
};

export const followUsOnZaloEvent = ({
  zaloUserId,
}: {
  zaloUserId: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/follow_us_on_zalo/jsonschema/1-0-0",
      data: { zaloUserId },
    },
  });
};

export const offerListingEvent = ({
  offerListingId,
  flowId,
  redirectReason,
}: {
  offerListingId: string;
  redirectReason?: string | null;
  flowId?: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/offer_listing/jsonschema/2-0-0",
      data: { offerListingId, flowId, redirectReason },
    },
  });
};

export const trackStateEntryEvent = ({
  machine,
  context,
  event,
}: {
  machine: { name: string; version: string };
  context: string;
  event: { name: string; raw: string };
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.xstate/state_entry/jsonschema/1-0-0",
      data: {
        machine: machine.name,
        machineVersion: machine.version,
        rawContextData: truncate({ input: context, length: LARGE_STRING }),
        eventName: event.name,
        rawEventData: truncate({ input: event.raw, length: LARGE_STRING }),
      },
    },
  });
};

export const offerRedirectEvent = ({
  offerListingId,
  flowId,
  partnerId,
  redirectUrl,
}: {
  offerListingId: string;
  partnerId: string;
  flowId?: string | null;
  redirectUrl?: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/offer_redirect/jsonschema/1-0-0",
      data: { offerListingId, flowId, partnerId, redirectUrl },
    },
  });
};

export const referralShareEvent = ({
  platform,
  referralUrl,
}: {
  referralUrl: string;
  platform: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/referral_share/jsonschema/1-0-0",
      data: { referralUrl, platform },
    },
  });
};

export const pushEngageSubscribedEvent = ({
  rawData,
  status,
  message,
  subscriberHash,
  geoCountry,
  geoState,
  geoCity,
}: {
  rawData: string;
  status: string | null;
  message: string | null;
  subscriberHash: string | null;
  geoCountry: string | null;
  geoState: string | null;
  geoCity: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/pushengage_subscribed/jsonschema/1-0-0",
      data: {
        rawData,
        status,
        message,
        subscriberHash,
        geoCountry,
        geoState,
        geoCity,
      },
    },
  });
};

export const pushEngageDeniedEvent = ({
  rawData,
  status,
  message,
}: {
  rawData: string;
  status: string | null;
  message: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/pushengage_denied/jsonschema/1-0-0",
      data: {
        rawData,
        status,
        message,
      },
    },
  });
};

export const pushEngageOtherEvent = ({
  rawData,
  statusCode,
  status,
  message,
}: {
  rawData: string;
  statusCode: number;
  status: string | null;
  message: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/pushengage_other/jsonschema/1-0-0",
      data: {
        rawData,
        statusCode,
        status,
        message,
      },
    },
  });
};

export const scriptLoadedEvent = ({ scriptSrc }: { scriptSrc: string }) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/script_loaded/jsonschema/1-0-0",
      data: {
        scriptSrc,
      },
    },
  });
};

export const adsOnOfferListingEvent = ({
  offerListingId,
  adProvider,
  customerSegment,
  adShown,
  adApproach,
  customerTraits,
  deviceBrand,
  experimentId,
  experimentVariant,
}: {
  offerListingId: string;
  adProvider: string;
  customerSegment: string;
  adShown: boolean;
  adApproach?: string;
  customerTraits?: string[];
  deviceBrand: string | null;
  experimentId: string | null;
  experimentVariant: string | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/ads_on_offer_listing/jsonschema/1-0-0",
      data: {
        offerListingId,
        adProvider,
        customerSegment,
        adShown,
        adApproach,
        customerTraits: customerTraits || [],
        deviceBrand,
        experimentId,
        experimentVariant,
      },
    },
  });
};

export const loanProbabilityEvent = ({
  experimentVariant,
  offerListingId,
  offerIds,
  offerPercentages,
}: {
  experimentVariant: string;
  offerListingId: string;
  offerIds?: string[];
  offerPercentages?: number[];
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/loan_probability_on_offer_listing/jsonschema/1-0-0",
      data: {
        experimentVariant,
        offerListingId,
        offerIds,
        offerPercentages,
      },
    },
  });
};

export const monetagOnOfferListingEvent = ({
  offerListingId,
  customerSegment,
  adShown,
  offers,
  age,
  isForced,
  isExperiment1Enabled,
  isExperiment2Enabled,
  brandId,
  addVariants,
  deviceBrand,
}: {
  offerListingId?: string;
  customerSegment?: string;
  adShown: boolean;
  offers?: number;
  age?: number | null;
  isForced: boolean;
  isExperiment1Enabled?: boolean;
  isExperiment2Enabled?: boolean;
  brandId?: string;
  deviceBrand?: string;
  addVariants?: string[];
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/monetag_on_offer_listing/jsonschema/3-0-0",
      data: {
        offerListingId,
        customerSegment,
        adShown,
        offers,
        age,
        isForced,
        isExperiment1Enabled,
        isExperiment2Enabled,
        brandId,
        addVariants,
        deviceBrand,
      },
    },
  });
};

export const showAdcashEvent = ({
  offerListingId,
  customerSegment,
  adShown,
  offers,
  age,
  isForced,
  isExperiment1Enabled,
  isExperiment2Enabled,
  brandId,
  deviceBrand,
}: {
  isForced: boolean;
  adShown: boolean;
  offerListingId?: string;
  customerSegment?: string;
  offers?: number;
  age?: number | null;
  isExperiment1Enabled?: boolean;
  isExperiment2Enabled?: boolean;
  brandId?: string;
  deviceBrand?: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/adcash_ads/jsonschema/1-0-0",
      data: {
        offerListingId,
        customerSegment,
        adShown,
        offers,
        age,
        isForced,
        isExperiment1Enabled,
        isExperiment2Enabled,
        brandId,
        deviceBrand,
      },
    },
  });
};

export const formFieldValuesEvent = ({
  form,
  email,
  phoneNumber,
}: {
  form: string;
  email?: string;
  phoneNumber?: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/form_field_values/jsonschema/2-0-0",
      data: { form, email, phoneNumber },
    },
  });
};

export const trackBattery = (battery: {
  isSupported: boolean;
  level?: number | null | undefined;
  charging?: boolean | null | undefined;
  dischargingTime?: number | null | undefined;
  chargingTime?: number | null | undefined;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/battery_status/jsonschema/1-0-0",
      data: battery,
    },
  });
};

export const trackECommerceRedirect = ({ offerUrl }: { offerUrl: string }) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/e_commerce_redirect/jsonschema/1-0-0",
      data: { offerUrl },
    },
  });
};

export const trackBrowserData = (browserData: {
  browserHistoryLength?: number;
  browserName?: string;
  browserVersion?: string;
  cpuArchitecture?: string;
  cpuCores?: number;
  cpuPlatform?: string;
  cpuVendor?: string;
  engineName?: string;
  engineVersion?: string;
  absoluteOrientationSensor?: boolean;
  accelerometer?: boolean;
  addEventListener?: boolean;
  ambientLightSensor?: boolean;
  asyncScript?: boolean;
  audioContext?: boolean;
  audioSupported?: boolean;
  audioFormat_m4a?: boolean;
  audioFormat_mp3?: boolean;
  audioFormat_ogg?: boolean;
  audioFormat_opus?: boolean;
  audioFormat_wav?: boolean;
  audioFormat_webm?: boolean;
  battery?: boolean;
  canvas?: boolean;
  classList?: boolean;
  contextMenu?: boolean;
  cookieEnabled?: boolean;
  cors?: boolean;
  css3D?: boolean;
  cssTransform?: boolean;
  cssTransition?: boolean;
  deferScript?: boolean;
  deviceMotion?: boolean;
  deviceOrientation?: boolean;
  deviceRelativeOrientation?: "portrait" | "landscape";
  dialogElement?: boolean;
  doNotTrack: boolean | null;
  file?: boolean;
  fileSystem?: boolean;
  forceTouch?: boolean;
  fullscreen?: boolean;
  fullscreenKeyboard?: boolean;
  geolocation?: boolean;
  gravitySensor?: boolean;
  gyroscope?: boolean;
  hasAdsBlocked?: boolean;
  historyAPI?: boolean;
  linearAccelerationSensor?: boolean;
  littleEndian?: boolean;
  localStorage?: boolean;
  magnetometer?: boolean;
  matchMedia?: boolean;
  pdfViewerEnabled?: boolean;
  pictureElement?: boolean;
  pixelRatio?: number;
  placeholder?: boolean;
  pointerEnabled?: boolean;
  pointerEvents?: boolean;
  pointerLock?: boolean;
  querySelector?: boolean;
  querySelectorAll?: boolean;
  quirksMode?: boolean;
  relativeOrientationSensor?: boolean;
  remUnit?: boolean;
  serviceWorker?: boolean;
  sizes?: boolean;
  srcset?: boolean;
  svg?: boolean;
  touch?: boolean;
  typedArray?: boolean;
  userMedia?: boolean;
  vibration?: boolean;
  videoFormat_mp4?: boolean;
  videoFormat_ogv?: boolean;
  videoFormat_webm?: boolean;
  videoSupported?: boolean;
  viewportUnit?: boolean;
  webGL?: boolean;
  webVR?: boolean;
  webdriver?: boolean;
  webp?: boolean;
  worker?: boolean;
  availableFonts?: string[];
  loadedFonts?: string[];
  deviceMemory?: number;
  deviceType?: string;
  deviceVendor?: string;
  deviceModel?: string;
  hardwareConcurrency?: number;
  language?: string;
  languages?: string[];
  downlink?: number;
  downlinkMax?: number;
  effectiveConnectionType?: string;
  rtt?: number;
  saveData?: boolean;
  connectionType?: string;
  osName?: string;
  osVersion?: string;
  osVersionName?: string;
  platform?: string;
  availableScreenHeight?: number;
  availableScreenWidth?: number;
  nativeScreenHeight?: number;
  nativeScreenWidth?: number;
  screenHeight?: number;
  screenWidth?: number;
  firstPaint?: number;
  firstContentfulPaint?: number;
  largestContentfulPaint?: number;
  timeToFirstByte?: number;
  nextjsHydration?: number;
  nextjsBeforeHydration?: number;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/browser_context/jsonschema/1-0-3",
      data: browserData,
    },
  });
};

export const trackOfferListingSorting = ({
  offerListingId,
  sortingType,
  sortedOfferIds,
}: {
  offerListingId: string;
  sortingType: string;
  sortedOfferIds: string[];
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/sorted_offer_listing/jsonschema/1-0-0",
      data: { offerListingId, sortingType, sortedOfferIds },
    },
  });
};

export const trackFormValidationErrors = ({
  fieldName,
  fieldValue,
  formName,
  errorType,
  errorMsg,
}: {
  formName?: string;
  fieldName?: string;
  fieldValue?: string;
  errorType?: string;
  errorMsg?: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/form_validation_errors/jsonschema/1-0-0",
      data: {
        fieldName,
        fieldValue,
        formName,
        errorType,
        errorMsg,
      },
    },
  });
};

export const trackFormSubmitTime = ({
  formName,
  submitTime,
  attempt,
}: {
  formName?: string;
  submitTime?: number;
  attempt?: number;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/form_submit_time/jsonschema/1-0-0",
      data: { formName, submitTime, attempt },
    },
  });
};

export const trackOfferListingFiltering = ({
  offerListingId,
  filteredOverallRatings,
  filteredApplicationFormRatings,
  filteredGettingLoanRatings,
  filteredOfferIds,
}: {
  offerListingId: string;
  filteredOverallRatings: string[];
  filteredApplicationFormRatings: string[];
  filteredGettingLoanRatings: string[];
  filteredOfferIds: string[];
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/filtered_offer_listing/jsonschema/1-0-0",
      data: {
        offerListingId,
        filteredOverallRatings,
        filteredApplicationFormRatings,
        filteredGettingLoanRatings,
        filteredOfferIds,
      },
    },
  });
};

export const trackAdSlotStatusChange = ({
  adProvider,
  slotId,
  slotName,
  status,
  offerListingId,
}: {
  adProvider: string;
  slotId: string;
  slotName: string;
  status: string;
  offerListingId?: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/ad_slot_status_change/jsonschema/1-0-1",
      data: {
        ad_provider: adProvider,
        slot_id: slotId,
        slot_name: slotName,
        status,
        offer_listing_id: offerListingId,
      },
    },
  });
};

export const trackSortAndFilterBarShown = ({
  leadId,
  offerListingId,
  barShown,
}: {
  leadId?: string | null;
  offerListingId: string;
  barShown: boolean;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/sort_and_filter_bar_shown/jsonschema/1-0-0",
      data: {
        leadId,
        offerListingId,
        barShown,
      },
    },
  });
};

export interface ZaloDetailsEmailRejectedEventProps
  extends Record<string, unknown> {
  leadId: string;
  type: "error" | "warning";
  errorId:
    | "email_already_belongs_to_other_lead"
    | "invalid_email"
    | "email_suggestion"
    | "invalid_response";
}

export const trackZaloDetailsEmailRejected = (
  data: ZaloDetailsEmailRejectedEventProps,
) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/zalo_details_email_rejected/jsonschema/1-0-2",
      data,
    },
  });
};

export const functionExecutionContext = ({
  metricType,
  requestId,
  executionTime,
}: {
  metricType: string;
  requestId: string;
  executionTime: number;
}): SelfDescribingJson => {
  return {
    schema: "iglu:app.j3ff.web/function_execution_context/jsonschema/1-0-1",
    data: {
      metric_type: metricType,
      request_id: requestId,
      execution_time: executionTime,
    },
  };
};

export const elementShown = ({
  key,
  context,
}: {
  key: string;
  context?: SelfDescribingJson[] | null;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/show/jsonschema/1-0-0",
      data: { key },
    },
    context,
  });
};

export type ProcessExecutionTimeEvent = {
  requestId: string;
  process:
    | "fetching-offers"
    | "rendering-offers"
    | `polling-offer-listing-${number}`;
  executionTime: number;
};

export const trackProcessExecutionTime = (
  data: ProcessExecutionTimeEvent,
  context?: SelfDescribingJson[] | null,
) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/process_execution_time/jsonschema/1-0-0",
      data,
    },
    context,
  });
};

export type ApplicationFunnelStepCompletedEvent = {
  stepName: string;
  timeToCompleteSeconds: number;
  experimentEnabled: boolean;
};

export const trackApplicationFunnelStepCompleted = (
  data: ApplicationFunnelStepCompletedEvent,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/application_funnel_step_completed/jsonschema/2-0-0",
      data: {
        step_name: data.stepName,
        time_to_complete_seconds: data.timeToCompleteSeconds,
        experiment_enabled: data.experimentEnabled,
      },
    },
  });
};

export const experimentFeatureToggleContext = ({
  feature,
  testGroupExperimentIds,
  controlGroupExperimentIds,
}: {
  feature: string;
  testGroupExperimentIds: string[];
  controlGroupExperimentIds: string[];
}): SelfDescribingJson => {
  return {
    schema: "iglu:app.j3ff.experiments/toggle_context/jsonschema/1-0-0",
    data: {
      feature,
      test_group_experiments: testGroupExperimentIds,
      control_group_experiments: controlGroupExperimentIds,
    },
  };
};

export interface OneTapEvent extends Record<string, unknown> {
  dismissedReason: string | null;
  isDisplayed: boolean | null;
  isNotDisplayed: boolean | null;
  momentType: string | null;
  notDisplayedType: string | null;
  skippedReason: string | null;
}

export const trackGoogleOneTapPopup = (data: OneTapEvent) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/google_one_tap_pop_up/jsonschema/1-0-0",
      data,
    },
  });
};

export type ZaloRegFlowState = "initiated" | "completed" | "failed";

export const trackZaloRegFlowState = ({
  flowState,
  experimentEnabled = false,
}: {
  flowState: ZaloRegFlowState;
  experimentEnabled?: boolean;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/zalo_reg_flow_state/jsonschema/1-0-0",
      data: {
        flowState,
        experimentEnabled,
      },
    },
  });
};

export const trackZaloDetailsConfirmEmailRedirect = ({
  email,
}: {
  email: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/zalo_details_confirm_email_redirect/jsonschema/1-0-0",
      data: {
        email,
      },
    },
  });
};

export type CaptchaAction = "registration";

export const trackCaptchaDisplay = ({
  shown,
  action,
}: {
  shown: boolean;
  action: CaptchaAction;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/captcha_display/jsonschema/1-0-0",
      data: {
        shown: shown,
        action: action,
      },
    },
  });
};

export interface ApiAutoRedirectEvent extends Record<string, unknown> {
  autoRedirected: boolean;
  experimentEnabled: boolean;
}

export const trackApiAutoRedirect = (data: ApiAutoRedirectEvent) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/api_auto_redirect/jsonschema/1-0-0",
      data,
    },
  });
};

export const dynamicBanners = {
  onShown: ({
    bannerId,
    isReallyShown,
    experimentId,
    experimentVariant,
    offerListingId,
  }: {
    bannerId: string;
    isReallyShown: boolean;
    experimentId: string | null;
    experimentVariant: string | null;
    offerListingId: number | null;
  }) => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:app.j3ff.web/dynamic_banner_shown/jsonschema/2-0-0",
        data: {
          banner_id: bannerId,
          is_really_shown: isReallyShown,
          experiment_id: experimentId,
          experiment_variant: experimentVariant,
          offer_listing_id: offerListingId,
        },
      },
    });
  },
  onClick: ({
    bannerId,
    buttonId,
    experimentId,
    experimentVariant,
    offerListingId,
  }: {
    bannerId: string;
    buttonId: string;
    experimentId: string | null;
    experimentVariant: string | null;
    offerListingId: number | null;
  }) => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:app.j3ff.web/dynamic_banner_click/jsonschema/2-0-0",
        data: {
          banner_id: bannerId,
          button_id: buttonId,
          experiment_id: experimentId,
          experiment_variant: experimentVariant,
          offer_listing_id: offerListingId,
        },
      },
    });
  },
};

export interface TrackIdStepButtonClickData extends Record<string, unknown> {
  leadId: string;
  experimentId: string;
  experimentVariantId: string;
  clickedButtonId: string;
}

export const trackIdStepButtonClick = (data: TrackIdStepButtonClickData) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/skip_id_button_click/jsonschema/1-0-0",
      data,
    },
  });
};
export interface TrackTopOfferAutoRedirectUserChoiceData
  extends Record<string, unknown> {
  offerListingId: string;
  leadId: string;
  experimentVariant: string;
  clickedButtonId: string;
}

export const trackTopOfferAutoRedirectUserChoice = (
  data: TrackTopOfferAutoRedirectUserChoiceData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/top_offer_auto_redirect_user_choice/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackFlowIdStepExperimentData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}
export const trackFlowIdStepExperiment = (
  data: TrackFlowIdStepExperimentData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/flow_id_step_experiment_report/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackAddressInputVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}
export const trackAltAddressInputVariant = (
  data: TrackFlowIdStepExperimentData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_alt_address_input_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackAddressSubmitVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
  inputVariant: "manual" | "geolocation" | null;
}
export const trackAltAddressSubmitVariant = (
  data: TrackAddressSubmitVariantData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_alt_address_submit_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackIncomeInputVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}

export const trackAltIncomeInputVariant = (
  data: TrackIncomeInputVariantData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_alt_income_input_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackIncomeSubmitVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
  inputValue: string;
}
export const trackAltIncomeSubmitVariant = (
  data: TrackIncomeSubmitVariantData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_alt_income_submit_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackDobInputVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}
export const trackAltDobInputVariant = (data: TrackDobInputVariantData) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/track_alt_dob_input_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackDobSubmitVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}
export const trackAltDobSubmitVariant = (data: TrackDobSubmitVariantData) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/track_alt_dob_submit_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackProgressBarVariantData extends Record<string, unknown> {
  experimentId: string;
  experimentVariantId: string;
  leadId: string;
}

export const trackProgressBarVariant = (data: TrackProgressBarVariantData) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_alt_progress_bar_variant/jsonschema/1-0-0",
      data,
    },
  });
};

export interface TrackExperimentEngagementData extends Record<string, unknown> {
  experimentId: string;
  isEnabled: boolean;
  experimentVariantId: string | null;
  leadId: string | null;
  brandId: string | null;
}

export const trackExperimentEngagement = (
  data: TrackExperimentEngagementData,
) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/track_experiment_engagement/jsonschema/1-0-0",
      data,
    },
  });
};

export const olFeedbackPopUpShown = (data: {
  leadId: string | null;
  offerListingId: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/ol_feedback_pop_up_shown/jsonschema/1-0-1",
      data,
    },
  });
};

export const olFeedbackPopUpHasIssues = (data: {
  leadId: string | null;
  hasIssues: boolean;
  offerListingId: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/ol_feedback_pop_up_has_issues/jsonschema/1-0-1",
      data,
    },
  });
};

export const olFeedbackPopUpSubmitted = (data: {
  leadId: string | null;
  message: string;
  offerListingId: string;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:app.j3ff.web/ol_feedback_pop_up_submitted/jsonschema/1-0-1",
      data,
    },
  });
};

export const trackOlFeedbackPopUpEligibility = (data: {
  leadId: string;
  offerListingId: string;
  popUpShown: boolean;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/ol_feedback_pop_up_eligibility/jsonschema/1-0-0",
      data,
    },
  });
};

export const trackNameAndDobInactivityPopUp = (data: {
  leadId: string;
  experimentId: string;
  experimentVariantId: string;
  brandId: string;
  randomCount: number;
  inactivityDuration: number;
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:app.j3ff.web/track_name_and_dob_inactivity_pop_up/jsonschema/1-0-0",
      data,
    },
  });
};
