import { createExperimentAdPlacementConfig } from "../../createExperimentAdPlacementConfig";
import { type AdSenseExperimentConfig } from "../../types";

export const config: AdSenseExperimentConfig = {
  siteId: "finmercado-mx",
  clientId: "ca-pub-2865748719827515",
  placementConfigs: [
    createExperimentAdPlacementConfig({
      configId: "mx-exp1",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "6004044258", placement: "top" },
        { id: "7341176651", placement: "mid" },
        { id: "1293846893", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp2",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "2421178382", placement: "top" },
        { id: "8505701042", placement: "mid" },
        { id: "5054058114", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp3",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "5166761229", placement: "top" },
        { id: "8610159742", placement: "mid" },
        { id: "8202131482", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp4",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "5853743666", placement: "top" },
        { id: "7941685100", placement: "mid" },
        { id: "9265788335", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp5",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "5315521760", placement: "top" },
        { id: "9251693502", placement: "mid" },
        { id: "7952706665", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp6",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "6625530165", placement: "top" },
        { id: "5312448490", placement: "mid" },
        { id: "2229606693", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp7",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "8603443354", placement: "top" },
        { id: "7350658161", placement: "mid" },
        { id: "1321232597", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "mx-exp8",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "9060121810", placement: "top" },
        { id: "2689358429", placement: "mid" },
        { id: "6639624998", placement: "bottom" },
      ],
    }),
    {
      id: "mx-exp0",
      toggleId: "adsense-mx-exp0",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "3818165350", name: "mx-exp0-top", placement: "top" },
        { id: "8505701042", name: "mx-exp0-mid", placement: "mid" },
      ],
    },
    {
      id: "mx-exp9",
      toggleId: "adsense-mx-exp9",
      siteId: "finmercado-mx",
      adUnits: [],
    },
    {
      id: "mx-exp10",
      toggleId: "adsense-mx-exp10",
      siteId: "finmercado-mx",
      adUnits: [
        { id: "9747894550", name: "mx-exp10-top", placement: "top" },
        { id: "6934028953", name: "mx-exp10-mid", placement: "mid" },
        { id: "5620947281", name: "mx-exp10-bottom", placement: "bottom" },
      ],
    },
  ],
};
