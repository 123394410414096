import { useEffect, useRef, useState } from "react";
import { onError } from "@integration/bugsnag/csr";

interface Params {
  leadId: string | undefined;
  emailConfirmationToken: string | undefined;
}

export const useConfirmEmailWithLongLivedToken = ({
  leadId,
  emailConfirmationToken,
}: Params) => {
  /**
   * leadId might not yet be available when app loads, so we temporarily
   * store emailToken in state till leadId is available. This is to avoid
   * url query passing throughout the app's pages.
   */
  const [token, setToken] = useState<string | undefined>(
    emailConfirmationToken,
  );

  useEffect(() => {
    if (emailConfirmationToken) {
      setToken(emailConfirmationToken);
    }
  }, [emailConfirmationToken]);

  const isConfirmedOnce = useRef<boolean>(false);
  useEffect(() => {
    if (isConfirmedOnce.current) {
      return;
    }

    if (leadId && token) {
      (async () => {
        const confirmLeadEmailWithLongLivedToken = (
          await import("./confirmLeadEmailWithLongLivedToken")
        ).confirmLeadEmailWithLongLivedToken;

        const isDone = await confirmLeadEmailWithLongLivedToken({
          leadId,
          token,
        });

        if (isDone) {
          isConfirmedOnce.current = true;
        }
      })().catch(onError);
    }
  }, [token, leadId]);
};
