import { CONFIG_ENTRIES } from "./config";
import type { PartnerOfferId } from "./types";

export const findThemeByOfferId = async ({
  offerId,
}: {
  offerId: PartnerOfferId;
}) => {
  const entry = CONFIG_ENTRIES.find((it) => it.offerIds.includes(offerId));
  return await entry?.getTheme();
};
