import { getCookie as doGetCookie } from "cookies-next";
import { z } from "zod";
import { getDefaultCookieOptions } from "./getDefaultCookieOptions";
import type { OptionsType } from "cookies-next/lib/types";

export const getCookie = (key: string, options?: OptionsType) => {
  const cookieOptions: OptionsType = options || getDefaultCookieOptions();
  return doGetCookie(key, cookieOptions);
};

export const getCookieAsString = (
  key: string,
  options?: OptionsType,
): string | null => {
  const value = getCookie(key, options);
  const parsed = z
    .string()
    .transform((v) => v.toString())
    .safeParse(value);
  if (parsed.success) {
    return parsed.data;
  }
  return null;
};

export const getCookieAsInt = (
  key: string,
  options?: OptionsType,
): number | null => {
  const value = getCookie(key, options);
  const parsed = z
    .string()
    .transform((v) => parseInt(v.toString()))
    .safeParse(value);
  if (parsed.success) {
    return parsed.data;
  }
  return null;
};
