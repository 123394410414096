import {
  MX_ADSENSE_REMAINING_VISITORS_EXP_ID,
  MX_ADSENSE_EXP_1_ID,
  MX_ADSENSE_EXP_2_ID,
  MX_ADSENSE_EXP_8_ID,
  MX_ADSENSE_EXP_3_ID,
  MX_ADSENSE_EXP_4_ID,
  MX_ADSENSE_EXP_5_ID,
  MX_ADSENSE_EXP_6_ID,
  MX_ADSENSE_EXP_7_ID,
  MX_ADSENSE_EXP_9_ID,
  MX_ADSENSE_EXP_10_ID,
} from "@integration/experiments/constants";
import { type Rule } from "@integration/adsense/rules/types";

export const rules: Rule[] = [
  {
    experimentId: MX_ADSENSE_EXP_10_ID,
    conditions: [
      { field: "is_fresh", operator: "==", value: true },
      { field: "has_no_redirects", operator: "==", value: true },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_8_ID,
    conditions: [{ field: "offer_count", operator: "==", value: 0 }],
  },
  {
    experimentId: MX_ADSENSE_EXP_9_ID,
    conditions: [{ field: "utm_source", operator: "==", value: "facebook" }],
  },
  {
    experimentId: MX_ADSENSE_EXP_1_ID,
    conditions: [
      { field: "is_days_on_book_zero", operator: "==", value: true },
      { field: "quality_score", operator: ">=", value: 0.05 },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_2_ID,
    conditions: [{ field: "age", operator: "<", value: 21 }],
  },
  {
    experimentId: MX_ADSENSE_EXP_3_ID,
    conditions: [
      { field: "age", operator: ">=", value: 21 },
      { field: "age", operator: "<", value: 30 },
      { field: "offer_count", operator: ">", value: 7 },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_4_ID,
    conditions: [
      { field: "age", operator: ">=", value: 21 },
      { field: "age", operator: "<", value: 30 },
      { field: "offer_count", operator: "<=", value: 7 },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_5_ID,
    conditions: [
      { field: "age", operator: ">=", value: 30 },
      { field: "age", operator: "<", value: 39 },
      { field: "offer_count", operator: ">", value: 7 },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_6_ID,
    conditions: [
      { field: "age", operator: ">=", value: 30 },
      { field: "age", operator: "<", value: 39 },
      { field: "offer_count", operator: "<=", value: 7 },
    ],
  },
  {
    experimentId: MX_ADSENSE_EXP_7_ID,
    conditions: [{ field: "age", operator: ">=", value: 40 }],
  },
  {
    experimentId: MX_ADSENSE_REMAINING_VISITORS_EXP_ID,
    conditions: [],
  },
];
