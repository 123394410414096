import {
  WHITELISTED_QUERY_PARAM_KEYS,
  isAllowedToShowAdsense,
} from "@integration/adsense/guards";
import type { UrlParamNames } from "@integration/query-params/common";

export const isIncorrectAdsenseUsageDetected = ({
  pathname,
  query,
}: {
  pathname: string;
  query: object;
}): boolean => {
  if (!isAllowedToShowAdsense({ path: pathname })) {
    return true;
  }

  const illegalQueryParamKeys = Object.keys(query).filter(
    (key) => !WHITELISTED_QUERY_PARAM_KEYS.includes(key as UrlParamNames),
  );

  if (illegalQueryParamKeys.length > 0) {
    return true;
  }

  return false;
};
