import { useCallback } from "react";
import { onError } from "@integration/bugsnag/csr";
import {
  FINMART_ID_BRAND_ID,
  FINMART_VN_BRAND_ID,
} from "@domain/branding/constants";
import { translate } from "@domain/localisation/common/translate";
import type {
  Languages,
  Translation,
} from "@domain/localisation/localisation-config/types";
import type { BrandId, BrandName } from "@domain/branding/common/types";

type Args = {
  lang: Languages;
  brandId: BrandId;
  brandName: BrandName;
  translationDict?: Translation;
  fallbackTranslationDict?: Translation;
};

export const useTranslate = ({
  lang,
  brandId,
  brandName,
  fallbackTranslationDict,
  translationDict,
}: Args) => {
  const translateFunc = useCallback(
    (
      key: string,
      placeholders?: Record<string, string | number>,
      options?: {
        replace?: {
          pattern: string | RegExp;
          value: string;
        };
      },
    ) => {
      const shouldAutoTranslateBrandName =
        !options &&
        [FINMART_VN_BRAND_ID, FINMART_ID_BRAND_ID].includes(brandId);

      const optionsOverride = shouldAutoTranslateBrandName
        ? { replace: { pattern: /jeff/gi, value: brandName } }
        : options;

      let translation = translate({
        translationDict,
        fallbackTranslationDict,
        key,
        lang,
        options: optionsOverride,
        onError,
      });

      if (!translation || translation === key) {
        onError(`Failed to process translation key ${key} for lang: ${lang}`);
        return key;
      }

      if (placeholders) {
        Object.entries(placeholders).forEach(([argKey, argValue]) => {
          translation = translation.replace(`{${argKey}}`, String(argValue));
        });
      }

      return translation;
    },
    [brandId, brandName, fallbackTranslationDict, lang, translationDict],
  );

  return translateFunc;
};
