import { getBrandingFromDomain } from "@domain/branding/common";

const getPublicAssetBaseUrl = () => {
  if (typeof window === "undefined") {
    return "https://cdn.jeff-app.com";
  }
  const { hostname } = window.location;
  const brand = getBrandingFromDomain(hostname);
  const domain = brand.brandDomain;
  if (domain === "localhost:3000" || hostname.includes("localhost")) {
    return "";
  }
  if (domain.startsWith("app-dev.")) {
    return "https://" + domain.replace("app-dev.", "cdn-dev.");
  }
  if (domain.startsWith("app.")) {
    return "https://" + domain.replace("app.", "cdn.");
  }
  throw new Error(
    `Could not find CDN config for domain=${domain}, hostname=${hostname}`,
  );
};

export const asset = (src: string): string => {
  if (src.startsWith("data:")) {
    return src;
  }
  return `${getPublicAssetBaseUrl()}/assets/${src}`;
};

export const creditCardImageSrc = ({
  offerId,
}: {
  offerId: string;
}): string => {
  return `${getPublicAssetBaseUrl()}/offers/${offerId}/assets/cc-image.png`;
};

export const cdnUrl = ({ path }: { path: string }): string => {
  return getPublicAssetBaseUrl() + path;
};
