import { vi } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const vnItems: LocalisationConfig = {
  country: "VN",
  allowedLanguages: ["vi"],
  defaultLanguage: "vi",
  hrefLang: "vi-VN",
  currency: {
    abbreviation: "VND",
    symbol: "₫",
    format: (amount: number) => `${amount.toLocaleString()}₫`,
  },
  phonePrefix: allPhonePrefixes.VN,
  locale: vi,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "Việt Nam",
};
