import type { Languages, Translation } from "../localisation-config/types";

export const getTranslations = async (
  lang: Languages,
): Promise<Translation> => {
  if (lang === "vi") {
    return await import("@generated-translations/vi.json");
  }

  if (lang === "fil") {
    return await import("@generated-translations/fil.json");
  }

  if (lang === "hi") {
    return await import("@generated-translations/hi.json");
  }

  if (lang === "id") {
    return await import("@generated-translations/id.json");
  }

  if (lang === "es") {
    return await import("@generated-translations/es_MX.json");
  }

  return await import("@generated-translations/en.json");
};
