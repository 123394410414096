import { useEffect, useState } from "react";
import { useRouter } from "@integration/next-router";
import { SIGN_OUT_PAGE_URI } from "@utils/constants";
import { getCookie } from "@integration/cookies/getCookie";
import { COOKIE_NAME_AUTHENTICATED } from "@integration/cookies/cookie-names";
import { AuthContext } from "./context";
import type { AuthProviderProps } from "./AuthProvider.types";

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const isAuthTokenPresent = !!getCookie(COOKIE_NAME_AUTHENTICATED);
  const router = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthTokenPresent);

  useEffect(() => {
    if (isAuthTokenPresent !== isLoggedIn) {
      setIsLoggedIn(isAuthTokenPresent);
    }
  }, [isLoggedIn, isAuthTokenPresent]);

  const onLogOut = () => {
    setIsLoggedIn(false);
    void router.push(SIGN_OUT_PAGE_URI);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logOut: onLogOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
