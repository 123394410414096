import { withErrorBoundary } from "react-error-boundary";
import { isErrorTypeOf, onError } from "@integration/bugsnag/csr";
import { useTrackBattery } from "./useTrackBattery";

export const Component = () => {
  useTrackBattery(true);
  return null;
};

export const TrackBatteryContainer = withErrorBoundary(Component, {
  fallback: null,
  onError(error) {
    if (isErrorTypeOf({ e: error, errorType: "illegal-invocation" })) {
      return;
    }
    onError(error);
  },
});
