export class CustomError extends Error {
  constructor({ name, message }: { name: string; message: string }) {
    super(message);
    this.name = name;
  }
}

export class SampledError extends CustomError {
  shouldSkipReporting: boolean;
  constructor({
    name,
    message,
    shouldSkipReporting,
  }: {
    name: string;
    message: string;
    shouldSkipReporting: boolean;
  }) {
    super({ name, message });
    this.shouldSkipReporting = shouldSkipReporting;
  }
}

const getSampling = ({
  name,
}: {
  name: string;
}): { sampled: boolean; shouldSkipReporting: boolean } => {
  const canBeSampled = [
    "FailedToLoadStaticProps",
    "ChunkLoadError",
    "FailedToLoadScript",
    "UnleashRequestError",
  ];
  if (!canBeSampled.includes(name)) {
    return {
      sampled: false,
      shouldSkipReporting: false,
    };
  }
  return {
    sampled: true,
    shouldSkipReporting: Math.random() > 0.05,
  };
};

/**
 * Bugsnag has a limited amount of errors that can be sent over specific time period
 * While there are some errors that we are not fully ready to ignore or fix, we should sample them
 */
export const asSampledError = (
  source: Error | string | undefined,
): SampledError => {
  let errorMessage: string;
  let errorName: string;
  let errorStack: string | undefined;

  if (typeof source === "string") {
    errorMessage = source;
    errorName = "Error";
    errorStack = undefined;
  } else {
    errorMessage = source?.message || "{no error message}";
    errorName = source?.name || "UnknownError";
    errorStack = source?.stack;
  }

  const sampling = getSampling({ name: errorName });
  const sampledName = sampling.sampled ? `__sampled_${errorName}` : errorName;

  const result = new SampledError({
    name: sampledName,
    message: errorMessage,
    shouldSkipReporting: sampling.shouldSkipReporting,
  });

  if (errorStack) {
    result.stack = errorStack;
  }

  return result;
};
