import { isVariantInTestGroup } from "../constants";
import { type ExperimentProps } from "../types";

export const isInTestGroup = ({
  experimentId,
  experiments,
}: {
  experimentId: string;
  experiments: ExperimentProps | undefined;
}): boolean => {
  const enabled = experiments?.variants[experimentId]?.enabled;
  if (!enabled) {
    return false;
  }
  return isVariantInTestGroup(experiments?.variants[experimentId]?.name);
};
