import { getBrandingFromDomain } from "@domain/branding/common/getBrandingFromDomain";
import { PARAM_NAME_PARTNER_URL_KEY } from "@utils/constants";
import { asPartnerOfferId } from "@domain/partner-offers/utils";
import { asLeadPageId } from "@domain/lead-pages/utils";
import type { UrlParams } from "@integration/query-params/common/types";
import type { EntityId } from "@domain/branding/common/types";

export const getInitialThemeId = ({
  domain,
  urlParams,
}: {
  domain: string;
  urlParams: UrlParams;
}): EntityId => {
  const partnerOfferId = asPartnerOfferId({
    input: urlParams[PARAM_NAME_PARTNER_URL_KEY],
  });
  if (partnerOfferId) {
    return partnerOfferId;
  }
  const leadPageId = asLeadPageId({
    input: urlParams[PARAM_NAME_PARTNER_URL_KEY],
  });
  if (leadPageId) {
    return leadPageId;
  }
  return getBrandingFromDomain(domain).brandId;
};
