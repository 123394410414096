import { BrowserPlugin } from "@snowplow/browser-tracker-core";
import { addListener, metadata, Metadata } from "../clarity";

let latestMetadata: Metadata | undefined;

const collect = () => metadata((it) => (latestMetadata = it));

export const ClarityContextPlugin = (): BrowserPlugin => {
  return {
    activateBrowserPlugin: () => {
      collect();
      addListener(collect);
    },
    contexts: () => {
      if (!latestMetadata) {
        return [];
      }
      return [
        {
          schema: "iglu:app.j3ff.web/clarity_context/jsonschema/1-0-0",
          data: {
            projectId: latestMetadata.projectId,
            sessionId: latestMetadata.sessionId,
            userId: latestMetadata.userId,
          },
        },
      ];
    },
  };
};
