import { enUS } from "date-fns/locale";
import { branding } from "@domain/branding/common";
import { getCountryFromEntityId } from "@domain/localisation/common/getCountryFromEntityId";
import { JEFF_VN_BRAND_ID } from "@domain/branding/constants";
import { type Country } from "@domain/common-types";
import { allPhonePrefixes } from "../types";
import { idItems } from "./id";
import { inItems } from "./in";
import { phItems } from "./ph";
import { vnItems } from "./vn";
import { mxItems } from "./mx";
import type { Currency, LocalisationConfig } from "../types";
import type { BrandId } from "@domain/branding/common";

const brandIdOverride = process.env.NEXT_PUBLIC_LOCAL_DEV_BRAND_ID as BrandId;
const countryToUse =
  getCountryFromEntityId({
    entityId: brandIdOverride || branding.brandId || JEFF_VN_BRAND_ID,
  }) || "VN";

const currencyByCountryMap: Record<Country, Currency> = {
  ID: idItems.currency,
  IN: inItems.currency,
  PH: phItems.currency,
  US: vnItems.currency,
  VN: vnItems.currency,
  MX: mxItems.currency,
};

export const localhostItems: LocalisationConfig = {
  country: countryToUse,
  allowedLanguages: ["en", "vi", "fil", "hi", "id", "es"],
  defaultLanguage: "en",
  hrefLang: "en-US",
  currency: currencyByCountryMap?.[countryToUse],
  phonePrefix: allPhonePrefixes?.[countryToUse],
  locale: enUS,
  dateInputFormat: "dd/MM/yyyy",
  countryName: "countryName",
};
