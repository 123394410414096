/* eslint-disable no-console */

import {
  ASIA_DEALS_BRAND_ID,
  DONG247_BRAND_ID,
  DONG_DEALS_BRAND_ID,
  MONEYDONG_BRAND_ID,
} from "@domain/branding/constants";

interface Args {
  entityId: string;
  onError?: (error: Error | string) => void;
}

export const getCountryFromEntityId = ({
  entityId,
  onError = console.error,
}: Args) => {
  const lastLetters = entityId.slice(-3).toUpperCase();
  if (lastLetters === "-VN") {
    return "VN";
  }
  if (lastLetters === "-PH") {
    return "PH";
  }
  if (lastLetters === "-IN") {
    return "IN";
  }
  if (lastLetters === "-ID") {
    return "ID";
  }
  if (lastLetters === "-MX") {
    return "MX";
  }
  if (
    [
      DONG247_BRAND_ID,
      MONEYDONG_BRAND_ID,
      ASIA_DEALS_BRAND_ID,
      DONG_DEALS_BRAND_ID,
    ].includes(entityId)
  ) {
    return "VN";
  }

  onError("Cannot map to country: " + entityId);

  return "US";
};
