import { isRunningOnVercel } from "@integration/vercel/ssr";
import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";
import { type RequestAware } from "./types";

type Headers = Record<string, string | string[] | undefined>;

type Request = { headers: Headers };

const firstHeader = (headers: Headers, name: string): string | undefined => {
  const headerName = name.toLowerCase();
  const value = Object.keys(headers)
    .filter((key) => key.toLowerCase() === headerName)
    .map((key) => headers[key])[0];
  return Array.isArray(value) ? value[0] : value;
};

export const requireHostname = ({ req }: RequestAware<Request>): string => {
  if (!req) {
    throw new Error("No request provided!");
  }
  const items = [
    firstHeader(req.headers, "x-server-name"), // Caddy when used as reverse proxy
    firstHeader(req.headers, "host"),
    firstHeader(req.headers, "origin"),
    firstHeader(req.headers, "referer"),
    process.env.NODE_ENV === "development" ? "localhost" : undefined,
  ];
  for (const it of items) {
    if (it && !it.includes("vercel.app")) {
      return it;
    }
  }
  const vercelFallback = items.find((it) => it?.includes("vercel.app"));
  if (vercelFallback) {
    return vercelFallback;
  }
  throw new Error(
    `Could not extract hostname from headers=${JSON.stringify(req.headers)}`,
  );
};

export const getCookieDomain = ({ req }: RequestAware<Request>) => {
  const host = requireHostname({ req });

  if (host.includes("localhost") || host.includes("ngrok-free.app")) {
    return "localhost";
  }

  const domain = getDomainFromOrigin({
    origin: host,
    prefixDot: isRunningOnVercel(),
  });
  if (!domain) {
    throw new Error("Unable to resolve to a known domain: " + host);
  }
  return domain;
};

export const requireClientIpAddress = ({
  req,
}: RequestAware<Request>): string => {
  if (!req) {
    throw new Error("No request provided!");
  }
  const items = [
    firstHeader(req.headers, "x-caddy-ip"),
    firstHeader(req.headers, "x-real-ip"),
    process.env.NODE_ENV === "development" ? "0.0.0.0" : undefined,
  ];
  for (const it of items) {
    if (it && !it.includes("vercel")) {
      return it;
    }
  }
  throw new Error(
    `Could not extract IP address from headers=${JSON.stringify(req.headers)}`,
  );
};

export const getAcceptLanguageHeader = ({ req }: RequestAware<Request>) => {
  if (!req) {
    throw new Error("No request provided!");
  }

  return firstHeader(req.headers, "accept-language");
};
