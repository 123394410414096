import {
  PH_ADSENSE_REMAINING_VISITORS_EXP_ID,
  PH_ADSENSE_EXP_1_ID,
  PH_ADSENSE_EXP_2_ID,
  PH_ADSENSE_EXP_4_ID,
  PH_ADSENSE_EXP_3_ID,
  PH_ADSENSE_EXP_5_ID,
  PH_ADSENSE_EXP_6_ID,
  PH_ADSENSE_EXP_7_ID,
  PH_ADSENSE_EXP_8_ID,
  PH_ADSENSE_EXP_9_ID,
  PH_ADSENSE_EXP_10_ID,
} from "@integration/experiments/constants";
import { type Rule } from "@integration/adsense/rules/types";

export const rules: Rule[] = [
  {
    experimentId: PH_ADSENSE_EXP_10_ID,
    conditions: [
      { field: "is_fresh", operator: "==", value: true },
      { field: "has_no_redirects", operator: "==", value: true },
      { field: "quality_score", operator: ">=", value: 0.05 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_1_ID,
    conditions: [
      { field: "is_days_on_book_zero", operator: "==", value: true },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_2_ID,
    conditions: [{ field: "age", operator: "<=", value: 21 }],
  },
  {
    experimentId: PH_ADSENSE_EXP_3_ID,
    conditions: [
      { field: "age", operator: ">=", value: 22 },
      { field: "age", operator: "<=", value: 29 },
      { field: "offer_count", operator: "<", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_4_ID,
    conditions: [
      { field: "age", operator: ">=", value: 22 },
      { field: "age", operator: "<=", value: 29 },
      { field: "offer_count", operator: ">=", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_5_ID,
    conditions: [
      { field: "age", operator: ">=", value: 30 },
      { field: "age", operator: "<=", value: 39 },
      { field: "offer_count", operator: "<", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_6_ID,
    conditions: [
      { field: "age", operator: ">=", value: 30 },
      { field: "age", operator: "<=", value: 39 },
      { field: "offer_count", operator: ">=", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_7_ID,
    conditions: [
      { field: "age", operator: ">=", value: 40 },
      { field: "age", operator: "<=", value: 59 },
      { field: "offer_count", operator: "<", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_8_ID,
    conditions: [
      { field: "age", operator: ">=", value: 40 },
      { field: "age", operator: "<=", value: 59 },
      { field: "offer_count", operator: ">=", value: 4 },
    ],
  },
  {
    experimentId: PH_ADSENSE_EXP_9_ID,
    conditions: [{ field: "age", operator: "==", value: null }],
  },
  {
    experimentId: PH_ADSENSE_REMAINING_VISITORS_EXP_ID,
    conditions: [],
  },
];
