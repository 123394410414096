import { adsOnOfferListingEvent } from "@jeff/web-analytics/snowplow";

type TrackOfferListingParams = Parameters<typeof adsOnOfferListingEvent>[0];

export const trackAdsOfferListing = (params: TrackOfferListingParams) => {
  adsOnOfferListingEvent({
    ...params,
    experimentId: params.experimentId
      ? params.experimentId.replace("adsense-", "")
      : null,
  });
};
