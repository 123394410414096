import { useEffect, useState } from "react";
import { onError } from "@integration/bugsnag/csr";
import { LOCALHOST_DOMAIN } from "@domain/branding/constants";
import { localhostItems } from "@domain/localisation/localisation-config/countries/localhost";
import { fetchLocalisationConfig } from "@domain/localisation/common/fetchLocalisationConfig";
import { type Country } from "@domain/common-types";
import type { LocalisationConfig } from "@domain/localisation/localisation-config/types";
import type { BrandDomains, BrandId } from "@domain/branding/common/types";

interface Args {
  domain: BrandDomains;
  brandId: BrandId;
  storybookCountry?: Country;
}

export const useLocalisationConfigState = ({
  domain,
  brandId,
  storybookCountry,
}: Args) => {
  const initialConfig = [LOCALHOST_DOMAIN].includes(domain)
    ? localhostItems
    : fetchLocalisationConfig({ brandId, onError });

  const [localisationConfig, setLocalisationConfig] =
    useState<LocalisationConfig>(initialConfig);

  useEffect(() => {
    if (storybookCountry) {
      setLocalisationConfig(localhostItems);
    }
  }, [storybookCountry]);

  return [localisationConfig, setLocalisationConfig] as [
    LocalisationConfig,
    (newConfig: LocalisationConfig) => void,
  ];
};
