/* eslint-disable no-console */
import type { Languages, Translation } from "../localisation-config/types";

const getNestedTranslation = ({
  keys,
  translationDict,
}: {
  keys: string[];
  translationDict: Translation;
}) => {
  const result = keys.reduce((obj: Translation | string, key: string) => {
    if (typeof obj === "string") {
      return obj;
    }

    if (obj === undefined) {
      return obj;
    }

    return obj[key];
  }, translationDict);

  if (typeof result === "string") {
    return result;
  }

  if (!result) {
    return undefined;
  }

  return undefined;
};

export interface TranslateOptions {
  replace?: {
    pattern: string | RegExp;
    value: string;
  };
}

export const translate = ({
  key,
  lang,
  options,
  translationDict,
  fallbackTranslationDict,
  onError = console.error,
}: {
  key: string;
  lang: Languages;
  options?: TranslateOptions;
  translationDict?: Translation;
  fallbackTranslationDict?: Translation;
  onError?: (error: string | Error) => void;
}) => {
  if (!key) {
    onError(`No key provided for language: ${lang}`);
    return "";
  }

  if (!translationDict || !fallbackTranslationDict) {
    onError(`No translation/fallback provided for language: ${lang}`);
    return key;
  }

  const keys = key.split(".");

  const mainResult = getNestedTranslation({ keys, translationDict });
  if (!mainResult) {
    onError(`No translation found for key: ${key} in lang: ${lang}`);
  }

  const fallbackResult = getNestedTranslation({
    keys,
    translationDict: fallbackTranslationDict,
  });
  if (!fallbackResult) {
    onError(`No fallback translation found for key: ${key}`);
  }

  const finalResult = mainResult || fallbackResult;

  if (finalResult && options && options.replace) {
    return finalResult.replace(options.replace.pattern, options.replace.value);
  }

  return finalResult || key;
};
