import { Fragment, useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import dynamic from "next/dynamic";
import { useThemeContext } from "@providers/ThemeProvider";
import { CenteredCircularProgress } from "@components/atoms/CenteredCircularProgress/CenteredCircularProgress";

const Loader = dynamic(
  () => import("@components/organisms/Loader").then((mod) => mod.Loader),
  {
    loading: () => <CenteredCircularProgress />,
  },
);

export const CircularLoader = ({
  loading,
  isSpinnerShown = true,
}: {
  loading: boolean;
  isSpinnerShown?: boolean;
}) => {
  const [open, setOpen] = useState(loading);

  useEffect(() => {
    setOpen(loading);

    return () => {
      setOpen(false);
    };
  }, [loading]);
  const { themeId } = useThemeContext();

  return (
    <Fragment>
      <Backdrop
        sx={(t) => ({
          color: t.palette.primary.main,
          zIndex: 100000, // loader over all else
        })}
        open={open}
        invisible={!isSpinnerShown}
      >
        {!themeId && isSpinnerShown && <CenteredCircularProgress />}
        {themeId && isSpinnerShown && <Loader isOpen={true} variant="noText" />}
      </Backdrop>
    </Fragment>
  );
};
