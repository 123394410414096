import * as marketingParams from "./marketingParams";

export const MARKETING_COOKIES = [
  marketingParams.PARAM_NAME_UTM_SOURCE,
  marketingParams.PARAM_NAME_UTM_MEDIUM,
  marketingParams.PARAM_NAME_UTM_CAMPAIGN,
  marketingParams.PARAM_NAME_UTM_CONTENT,
  marketingParams.PARAM_NAME_UTM_ID,
  marketingParams.PARAM_NAME_REFERRER,
  marketingParams.PARAM_NAME_GOOGLE_CLICK_ID,
  marketingParams.PARAM_NAME_PUBLISHER_NAME,
  marketingParams.PARAM_NAME_PUBLISHER_CLICK_ID,
  marketingParams.PARAM_NAME_PUBLISHER_SUB_PUB_ID,
  marketingParams.PARAM_NAME_QUERY_PARAMS_JSON,
] as const;
