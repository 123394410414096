import { enUS as id } from "date-fns/locale";
import { allPhonePrefixes } from "../types";
import type { LocalisationConfig } from "../types";

export const idItems: LocalisationConfig = {
  allowedLanguages: ["en", "id"],
  defaultLanguage: "id",
  hrefLang: "id",
  currency: {
    abbreviation: "IDR",
    symbol: "Rp",
    format: (amount: number) => `Rp${amount.toLocaleString()}`,
  },
  phonePrefix: allPhonePrefixes.ID,
  locale: id,
  dateInputFormat: "dd/MM/yyyy",
  country: "ID",
  countryName: "भारत",
};
