import { useEffect, useState } from "react";

export const useShouldStartLoadingGlobalScripts = () => {
  const [shouldStartLoadingScripts, setShouldStartLoadingScripts] =
    useState(false);

  useEffect(() => {
    setShouldStartLoadingScripts(true);
  }, []);

  return shouldStartLoadingScripts;
};
