import {
  isErrorOfAnyType,
  type ErrorType,
} from "@jeff/common-resources/src/integration/errors";

export const shouldReport = ({ error }: { error: Error }): boolean => {
  const ignoredErrorTypes: ErrorType[] = [
    "resize-observer-loop-error",
    "random-script-variable-not-found",
    "failed-to-fetch",
  ];
  if (
    isErrorOfAnyType({
      e: error,
      errorTypes: ignoredErrorTypes,
    })
  ) {
    return false;
  }
  const ignoredError: string[] = ["NotSupportedError"];
  if (ignoredError.includes(error.name)) {
    return false;
  }
  return true;
};
