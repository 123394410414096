import { getProfileConfig } from "@domain/profile";
import type {
  ProductConfig,
  SocialProviderNames,
} from "@domain/product-config/types";

const ENABLED_SOCIAL_PROVIDERS: SocialProviderNames[] = ["google"];

export const loanonlineConfig: ProductConfig = {
  countryCode: "PH",
  socialProviderConfig: {
    enabledSocialProviders: ENABLED_SOCIAL_PROVIDERS,
  },
  profileConfig: {
    ...getProfileConfig({ brandId: "loanonline-ph" }),
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
    isAlternativeAddressMethodsEnabled: true,
    isAlternativeIncomeMethodsEnabled: true,
    isAlternativeDobMethodsEnabled: true,
  },
  adSense: {
    enabled: true,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: false,
  },
  otp: {
    enabled: false,
  },
  captcha: {
    enabled: false,
  },
  feedback: {
    enabled: true,
  },
  topOfferAutoRedirectUserChoice: { enabled: true },
  registrationTimer: { enabled: true },
  olFeedbackPopUp: { enabled: true },
};

export const finmerkadoPhConfig: ProductConfig = {
  countryCode: "PH",
  socialProviderConfig: {
    enabledSocialProviders: ENABLED_SOCIAL_PROVIDERS,
  },
  profileConfig: {
    ...getProfileConfig({ brandId: "finmerkado-ph" }),
  },
  flowConfig: {
    isUnifiedFlowEnabled: true,
  },
  adSense: {
    enabled: false,
  },
  userFeedback: {
    canShowFeedbackInOfferCards: false,
  },
  otp: {
    enabled: false,
  },
  captcha: {
    enabled: false,
  },
  feedback: {
    enabled: true,
  },
  topOfferAutoRedirectUserChoice: { enabled: true },
  olFeedbackPopUp: {
    enabled: true,
  },
};
