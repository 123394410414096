import { useState, useEffect } from "react";
import { ExperimentContext } from "./ExperimentContext";
import type { ReactNode } from "react";
import type { ExperimentProps } from "@integration/experiments/types";

export interface ExperimentContextProps {
  leadId?: string;
  children: ReactNode;
  experimentProps?: ExperimentProps;
}

export const ExperimentProvider = ({
  children,
  leadId,
  experimentProps,
}: ExperimentContextProps) => {
  const [experiments, setExperiments] = useState(experimentProps);

  useEffect(() => {
    if (!experiments && experimentProps) {
      setExperiments(experimentProps);
    }
  }, [experimentProps, experiments]);

  return (
    <ExperimentContext.Provider
      value={{
        leadId,
        experiments,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};
