import { COOKIE_NAME_LEAD_ID } from "@integration/cookies/cookie-names";
import { getCookieAsString } from "@integration/cookies/getCookie";
import { isLeadIdValid } from "./isLeadIdValid";
import type { OptionsType } from "cookies-next/lib/types";

export const getStoredLeadId = (options?: OptionsType) => {
  const leadId = getCookieAsString(COOKIE_NAME_LEAD_ID, options);
  if (!leadId || !isLeadIdValid({ leadId })) {
    return;
  }
  return leadId;
};
