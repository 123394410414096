import { getDefaultCookieOptions } from "./getDefaultCookieOptions";
import type { OptionsType } from "cookies-next/lib/types";

export const createCookieOptions = (options?: OptionsType): OptionsType => {
  const cookieOptions: OptionsType = options
    ? { ...getDefaultCookieOptions(options), ...options }
    : getDefaultCookieOptions();

  return cookieOptions;
};
