import { urlParamNames } from "./types";
import type { UrlParams, ParsedQueryParams } from "./types";

export const filterQueryParams = (params: ParsedQueryParams) => {
  const filteredParams: UrlParams = {};
  for (const key of urlParamNames) {
    const paramVal = params?.[key];
    if (paramVal) {
      filteredParams[key] = paramVal?.toString();
    }
  }
  return filteredParams;
};
