import { useEffect, useState } from "react";
import { onError } from "@integration/bugsnag/csr";
import type { ExperimentProps } from "../types";

export const useEnabledExperiments = ({
  leadId,
  brandId,
}: {
  leadId?: string;
  brandId: string;
}) => {
  const [experiments, setExperiments] = useState<ExperimentProps>();
  useEffect(() => {
    async function fetchExperiments() {
      const getEnabledExperiments = (await import("./getEnabledExperiments"))
        .getEnabledExperiments;
      const fetched = await getEnabledExperiments({
        overrides: { leadId, brandId },
      });
      setExperiments(fetched);
    }
    fetchExperiments().catch(onError);
  }, [leadId, brandId]);

  return experiments;
};
