import { type NextApiRequest } from "next";
import { z } from "zod";
import { serverSideEnvVariable } from "@server-side-env";

export const isRunningOnVercel = () => {
  const env = serverSideEnvVariable("VERCEL_ENV");
  return env !== undefined && env.length > 0;
};

export const isCronRequestAllowed = ({ req }: { req: NextApiRequest }) => {
  const secret = serverSideEnvVariable("CRON_SECRET");
  if (!secret) {
    return false;
  }
  const findKey = () => {
    const parsedHeaders = z
      .object({
        Authorization: z.string(),
      })
      .safeParse(req.headers);
    if (parsedHeaders.success) {
      return parsedHeaders.data.Authorization;
    }
    const parsedQueryParams = z
      .object({
        key: z.string(),
      })
      .safeParse(req.query);
    if (parsedQueryParams.success) {
      return parsedQueryParams.data.key;
    }
    return null;
  };
  const key = findKey();
  return key === secret;
};
