type ClientSideEnvKey =
  | "GG_APP_ID"
  | "FB_APP_ID"
  | "COMMIT_REF"
  | "ENVIRONMENT"
  | "GTM_ENABLED"
  | "NEXT_APP_URL"
  | "DEV_ENV"
  | "CLARITY_IDS_BY_HOST"
  | "ZALO_DATA_OAID"
  | "CIO_SITE_ID"
  | "VERCEL_ENV"
  | "CIO_SITE_IDS_BY_HOST"
  | "GOOGLE_GEOCODING_API_KEY";

type NextEnvValueOrKey =
  | "${NEXT_PUBLIC_GG_APP_ID}"
  | "${NEXT_PUBLIC_FB_APP_ID}"
  | "${NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}"
  | "${NEXT_PUBLIC_ENVIRONMENT}"
  | "${NEXT_PUBLIC_GTM_ENABLED}"
  | "${NEXT_PUBLIC_NEXT_APP_URL}"
  | "${NEXT_PUBLIC_DEV_ENV}"
  | "${NEXT_PUBLIC_CLARITY_IDS_BY_HOST}"
  | "${NEXT_PUBLIC_ZALO_DATA_OAID}"
  | "${NEXT_PUBLIC_CIO_SITE_ID}"
  | "${NEXT_PUBLIC_VERCEL_ENV}"
  | "${NEXT_PUBLIC_CIO_SITE_IDS_BY_HOST}"
  | "${NEXT_PUBLIC_GOOGLE_GEOCODING_API_KEY}";

interface VariableEntry {
  key: ClientSideEnvKey;
  env?: NextEnvValueOrKey;
  defaultValue?: string | undefined;
}

const valueOrEnv = (
  v: NextEnvValueOrKey,
  defaultValue: string | undefined,
): string | undefined => {
  if (!v.startsWith("${")) {
    return v;
  }
  const name = v.substring(2, v.length - 1);
  return process.env[name] || defaultValue;
};

const entries: VariableEntry[] = [
  {
    key: "GG_APP_ID",
    env: "${NEXT_PUBLIC_GG_APP_ID}",
    defaultValue: process.env.NEXT_PUBLIC_GG_APP_ID,
  },
  {
    key: "FB_APP_ID",
    env: "${NEXT_PUBLIC_FB_APP_ID}",
    defaultValue: process.env.NEXT_PUBLIC_FB_APP_ID,
  },
  {
    key: "COMMIT_REF",
    env: "${NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA}",
    defaultValue: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "unknown",
  },
  {
    key: "ENVIRONMENT",
    env: "${NEXT_PUBLIC_ENVIRONMENT}",
    defaultValue: process.env.NEXT_PUBLIC_ENVIRONMENT || "development",
  },
  {
    key: "GTM_ENABLED",
    env: "${NEXT_PUBLIC_GTM_ENABLED}",
    defaultValue: process.env.NEXT_PUBLIC_GTM_ENABLED || "true",
  },
  {
    key: "NEXT_APP_URL",
    env: "${NEXT_PUBLIC_NEXT_APP_URL}",
    defaultValue: process.env.NEXT_PUBLIC_NEXT_APP_URL || "https://app.jeff.vn",
  },
  {
    key: "DEV_ENV",
    env: "${NEXT_PUBLIC_DEV_ENV}",
    defaultValue: process.env.NEXT_PUBLIC_DEV_ENV || "false",
  },
  {
    key: "CLARITY_IDS_BY_HOST",
    env: "${NEXT_PUBLIC_CLARITY_IDS_BY_HOST}",
    defaultValue: process.env.NEXT_PUBLIC_CLARITY_IDS_BY_HOST,
  },
  {
    key: "ZALO_DATA_OAID",
    env: "${NEXT_PUBLIC_ZALO_DATA_OAID}",
    defaultValue: process.env.NEXT_PUBLIC_ZALO_DATA_OAID,
  },
  {
    key: "CIO_SITE_ID",
    env: "${NEXT_PUBLIC_CIO_SITE_ID}",
    defaultValue: process.env.NEXT_PUBLIC_CIO_SITE_ID,
  },
  {
    key: "VERCEL_ENV",
    env: "${NEXT_PUBLIC_VERCEL_ENV}",
    defaultValue: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
  {
    key: "CIO_SITE_IDS_BY_HOST",
    env: "${NEXT_PUBLIC_CIO_SITE_IDS_BY_HOST}",
    defaultValue: process.env.NEXT_PUBLIC_CIO_SITE_IDS_BY_HOST,
  },
  {
    key: "GOOGLE_GEOCODING_API_KEY",
    env: "${NEXT_PUBLIC_GOOGLE_GEOCODING_API_KEY}",
    defaultValue: process.env.NEXT_PUBLIC_GOOGLE_GEOCODING_API_KEY,
  },
];

export const clientSideEnvVariable = (key: ClientSideEnvKey): string => {
  const entry = entries.find((it) => it.key === key);
  if (!entry) {
    throw new Error(`Could not get entry for env variable ${key}`);
  }
  const { env: v, defaultValue } = entry;
  if (!v) {
    return defaultValue || "";
  }
  return valueOrEnv(v, defaultValue) || "";
};

export const isDevEnv = (): boolean => {
  return clientSideEnvVariable("DEV_ENV") === "true";
};
