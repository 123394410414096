import { branding as brandingConfig } from "@domain/branding/common/branding";
import { getHumanReadableLangFromLang } from "@domain/localisation/common/getHumanReadableLangFromLang";
import { onError } from "@integration/bugsnag/csr";
import { CenteredCircularProgress } from "@components/atoms/CenteredCircularProgress/CenteredCircularProgress";
import { getBrandingFromDomain } from "@domain/branding/common/getBrandingFromDomain";
import { useSetMobileAppLanguage } from "@integration/mobile-app/useSetMobileAppLanguage";
import { LocalisationContext } from "./context";
import { useLocalisationConfigState } from "./hooks/useLocalisationConfigState";
import { useLangState } from "./hooks/useLangState";
import { useTranslate } from "./hooks/useTranslate";
import { useTranslations } from "./hooks/useTranslations";
import type { LocalisationProviderProps } from "./LocalisationProvider.types";

export const LocalisationProvider = ({
  children,
  storybookCountry,
  storybookLang,
  domainName,
  browserLanguages,
  preferredLang,
  initialFallbackTranslations,
  initialTranslations,
}: LocalisationProviderProps) => {
  const branding = getBrandingFromDomain(domainName) || brandingConfig;
  const { brandId, brandName } = branding;

  const domain = domainName || branding.brandDomain;

  const [localisationConfig] = useLocalisationConfigState({
    domain,
    brandId,
    storybookCountry,
  });

  const [currentLang, setCurrentLang] = useLangState({
    brandId,
    browserLanguages,
    preferredLang,
    localisationConfig,
    storybookLang,
  });

  const [translationDict, fallbackTranslationDict] = useTranslations({
    lang: currentLang,
    initialFallbackTranslations,
    initialTranslations,
  });

  const translate = useTranslate({
    brandId,
    brandName,
    fallbackTranslationDict,
    lang: currentLang,
    translationDict,
  });

  useSetMobileAppLanguage(setCurrentLang);

  return (
    <LocalisationContext.Provider
      value={{
        currentLang,
        setCurrentLang,
        allowedLanguages: localisationConfig.allowedLanguages,
        allowedHumanReadableLanguages: localisationConfig.allowedLanguages.map(
          (lang) =>
            getHumanReadableLangFromLang({
              lang,
              onError,
            }),
        ),
        humanReadableLang: getHumanReadableLangFromLang({
          lang: currentLang,
          onError,
        }),
        country: localisationConfig.country,
        currency: localisationConfig.currency,
        hrefLang: localisationConfig.hrefLang,
        dateInputFormat: localisationConfig.dateInputFormat,
        locale: localisationConfig.locale,
        phonePrefix: localisationConfig.phonePrefix,
        countryName: localisationConfig.countryName,
        t: translate,
      }}
    >
      {!translationDict ? <CenteredCircularProgress /> : children}
    </LocalisationContext.Provider>
  );
};
