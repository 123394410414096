import { type ProductConfig } from "@domain/product-config/types";
import { REGISTRATION_TIMER_EXP_ID } from "@integration/experiments/constants";
import { type ExperimentProps } from "@integration/experiments/types";

export const getApplicationFlowTimerExpEnabled = (
  experiments: ExperimentProps | undefined,
  productConfig: ProductConfig,
): boolean => {
  if (!experiments) {
    return false;
  }

  if (productConfig.registrationTimer?.enabled) {
    return experiments.enabled[REGISTRATION_TIMER_EXP_ID];
  }

  return false;
};
