/* eslint-disable no-console */
import {
  adPlacement,
  emptyAdPlacementConfig,
  maxAdPlacementConfig,
  maxGPTPlacementConfig,
} from "./config";
import { findApplicableExperiment } from "./rules";
import { type Rule } from "./rules/types";
import { rules as finmercadoMX } from "./sites/finmercado-mx";
import { rules as jeffVN } from "./sites/jeff-vn";
import { rules as loanonlinePH } from "./sites/loanonline-ph";
import { rules as finmartVN } from "./sites/finmart-vn";
import {
  type AdSenseStatus,
  type GetAdSenseStatusArgs,
  SiteIdSchema,
  AdSenseApproach,
  type SiteId,
} from "./types";
import { experimentDetails } from "./experiments";
import { getAdsProduct } from "./getAdsProducts";

const findSiteRules = ({ siteId }: { siteId: SiteId }): Rule[] => {
  switch (siteId) {
    case "loanonline-ph":
      return loanonlinePH;
    case "jeff-vn":
      return jeffVN;
    case "finmercado-mx":
      return finmercadoMX;
    case "finmart-vn":
      return finmartVN;
  }
};

const evaluate = ({
  siteId,
  input,
  rules,
}: {
  siteId: SiteId;
  input: GetAdSenseStatusArgs;
  rules: Rule[];
}): AdSenseStatus => {
  const experimentId = findApplicableExperiment({ rules, input });

  if (!experimentId) {
    return {
      show: false,
      adsProduct: "adsense",
      segmentName: "no-experiment",
      approach: AdSenseApproach.ExperimentBased,
      placements: emptyAdPlacementConfig(),
      experimentId: null,
      experimentVariant: null,
    };
  }
  const details = experimentDetails({
    experimentId,
    experiments: input.experiments,
  });

  const adsPlacements = adPlacement({
    siteId,
    toggleId: experimentId,
    experimentVariant: details.experimentVariant ?? undefined,
  });

  const adsProduct = getAdsProduct(adsPlacements);

  return {
    ...details,
    adsProduct,
    segmentName: "rule-based",
    approach: AdSenseApproach.ExperimentBased,
    placements: adsPlacements,
  };
};

export const findAdsStatus = (
  args: {
    siteId: string;
    onError?: (error: Error | string) => void;
  } & GetAdSenseStatusArgs,
): AdSenseStatus | null => {
  const onError = args?.onError || console.error;
  try {
    const result = SiteIdSchema.safeParse(args.siteId);
    if (!result.success) {
      return null;
    }
    const { config, forced, forcedGpt } = args;
    if (!config.enabled) {
      return {
        show: false,
        segmentName: "disabled",
        approach: AdSenseApproach.Disabled,
        placements: emptyAdPlacementConfig(),
        experimentId: null,
        experimentVariant: null,
      };
    }
    const siteId = result.data;
    if (forced) {
      return {
        show: true,
        adsProduct: "adsense",
        approach: AdSenseApproach.Forced,
        segmentName: "forced",
        placements: maxAdPlacementConfig({ siteId }),
        experimentId: null,
        experimentVariant: null,
      };
    }
    if (forcedGpt) {
      return {
        show: true,
        adsProduct: "gpt",
        approach: AdSenseApproach.Forced,
        segmentName: "forced",
        placements: maxGPTPlacementConfig({ siteId }),
        experimentId: null,
        experimentVariant: null,
      };
    }
    const evaluated = evaluate({
      siteId,
      input: args,
      rules: findSiteRules({ siteId }),
    });
    return evaluated;
  } catch (e) {
    onError(e as Error);
    return null;
  }
};
