export const ROBOCASH_VN_PARTNER_OFFER_ID = "robocash-vn";
export const ROBOCASH_CPS_VN_PARTNER_OFFER_ID = "robocash-cps-vn";
export const MONEYCAT_VN_PARTNER_OFFER_ID = "moneycat-vn";
export const MONEYCAT_CPS_VN_PARTNER_OFFER_ID = "moneycat-cps-vn";
export const MONEYCAT_MX_PARTNER_OFFER_ID = "moneycat-mx";
export const TAMO_VN_PARTNER_OFFER_ID = "tamo-vn";
export const TAMO_CPS_VN_PARTNER_OFFER_ID = "tamo-cps-vn";
export const ON_CREDIT_VN_PARTNER_OFFER_ID = "on-credit-vn";
export const ONCREDIT_VN_PARTNER_OFFER_ID = "oncredit-vn";
export const DOCTOR_DONG_VN_PARTNER_OFFER_ID = "doctor-dong-vn";
export const DOCTORDONG_VN_PARTNER_OFFER_ID = "doctordong-vn";
export const FINDO_VN_PARTNER_OFFER_ID = "findo-vn";
export const SENMO_VN_PARTNER_OFFER_ID = "senmo-vn";
export const VAYVND_VN_PARTNER_OFFER_ID = "vayvnd-vn";
export const TIEN_OI_VN_PARTNER_OFFER_ID = "tien-oi-vn";
export const TIEN_VN_PARTNER_OFFER_ID = "tien-vn";
export const ATM_ONLINE_VN_PARTNER_OFFER_ID = "atm-online-vn";
export const ATM_ONLINE_CPS_VN_PARTNER_OFFER_ID = "atm-online-cps-vn";
export const VAMO_PARTNER_OFFER_ID = "vamo-vn";
export const TAKOMO_PARTNER_OFFER_ID = "takomo-vn";
export const TAKOMO_CPS_PARTNER_OFFER_ID = "takomo-cps-vn";
export const DIGIDO_PH_PARTNER_OFFER_ID = "digido-ph";
export const DIGIDO_DIRECT_PH_PARTNER_OFFER_ID = "digido-direct-ph";
export const MONEYCAT_PH_PARTNER_OFFER_ID = "moneycat-ph";
export const MONEYCAT_CPS_PH_PARTNER_OFFER_ID = "moneycat-cps-ph";
export const OLP_DIRECT_PH_PARTNER_OFFER_ID = "olp-direct-ph";
export const FINBRO_PH_PARTNER_OFFER_ID = "finbro-ph";
export const CASHXPRESS_PH_PARTNER_OFFER_ID = "cashxpress-ph";
export const KVIKU_DIRECT_PH_PARTNER_OFFER_ID = "kviku-direct-ph";
export const VAMO_PH_PARTNER_OFFER_ID = "vamo-ph";
export const ON_CREDIT_LK_PARTNER_OFFER_ID = "on-credit-lk";
export const LOANME_LK_PARTNER_OFFER_ID = "loanme-lk";
export const LOTUS_LOAN_LK_PARTNER_OFFER_ID = "lotus-loan-lk";
export const ROBOCASH_LK_PARTNER_OFFER_ID = "robocash-lk";
export const FASTRUPEE_LK_PARTNER_OFFER_ID = "fastrupee-lk";
export const CASHX_LK_PARTNER_OFFER_ID = "cashx-lk";
export const MAYBANK_ID_PARTNER_OFFER_ID = "maybank-id";
export const COZMO_CPS_VN_PARTNER_OFFER_ID = "cozmo-cps-vn";
export const COZMO_VN_PARTNER_OFFER_ID = "cozmo-vn";
export const VPBANK_SECURITIES_TRADING_PARTNER_OFFER_ID =
  "vpbank-securities-trading-vn";
export const MONEYVEO_VN_PARTNER_OFFER_ID = "moneyveo-vn";
export const CASHEXPRESS_PH_PARTNER_OFFER_ID = "cashexpress-ph";
export const PESO_REDEE_PH_PARTNER_OFFER_ID = "peso-redee-ph";
export const DONG_PLUS_VN_PARTNER_OFFER_ID = "dong-plus-vn";
export const HDBANK_VN_PARTNER_OFFER_ID = "hdbank-petrolimex-4in1-vn";
export const HDBANK_VIETJET_PLATINUM_VN_PARTNER_OFFER_ID =
  "hdbank-vietjet-platinum-vn";
export const CREDITO_365_MX_PARTNER_OFFER_ID = "credito365-mx";
export const DINERIA_MX_PARTNER_OFFER_ID = "dineria-mx";
export const LENDSWAP_MX_PARTNER_OFFER_ID = "lendswap-mx";
export const KIMBI_MX_PARTNER_OFFER_ID = "kimbi-mx";
export const MONEYMAN_MX_PARTNER_OFFER_ID = "money-man-mx";
export const VIVUS_MX_PARTNER_OFFER_ID = "vivus-mx";
export const COZMO_CPL_MX_PARTNER_OFFER_ID = "cozmo-cpl-mx";
export const LENDON_MX_PARTNER_OFFER_ID = "lendon-mx";
export const PRESTOMIN_MX_PARRTNER_OFFER_ID = "prestomin-mx";

export const ALL_PARTNER_OFFER_IDS = [
  ATM_ONLINE_CPS_VN_PARTNER_OFFER_ID,
  ATM_ONLINE_VN_PARTNER_OFFER_ID,
  CASHX_LK_PARTNER_OFFER_ID,
  CASHXPRESS_PH_PARTNER_OFFER_ID,
  DIGIDO_DIRECT_PH_PARTNER_OFFER_ID,
  DIGIDO_PH_PARTNER_OFFER_ID,
  DOCTOR_DONG_VN_PARTNER_OFFER_ID,
  DOCTORDONG_VN_PARTNER_OFFER_ID,
  FASTRUPEE_LK_PARTNER_OFFER_ID,
  FINBRO_PH_PARTNER_OFFER_ID,
  FINDO_VN_PARTNER_OFFER_ID,
  KVIKU_DIRECT_PH_PARTNER_OFFER_ID,
  LOANME_LK_PARTNER_OFFER_ID,
  LOTUS_LOAN_LK_PARTNER_OFFER_ID,
  MONEYCAT_CPS_PH_PARTNER_OFFER_ID,
  MONEYCAT_CPS_VN_PARTNER_OFFER_ID,
  MONEYCAT_PH_PARTNER_OFFER_ID,
  MONEYCAT_VN_PARTNER_OFFER_ID,
  MONEYCAT_MX_PARTNER_OFFER_ID,
  OLP_DIRECT_PH_PARTNER_OFFER_ID,
  ON_CREDIT_LK_PARTNER_OFFER_ID,
  ON_CREDIT_VN_PARTNER_OFFER_ID,
  ON_CREDIT_VN_PARTNER_OFFER_ID,
  ONCREDIT_VN_PARTNER_OFFER_ID,
  ROBOCASH_CPS_VN_PARTNER_OFFER_ID,
  ROBOCASH_LK_PARTNER_OFFER_ID,
  ROBOCASH_VN_PARTNER_OFFER_ID,
  SENMO_VN_PARTNER_OFFER_ID,
  TAKOMO_PARTNER_OFFER_ID,
  TAKOMO_CPS_PARTNER_OFFER_ID,
  TAMO_CPS_VN_PARTNER_OFFER_ID,
  TAMO_VN_PARTNER_OFFER_ID,
  TIEN_OI_VN_PARTNER_OFFER_ID,
  TIEN_VN_PARTNER_OFFER_ID,
  VAMO_PARTNER_OFFER_ID,
  VAMO_PH_PARTNER_OFFER_ID,
  VAYVND_VN_PARTNER_OFFER_ID,
  MAYBANK_ID_PARTNER_OFFER_ID,
  COZMO_CPS_VN_PARTNER_OFFER_ID,
  COZMO_VN_PARTNER_OFFER_ID,
  VPBANK_SECURITIES_TRADING_PARTNER_OFFER_ID,
  MONEYVEO_VN_PARTNER_OFFER_ID,
  CASHEXPRESS_PH_PARTNER_OFFER_ID,
  PESO_REDEE_PH_PARTNER_OFFER_ID,
  DONG_PLUS_VN_PARTNER_OFFER_ID,
  HDBANK_VN_PARTNER_OFFER_ID,
  HDBANK_VIETJET_PLATINUM_VN_PARTNER_OFFER_ID,
  CREDITO_365_MX_PARTNER_OFFER_ID,
  DINERIA_MX_PARTNER_OFFER_ID,
  LENDSWAP_MX_PARTNER_OFFER_ID,
  KIMBI_MX_PARTNER_OFFER_ID,
  MONEYMAN_MX_PARTNER_OFFER_ID,
  VIVUS_MX_PARTNER_OFFER_ID,
  COZMO_CPL_MX_PARTNER_OFFER_ID,
  LENDON_MX_PARTNER_OFFER_ID,
  PRESTOMIN_MX_PARRTNER_OFFER_ID,
] as const;
