import { createContext, useContext } from "react";
import { type QualityScore, type ProductType } from "@jeff/lead-api";
import {
  type AdPlacement,
  type AdPlacementConfig,
} from "@integration/adsense/types";
import { type LeadServicesInfo } from "@domain/leads/ssr/fetchLeadById";
import { type AgeGroup } from "../utils/ageGroup";

export type AdsMetadata = {
  ageGroup?: AgeGroup;
  city?: string;
  gender?: string;
  offerCount?: number;
  experiment?: string;
  deviceType?: string;
  deviceBrand?: string;
  leadScore?: string;
  qualityScore?: string;
};

export const AdsGlobalContext = createContext<AdsContextProps>({
  isAdSenseReady: false,
  isGPTReady: false,
  placements: new Map<AdPlacement, string>(),
  adSenseClientId: "",
  adsMetadata: {},
  onAdSlotStatusChange: () => {},
  initAds: () => null,
});

export interface AdsContextProps {
  /**
   * If ad sense script is loaded.
   */
  isAdSenseReady: boolean;

  /**
   * If GPT script is loaded.
   */
  isGPTReady: boolean;

  adSenseClientId?: string;

  /**
   * Ad sense placements that are enabled.
   */
  placements: AdPlacementConfig;

  adsMetadata: AdsMetadata;

  onAdSlotStatusChange: (args: {
    adProvider: string;
    slotId: string;
    status: string;
    offerListingId?: string;
  }) => void;
  initAds: (args: {
    offerListing?: {
      id: string;
      count: number;
      products: {
        productType: ProductType;
        count: number;
      }[];
      offers: {
        id: string;
      }[];
    };
    lead?: LeadServicesInfo | null;
    qualityScore?: QualityScore;
  }) => void;
}

export const useAdsContext = () => useContext(AdsGlobalContext);
