import { ALL_PARTNER_OFFER_IDS } from "./constants";
import type { PartnerOfferId } from "./types";

export const asPartnerOfferId = ({
  input,
}: {
  input?: string;
}): PartnerOfferId | undefined => {
  if (!input) {
    return;
  }
  if (ALL_PARTNER_OFFER_IDS.includes(input as PartnerOfferId)) {
    return input as PartnerOfferId;
  }
};
