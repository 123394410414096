import { createExperimentAdPlacementConfig } from "@integration/adsense/createExperimentAdPlacementConfig";
import { type AdSenseExperimentConfig } from "../../types";

export const config: AdSenseExperimentConfig = {
  siteId: "jeff-vn",
  clientId: "ca-pub-2865748719827515",
  placementConfigs: [
    createExperimentAdPlacementConfig({
      configId: "vn-exp3-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "1853359605", placement: "mid" },
        { id: "4263900525", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp3-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp3-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp3-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp0-test-group1",
      siteId: "jeff-vn",
      adUnits: [{ id: "5326688147", placement: "bottom" }],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp0-test-group3",
      siteId: "jeff-vn",
      adUnits: [{ id: "vn-exp0-test-group3-bottom", placement: "gpt-bottom" }],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp1-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "8785887470", placement: "top" },
        { id: "5841542821", placement: "mid" },
        { id: "4720032845", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp1-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp1-test-group3-top", placement: "gpt-top" },
        { id: "vn-exp1-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp1-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp4-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "2054105625", placement: "top" },
        { id: "9176232962", placement: "mid" },
        { id: "9917117929", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp4-test-group2",
      siteId: "jeff-vn",
      adUnits: [
        { id: "9255289864", placement: "mid" },
        { id: "2038627905", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp4-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp4-test-group3-top", placement: "gpt-top" },
        { id: "vn-exp4-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp4-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp5-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "6721513913", placement: "top" },
        { id: "2782268902", placement: "mid" },
        { id: "9849405989", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp5-test-group2",
      siteId: "jeff-vn",
      adUnits: [
        { id: "8220892872", placement: "mid" },
        { id: "7843023890", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp5-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp5-test-group3-top", placement: "gpt-top" },
        { id: "vn-exp5-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp5-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp6-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "5216860551", placement: "top" },
        { id: "5316044856", placement: "mid" },
        { id: "8536324315", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp6-test-group2",
      siteId: "jeff-vn",
      adUnits: [
        { id: "6716239510", placement: "mid" },
        { id: "5403157846", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp6-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp6-test-group3-top", placement: "gpt-top" },
        { id: "vn-exp6-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp6-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp7-test-group1",
      siteId: "jeff-vn",
      adUnits: [
        { id: "3903778883", placement: "top" },
        { id: "2689881514", placement: "mid" },
        { id: "5211586156", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp7-test-group2",
      siteId: "jeff-vn",
      adUnits: [
        { id: "1272341145", placement: "mid" },
        { id: "1277615540", placement: "bottom" },
      ],
    }),
    createExperimentAdPlacementConfig({
      configId: "vn-exp7-test-group3",
      siteId: "jeff-vn",
      adUnits: [
        { id: "vn-exp7-test-group3-top", placement: "gpt-top" },
        { id: "vn-exp7-test-group3-mid", placement: "gpt-mid" },
        { id: "vn-exp7-test-group3-bottom", placement: "gpt-bottom" },
      ],
    }),
    {
      id: "vn-exp9",
      toggleId: "adsense-vn-exp9",
      siteId: "jeff-vn",
      adUnits: [
        { id: "6373130131", name: "vn-exp9-top", placement: "top" },
        { id: "6811596914", name: "vn-exp9-mid", placement: "mid" },
        { id: "2872351900", name: "vn-exp9-bottom", placement: "bottom" },
      ],
    },
    {
      id: "vn-exp10",
      toggleId: "adsense-vn-exp10",
      siteId: "jeff-vn",
      adUnits: [
        { id: "3993861883", name: "vn-exp10-top", placement: "top" },
        { id: "5115371869", name: "vn-exp10-mid", placement: "mid" },
        { id: "4291893714", name: "vn-exp10-bottom", placement: "bottom" },
      ],
    },
    {
      id: "vn-exp11",
      toggleId: "adsense-vn-exp11",
      siteId: "jeff-vn",
      adUnits: [
        { id: "7011747128", name: "vn-exp11-top", placement: "top" },
        { id: "3878488760", name: "vn-exp11-mid", placement: "mid" },
        { id: "2565407092", name: "vn-exp11-bottom", placement: "bottom" },
      ],
    },
  ],
};
