import type { trackBrowserData } from "@jeff/web-analytics/snowplow";
import type { fetchBrowserData } from "./fetchBrowserData";

type BrowserData = Awaited<ReturnType<typeof fetchBrowserData>>;
type EventParams = Parameters<typeof trackBrowserData>[0];

export const flattenBrowserData = (data: BrowserData): EventParams => {
  return {
    browserHistoryLength: data?.browser?.historyLength,
    browserName: data?.browser?.name,
    browserVersion: data?.browser?.version,
    cpuArchitecture: data?.cpu?.architecture,
    cpuCores: data?.cpu?.cores,
    cpuPlatform: data?.cpu?.platform,
    cpuVendor: data?.cpu?.vendor,
    engineName: data?.engine?.name,
    engineVersion: data?.engine?.version,
    absoluteOrientationSensor: data?.features?.absoluteOrientationSensor,
    accelerometer: data?.features?.accelerometer,
    addEventListener: data?.features?.addEventListener,
    ambientLightSensor: data?.features?.ambientLightSensor,
    asyncScript: data?.features?.asyncScript,
    audioContext: data?.features?.audio?.context,
    audioSupported: data?.features?.audio?.supported,
    audioFormat_m4a: data?.features?.audio?.formats?.m4a,
    audioFormat_mp3: data?.features?.audio?.formats?.mp3,
    audioFormat_ogg: data?.features?.audio?.formats?.ogg,
    audioFormat_opus: data?.features?.audio?.formats?.opus,
    audioFormat_wav: data?.features?.audio?.formats?.wav,
    audioFormat_webm: data?.features?.audio?.formats?.webm,
    battery: data?.features?.battery,
    canvas: data?.features?.canvas,
    classList: data?.features?.classList,
    contextMenu: data?.features?.contextMenu,
    cookieEnabled: data?.features?.cookieEnabled,
    cors: data?.features?.cors,
    css3D: data?.features?.css3D,
    cssTransform: data?.features?.cssTransform,
    cssTransition: data?.features?.cssTransition,
    deferScript: data?.features?.deferScript,
    deviceMotion: data?.features?.deviceMotion,
    deviceOrientation: data?.features?.deviceOrientation,
    deviceRelativeOrientation: data?.features?.deviceRelativeOrientation,
    dialogElement: data?.features?.dialogElement,
    doNotTrack: !!data?.features?.doNotTrack ?? null,
    file: data?.features?.file,
    fileSystem: data?.features?.fileSystem,
    forceTouch: data?.features?.forceTouch,
    fullscreen: data?.features?.fullscreen,
    fullscreenKeyboard: data?.features?.fullscreenKeyboard,
    geolocation: data?.features?.geolocation,
    gravitySensor: data?.features?.gravitySensor,
    gyroscope: data?.features?.gyroscope,
    hasAdsBlocked: data?.features?.hasAdsBlocked,
    historyAPI: data?.features?.historyAPI,
    linearAccelerationSensor: data?.features?.linearAccelerationSensor,
    littleEndian: data?.features?.littleEndian,
    localStorage: data?.features?.localStorage,
    magnetometer: data?.features?.magnetometer,
    matchMedia: data?.features?.matchMedia,
    pdfViewerEnabled: data?.features?.pdfViewerEnabled,
    pictureElement: data?.features?.pictureElement,
    pixelRatio: data?.features?.pixelRatio,
    placeholder: data?.features?.placeholder,
    pointerEnabled: data?.features?.pointerEnabled,
    pointerEvents: data?.features?.pointerEvents,
    pointerLock: data?.features?.pointerLock,
    querySelector: data?.features?.querySelector,
    querySelectorAll: data?.features?.querySelectorAll,
    quirksMode: data?.features?.quirksMode,
    relativeOrientationSensor: data?.features?.relativeOrientationSensor,
    remUnit: data?.features?.remUnit,
    serviceWorker: data?.features?.serviceWorker,
    sizes: data?.features?.sizes,
    srcset: data?.features?.srcset,
    svg: data?.features?.svg,
    touch: data?.features?.touch,
    typedArray: data?.features?.typedArray,
    userMedia: data?.features?.userMedia,
    vibration: data?.features?.vibration,
    videoFormat_mp4: data?.features?.video?.formats?.mp4,
    videoFormat_ogv: data?.features?.video?.formats?.ogv,
    videoFormat_webm: data?.features?.video?.formats?.webm,
    videoSupported: data?.features?.video?.supported,
    viewportUnit: data?.features?.viewportUnit,
    webGL: data?.features?.webGL,
    webVR: data?.features?.webVR,
    webdriver: data?.features?.webdriver,
    webp: data?.features?.webp,
    worker: data?.features?.worker,
    availableFonts: data?.fonts?.available,
    loadedFonts: data?.fonts?.loaded,
    deviceMemory: data?.hardware?.deviceMemory,
    deviceModel: data?.hardware?.deviceModel,
    deviceVendor: data?.hardware?.deviceVendor,
    deviceType: data?.hardware?.deviceType,
    hardwareConcurrency: data?.hardware?.hardwareConcurrency,
    language: data?.languages?.language,
    languages: data?.languages?.languages,
    downlink: data?.network?.downlink,
    downlinkMax: data?.network?.downlinkMax,
    effectiveConnectionType: data?.network?.effectiveType,
    rtt: data?.network?.rtt,
    saveData: data?.network?.saveData,
    connectionType: data?.network?.type,
    osName: data?.os?.name,
    osVersion: data?.os?.version,
    osVersionName: data?.os?.versionName,
    platform: data?.platform,
    availableScreenHeight: data?.screen?.availableHeight,
    availableScreenWidth: data?.screen?.availableWidth,
    nativeScreenHeight: data?.screen?.nativeHeight,
    nativeScreenWidth: data?.screen?.nativeWidth,
    screenHeight: data?.screen?.height,
    screenWidth: data?.screen?.width,
    firstPaint: data?.performance?.firstPaint,
    firstContentfulPaint: data?.performance?.firstContentfulPaint,
    largestContentfulPaint: data?.performance?.largestContentfulPaint,
    timeToFirstByte: data?.performance?.timeToFirstByte,
    nextjsHydration: data?.performance?.nextjsHydration,
    nextjsBeforeHydration: data?.performance?.nextjsBeforeHydration,
  };
};
