import { type IncomingHttpHeaders } from "http";
import {
  requireClientIpAddress as _requireClientIpAddress,
  requireHostname as _requireHostname,
} from "./core";
import { type RequestAware } from "./types";

type Request = { headers: IncomingHttpHeaders };

export const requireClientIpAddress = ({
  req,
}: RequestAware<Request>): string => {
  return _requireClientIpAddress({ req });
};

export const requireHostname = ({ req }: RequestAware<Request>): string => {
  return _requireHostname({ req });
};
