import { type Event } from "@bugsnag/core/types";

const TYPICAL_NOISE = [
  "Unexpected token u in JSON",
  "Unexpected token '<'",
  "zaloJSV2",
  "runCustomize is not defined",
  "isReCreate is not defined",
  "Failed to fetch",
  "registerMyClickListener is not defined",
  "Unexpected end of script",
  "Invalid or unexpected token",
  "Unexpected token )",
  "Unexpected EOF",
  "missing ) after argument list",
  "processRandomSelector is not defined",
].map((s) => s.toLowerCase());

/**
 * In BugSnag errors are grouped in two categories - handled and unhandled.
 *
 * Handled - we caught the exception and reported it to BugSnag properly
 * Unhandled - the exception was not caught (occured somewhere out of the React boundaries, not in the try-catch block, there was an unhandled promise rejection, etc.)
 *
 * Mostly all of the places are covered at the moment but still sometimes these errors occur, main reasons:
 * - internet problems / unfinished requests etc.
 * - some additional scripts that are loaded by browser in client side (Zalo browser, plugins, etc.)
 * - some parsing errors (unexpected token, etc.)
 *
 * Most of them can be considered as noise and we don't want to report them to BugSnag.
 *
 * @returns {boolean} - true if the error should be reported to BugSnag, false otherwise
 */
export const isReportable = ({ event }: { event: Event }): boolean => {
  // eslint-disable-next-line no-console
  console.log(JSON.stringify(event, null, 2));
  // we want to report all the caught errors
  if (!event.unhandled) {
    return true;
  }
  const e = event.errors.find((e) => !!e.errorMessage);
  if (!e) {
    return true;
  }
  const ignore = TYPICAL_NOISE.find((s) =>
    e.errorMessage.toLowerCase().includes(s),
  );
  if (!ignore) {
    return true;
  }
  return false;
};
