import {
  FINMERCADO_MX_BRAND_ID,
  FINMERCARDO_MX_BRAND_NAME,
  FINMERCADO_MX_COOKIE_DOMAIN,
  FINMERCADO_MX_DOMAIN,
  FINMERCADO_MX_HOMEPAGE,
} from "../constants/constants";
import { gtmTags } from "./gtmTags";
import type { Branding } from "../common/types";

export const finmercadoMxBranding: Branding = {
  brandId: FINMERCADO_MX_BRAND_ID,
  brandName: FINMERCARDO_MX_BRAND_NAME,
  cookieDomain: FINMERCADO_MX_COOKIE_DOMAIN,
  brandDomain: FINMERCADO_MX_DOMAIN,
  homepage: FINMERCADO_MX_HOMEPAGE,
  gtmId: gtmTags.mx,
  tncLink: "https://www.finmercado.mx/terms-conditions",
  privacyPolicyLink: "https://www.finmercado.mx/privacy",
};
