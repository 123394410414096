import { useEffect, useRef } from "react";
import { onError } from "@integration/bugsnag/csr";

interface Args {
  brandId: string;
}

export const useInitPushengage = ({ brandId }: Args) => {
  const isPushengageReady = useRef(false);

  useEffect(() => {
    const initPushengageAsync = async () => {
      const init = (await import("./pushengage")).init;
      init(brandId);
    };

    if (!isPushengageReady.current) {
      initPushengageAsync().catch(onError);
      isPushengageReady.current = true;
    }
  }, [brandId]);
};
