import type { FilterTermUnits } from "@components/organisms/SubprimeOfferFilter/SubprimeOfferFilter.types";

export const DEFAULT_AMOUNT_NEAR_PRIME = 10000000;
export const DEFAULT_TERM_NEAR_PRIME = 12;

export const DEFAULT_AMOUNT_SUB_PRIME = 5000000;
export const DEFAULT_TERM_SUB_PRIME = 0; //90;

export const TERM_UNITS_SUB_PRIME: FilterTermUnits = "DAYS";
export const TERM_UNITS_NEAR_PRIME: FilterTermUnits = "MONTHS";

export const MIN_AMOUNT_NEAR_PRIME = 1000000;
export const MAX_AMOUNT_NEAR_PRIME = 25000000000;

export const MIN_TERM_NEAR_PRIME = 1;
export const MAX_TERM_NEAR_PRIME = 36;

export const MIN_AMOUNT_SUB_PRIME = 1000000;
export const MAX_AMOUNT_SUB_PRIME = 20000000;

export const MIN_TERM_SUB_PRIME = 15;
export const MAX_TERM_SUB_PRIME = 180;

export const AMOUNT_STEP_NEAR_PRIME = 5000000;
export const AMOUNT_STEP_SUB_PRIME = 1000000;

export const TERM_STEP_NEAR_PRIME = 1;
export const TERM_STEP_SUB_PRIME = 15;

export const AVG_SUB_PRIME_DAILY_INTEREST_RATE = 0.004; // 0.04%
export const AVG_NEAR_PRIME_MONTHLY_INTEREST_RATE = 0.002; // 0.2 %
