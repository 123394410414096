export type AgeGroup =
  | "18-21"
  | "22-29"
  | "30-39"
  | "40-49"
  | "50-59"
  | "60 plus";

export const getAgeGroup = (age?: number): AgeGroup | undefined => {
  if (!age) {
    return undefined;
  }

  if (age >= 18 && age <= 21) {
    return "18-21";
  }
  if (age >= 22 && age <= 29) {
    return "22-29";
  }
  if (age >= 30 && age <= 39) {
    return "30-39";
  }
  if (age >= 40 && age <= 49) {
    return "40-49";
  }
  if (age >= 50 && age <= 59) {
    return "50-59";
  }
  return "60 plus";
};
