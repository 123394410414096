import { type ProfileConfig } from "../types";

export const config: ProfileConfig = {
  enabled: true,
  spaces: ["/dashboard", "/my-activity", "/me"],
  dashboard: { otherProducts: { enabled: false } },
  followUs: { enabled: [] },
  share: {
    enabled: false,
  },
};
