import { createContext } from "react";
import { type Country } from "@domain/common-types";
import type { Locale } from "date-fns";
import type {
  Currency,
  DateInputFormat,
  HrefLang,
  HumanReadableLanguage,
  Languages,
  PhonePrefix,
} from "@domain/localisation/localisation-config/types";

export interface LocalisationContextProps {
  /**
   * Translates a given key into the current language.
   * @param key - The translation key to look up.
   * @param placeholders - Optional object containing placeholder values to be inserted into the translated string.
   * @param options - Optional object to customize the translation behavior.
   * @param options.replace - Optional object to specify a pattern to be replaced in the translated string.
   * @returns The translated string.
   *
   * @example
   * // Basic usage
   * t('common.welcome') // Returns: 'Welcome'
   *
   * @example
   * // With placeholders
   * t('common.greeting', { name: 'John' }) // Returns: 'Hello, John!' assuming the original was 'Hello, {name}!'
   *
   * t('inputs.generic.error.string.min', { min: 10 })
   * // Returns: 'Must be {10} or more characters'
   * //assuming the original was 'Must be {{min}} or more characters'
   *
   * @example
   * // With replace option
   * t('brand.slogan', {}, { replace: { pattern: /{{OldBrand}}/g, value: 'NewBrand' } })
   * // Returns: 'NewBrand is awesome!' (assuming the original was '{{OldBrand}} is awesome!')
   */
  t: (
    key: string,
    placeholders?: Record<string, string | number>,
    options?: {
      replace?: {
        pattern: string | RegExp;
        value: string;
      };
    },
  ) => string;
  currentLang: Languages;
  allowedLanguages: Languages[];
  allowedHumanReadableLanguages: HumanReadableLanguage[];
  humanReadableLang: HumanReadableLanguage;
  hrefLang: HrefLang;
  setCurrentLang: (newLanguage: Languages) => void;
  currency: Currency;
  phonePrefix: PhonePrefix;
  locale: Locale;
  dateInputFormat: DateInputFormat;
  country: Country;
  countryName: string;
}

export const LocalisationContext = createContext<LocalisationContextProps>(
  {} as LocalisationContextProps,
);
