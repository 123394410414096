import * as marketingParamConstants from "@jeff/web-analytics/src/marketing/marketingParams";
import * as paramConstants from "@utils/constants/urlParamNames";
import type { QueryParamInput } from "@jeff/lead-api";

export type ParsedQueryParams = Record<
  string,
  string | undefined | null | (string | QueryParamInput | null)[]
>;

export const urlParamNames = [
  ...Object.values(paramConstants),
  ...Object.values(marketingParamConstants),
] as const;

export type UrlParamNames = (typeof urlParamNames)[number];

export type UrlParams = {
  [P in UrlParamNames]?: string;
};
