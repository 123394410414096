/* eslint-disable no-console */
import { OnErrorType } from "../common/types";
import { ClarityEventListener, Metadata, ErrorHandler } from "./types";

const listeners: ClarityEventListener[] = [];

const clarity = ({
  method,
  args = [],
}: {
  method: string;
  args?: unknown[];
  onError?: ErrorHandler;
}) => {
  const { clarity } = window;

  if (!clarity) {
    return;
  }

  clarity(method, ...args);
};

export const metadata = (
  cb: (it: Metadata) => void,
  onError: ErrorHandler = console.error,
) =>
  clarity({
    method: "metadata",
    onError,
    args: [
      (it: Metadata) => {
        if (!it.projectId || !it.sessionId || !it.userId) {
          return;
        }
        cb(it);
      },
      false,
    ],
  });

export const identify = ({
  userId,
  onError,
}: {
  userId: string;
  onError: OnErrorType;
}) => {
  try {
    clarity({ method: "identify", args: [userId], onError });
    listeners.forEach((it) => it());
  } catch (e) {
    onError(e as Error);
  }
};

export const addListener = (l: ClarityEventListener) => listeners.push(l);
