import Script from "next/script";
import { isDevEnv } from "@client-env";
import { useReportMounted } from "@utils/react";

type Props = {
  adClient?: string;
  onLoaded: () => void;
};

const dev = isDevEnv();

export const AdSenseScript = ({ adClient, onLoaded }: Props) => {
  useReportMounted(() => {
    if (!dev) {
      return;
    }
    const timerId = setTimeout(onLoaded, 1000);
    return () => clearTimeout(timerId);
  });
  if (!adClient || dev) {
    return null;
  }
  return (
    <Script
      id="google-ad-sense"
      onReady={onLoaded}
      crossOrigin="anonymous"
      async
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`}
    ></Script>
  );
};
