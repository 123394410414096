import { findThemeByOfferId } from "@domain/partner-offers/themes";
import {
  LOANONLINE_BRAND_ID,
  PAISA247_IN_BRAND_ID,
  FINMART_ID_BRAND_ID,
  FINMART_VN_BRAND_ID,
  DONG_DEALS_BRAND_ID,
  JEFF_VN_BRAND_ID,
  ASIA_DEALS_BRAND_ID,
  DONG247_BRAND_ID,
  FINMERCADO_MX_BRAND_ID,
  PEROLOAN_BRAND_ID,
  FINMERKADO_PH_BRAND_ID,
  DINEROEXPRES_MX_BRAND_ID,
} from "@domain/branding/constants";
import type { PartnerOfferId } from "@domain/partner-offers/types";
import type { EntityId } from "@domain/branding/common";
import type { BaseTheme } from "./base";

export const getBrandedTheme = async (
  brandId: EntityId,
): Promise<BaseTheme> => {
  const LEAD_PAGE_THEME_MAP: Partial<
    Record<EntityId, () => Promise<BaseTheme>>
  > = {
    [DONG247_BRAND_ID]: async () => (await import("./dong247")).dong247Theme,
    [JEFF_VN_BRAND_ID]: async () => (await import("./jeff")).jeffTheme,
    [DONG_DEALS_BRAND_ID]: async () =>
      (await import("./dongDeals")).dongDealsTheme,
    [ASIA_DEALS_BRAND_ID]: async () =>
      (await import("./asiaDeals")).asiaDealsTheme,
    [LOANONLINE_BRAND_ID]: async () =>
      (await import("./loanonline")).loanonlineTheme,
    moneydong: async () => (await import("./moneydong")).moneydongTheme,
    [PEROLOAN_BRAND_ID]: async () => (await import("./peroloan")).peroloanTheme,
    [PAISA247_IN_BRAND_ID]: async () =>
      (await import("./paisa247")).paisa247Theme,
    [FINMART_ID_BRAND_ID]: async () =>
      (await import("./finmart-id")).finmartIdTheme,
    [FINMART_VN_BRAND_ID]: async () =>
      (await import("./finmart-vn/theme")).finmartVnTheme,
    [FINMERCADO_MX_BRAND_ID]: async () =>
      (await import("./finmercado-mx/theme")).finmercadoMxTheme,
    [FINMERKADO_PH_BRAND_ID]: async () =>
      (await import("./finmerkado-ph/theme")).finmerkadoPhTheme,
    [DINEROEXPRES_MX_BRAND_ID]: async () =>
      (await import("./dineroexpres-mx")).dineroexpresTheme,
  };

  const brandedTheme = await LEAD_PAGE_THEME_MAP?.[brandId]?.();
  if (brandedTheme) {
    return brandedTheme;
  }

  const brandedOfferTheme = await findThemeByOfferId({
    offerId: brandId as PartnerOfferId,
  });
  if (brandedOfferTheme) {
    return brandedOfferTheme;
  }
  return (await import("./jeff")).jeffTheme;
};
