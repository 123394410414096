import { isInTestGroup } from "@integration/experiments/common";
import { type ExperimentId } from "@integration/experiments/constants";
import { type ExperimentProps } from "@integration/experiments/types";

export const experimentDetails = ({
  experimentId,
  experiments,
}: {
  experimentId: ExperimentId;
  experiments: ExperimentProps;
}) => {
  return {
    show: isInTestGroup({ experimentId, experiments }),
    experimentId,
    experimentVariant: experiments?.variants?.[experimentId]?.name || null,
  };
};
